import { css, html, LitElement, TemplateResult } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import "./form-item";
import "./icon";
import { ICON_CLOSE } from "../../icons";
import { property } from "lit/decorators/property.js";

@customElement("bs-dialog-close-button")
export class BsDialogCloseButton extends LitElement {
	static get styles() {
		return [
			sharedStyles,
			css`
				:host {
					display: flex;
					position: absolute;
					right: var(--space-m);
					top: var(--space-m);
					align-items: center;
					justify-content: center;
					z-index: 123456789;
					--icon-size: 18px;
				}

				:host([fill]) {
					background: var(--background);
					color: var(--foreground);
					border-radius: 9999px;
					width: 34px;
					height: 34px;
				}
			`
		];
	}

	@property({ type: Boolean, reflect: true }) fill = false;
	@property({ type: Object }) icon = ICON_CLOSE;

	close() {
		this.dispatchEvent(new CustomEvent("close", { bubbles: true, composed: true }));
	}

	render(): TemplateResult | undefined {
		return html` <bs-icon button .icon="${this.icon}" @click="${this.close}"></bs-icon> `;
	}
}
