import { ActionSubject } from "../action";
import { AuthCredentials, Session, User } from "shared";

export const sessionAction = {
	oauth: new ActionSubject<{ credentials: AuthCredentials; data?: Partial<User> }>(),
	signup: new ActionSubject<Session>(),
	login: new ActionSubject<Session>(),
	logout: new ActionSubject<void>(),
	setCredentials: new ActionSubject<AuthCredentials>(),
	refreshSessionUser: new ActionSubject<User>()
};