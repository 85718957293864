import { BehaviorSubject } from "rxjs";
import { GlobalStore, subSuccessActionValue } from "../store";
import { LanguageCode } from "shared";
import { getBestBrowserLanguageCode } from "../../util/translate-util";
import { languageAction } from "./language-action";
import { sessionStore } from "../session/session-store";
import { getItem, setItem } from "../../util/local-storage";
import { STORAGE_KEYS } from "../../config";

export class LanguageStore extends GlobalStore {
	currentLanguage: LanguageCode =
		sessionStore.sessionUser?.data?.language ?? getItem(STORAGE_KEYS.language) ?? getBestBrowserLanguageCode();

	model$ = {
		currentLanguage: new BehaviorSubject<LanguageCode>(this.currentLanguage)
	};

	protected subscriptions() {
		return [
			subSuccessActionValue(languageAction.set, (code) => {
				setItem(STORAGE_KEYS.language, code);

				if (this.currentLanguage !== code) {
					this.model$.currentLanguage.next(code);
				}
			}),
			this.model$.currentLanguage.subscribe((code) => {
				this.currentLanguage = code;
			})
		];
	}
}

export const languageStore = new LanguageStore();
