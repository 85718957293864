import { ActionSubject, meta$, runAction, SuccessAction } from "../action";
import {
	ActivityKind,
	APIError,
	Id,
	ModelKind,
	ModelMap,
	Ref,
	Room,
	WebSocketMessage,
	WebSocketMessageKindMap
} from "shared";
import { filter, map, Observable, shareReplay } from "rxjs";
import { hydrateGQLResult } from "../../api/gql/gql-util";
import { modelStore } from "../model/model-store";

export const activityAction = {
	activity: new ActionSubject<WebSocketMessage>(),
	caughtError: new ActionSubject<Error | APIError>()
};

export const caughtError = (error: Error | APIError) => runAction(activityAction.caughtError, () => error);

export const receiveActivity = (activity: WebSocketMessage) =>
	runAction(
		activityAction.activity,
		async () => {
			return modelStore.absorb(hydrateGQLResult(activity));
		},
		activity.roomId
	);

export function activity$<
	TKind extends ActivityKind,
	TActivity extends WebSocketMessageKindMap[TKind] = WebSocketMessageKindMap[TKind],
	TOut = TActivity,
	TMeta = Id<Room> | null
>(
	activityKind: TKind,
	{
		map: mapCb,
		filter: filterCb,
		meta
	}: {
		map?: (activity: TActivity) => TOut;
		filter?: (activity: SuccessAction<TActivity, TMeta>) => boolean;
		meta?: Ref<Room> | Observable<Ref<Room> | null>;
	} = {}
): Observable<SuccessAction<TOut, TMeta>> {
	return meta$(activityAction.activity, meta).pipe(
		filter(
			(activity) =>
				activity.kind === "success" &&
				activity.value.kind === activityKind &&
				(filterCb == null || filterCb(activity as SuccessAction<TActivity, TMeta>))
		),
		map((activityAction) => {
			const activity = (activityAction as SuccessAction<TActivity, any>).value;
			const value = mapCb == null ? activity : mapCb(activity);
			const meta = activity.roomId;
			return { kind: "success", value, meta } as SuccessAction<TOut, TMeta>;
		}),
		shareReplay(1)
	);
}

export function activityCreate$<TModelKind extends ModelKind, TModel extends ModelMap[TModelKind]>(
	modelKind: TModelKind
): Observable<SuccessAction<TModel>> {
	return activity$("cud.create").pipe(
		filter((activity) => activity.value.modelKind === modelKind),
		map((activity) => ({ kind: "success", meta: activity.meta, value: activity.value.model as unknown as TModel }))
	);
}

export function activityUpdate$<TModelKind extends ModelKind, TModel extends ModelMap[TModelKind]>(
	modelKind: TModelKind
): Observable<SuccessAction<TModel>> {
	return activity$("cud.update").pipe(
		filter((activity) => activity.value.modelKind === modelKind),
		map((activity) => ({ kind: "success", meta: activity.meta, value: activity.value.model as unknown as TModel }))
	);
}

export function activityRemove$<
	TModelKind extends ModelKind,
	TModel extends Id<ModelMap[TModelKind]> = Id<ModelMap[TModelKind]>
>(modelKind: TModelKind): Observable<SuccessAction<TModel>> {
	return activity$("cud.remove").pipe(
		filter((activity) => activity.value.modelKind === modelKind),
		map((activity) => ({ kind: "success", meta: activity.meta, value: activity.value.modelId as unknown as TModel }))
	);
}
