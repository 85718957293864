import { isId } from "shared";

function transformData(
	data: unknown,
	transform: (data: unknown, key?: string, level?: number) => unknown | null,
	currentKey: string | undefined = undefined,
	level = 0
): unknown {
	if (data == null) {
		return data;
	} else if (Array.isArray(data)) {
		return data.map((j) => transformData(j, transform, currentKey, level + 1));
	}

	const transformedData = transform(data, currentKey, level);
	data = transformedData || data;

	if (data != null && typeof data === "object" && !(data instanceof Date)) {
		return Object.keys(data as any)
			.map((key) => [key, transformData((data as any)[key], transform, key, level + 1)])
			.reduce((acc, [key, val]) => {
				acc[key as any] = val;
				return acc;
			}, {} as any);
	}

	return data;
}

export function hydrateGQLResult<T = any>(result: any): T {
	if (result == null) return result;

	const transformedResult = transformData(result, (dataToTransform, parent, level) => {
		if (typeof dataToTransform === "object" && dataToTransform != null && !(dataToTransform instanceof Date)) {
			const newObj = { ...dataToTransform } as any;

			for (const [key, value] of Object.entries(newObj)) {
				const match = key.match(/_alias_(\d+)_(.+)/);
				if (match != null) {
					delete newObj[key];
					newObj[match[2]] = value;
				}
			}

			if ("id" in newObj && Object.keys(newObj).length === 1 && (level || 0) > 1) {
				return (newObj as any).id;
			}

			return newObj;
		}

		// Convert date string to Date
		if (typeof dataToTransform === "string" && dataToTransform.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
			return new Date(dataToTransform);
		}

		return null;
	});

	return transformedResult as T;
}

export function makeGQLQueryFromShapeAndArgs(
	rootKey: string,
	args: object,
	resultShape: GQLShape<any> | true,
	{ opName, isMutation }: { isMutation?: boolean; opName?: string } = {}
): { query: string; variables: Record<string, unknown> } {
	const { query: gqlQueryShape, variables } =
		resultShape !== true
			? makeGQLQueryFromShape(resultShape, { level: 2 })
			: {
					query: "",
					variables: {}
				};

	const varArgParts: string[] = [];
	const shapeArgParts: string[] = [];

	for (const key of Object.keys(variables)) {
		const varType = "Data";
		const varName = `$${key}`;
		varArgParts.push(`${varName}: ${varType}`);
	}

	for (const [key, value] of Object.entries(args)) {
		if (value == null) continue;

		if (typeof value === "object") {
			const varName = `$${key}`;
			const varType = (() => {
				if (Array.isArray(value)) {
					if (isId(value[0])) return "[ID!]";
					if (typeof value[0] === "number") return "[Int!]";
					if (typeof value[0] === "string") return "[String!]";
					if (typeof value[0] === "boolean") return "[Boolean!]";
				}

				switch (key) {
					case "options":
						return "QueryOptions";
					default:
						return "Data";
				}
			})();

			variables[key] = value;
			varArgParts.push(`${varName}: ${varType}`);
			shapeArgParts.push(`${key}: ${varName}`);
		} else {
			shapeArgParts.push(`${key}: ${JSON.stringify(value)}`);
		}
	}
	const query = `${isMutation ? "mutation" : "query"}${opName != null ? ` ${opName}` : ""}${
		varArgParts.length > 0 ? ` (${varArgParts.join(", ")})` : ""
	} {
 ${rootKey}${shapeArgParts.length > 0 ? ` (${shapeArgParts.join(", ")})` : ""} ${
		gqlQueryShape.length > 0
			? `{
${gqlQueryShape}
 }`
			: ""
 }
}`;

	return { query, variables };
}

export function makeGQLQueryFromShape<TModel>(
	shape: GQLShape<TModel>,
	options: { level?: number; aliased?: boolean } = { level: 0, aliased: false }
): { query: string; variables: Record<string, unknown> } {
	const variables: Record<string, unknown> = {};

	const buildShape = (s: GQLShape<any>, opts: { level?: number; aliased?: boolean }): string => {
		if (typeof s === "object") {
			const queryParts: string[] = [];
			const indent = " ".repeat(opts.level || 0);

			// Is union
			if ((s as any)[gqlUnionSymbol] === true) {
				for (const [key, value] of Object.entries(s)) {
					queryParts.push(
						`... on ${key} {\n${buildShape(value!, {
							...opts,
							level: (opts.level || 0) + 1,
							aliased: true
						})}\n${indent}}`
					);
				}
			} else {
				const entries = Object.entries(s);
				if (entries.length === 0) {
					return "";
				}

				for (const [key, value] of entries) {
					const aliasKey = opts.aliased ? `_alias_${Math.round(Math.random() * 10000000)}_${key}: ${key}` : undefined;

					if (typeof value === "object") {
						let namePart = aliasKey ?? key;

						// Support for functions
						if ((value as any)[gqlFunctionSymbol] != null) {
							const args: string[] = [];
							for (const [argKey, argValue] of Object.entries((value as any)[gqlFunctionSymbol])) {
								if (argValue === undefined) continue;

								if (typeof argValue === "object") {
									const varName = `arg${Math.round(Math.random() * 1000000)}`;
									variables[varName] = argValue;
									args.push(`${argKey}: $${varName}`);
								} else {
									args.push(`${argKey}: ${JSON.stringify(argValue)}`);
								}
							}

							if (args.length > 0) {
								namePart += `(${args.join(", ")})`;
							}
						}

						const resultPart = buildShape(value, {
							...opts,
							level: (opts.level || 0) + 1
						});

						if (resultPart.length === 0) {
							queryParts.push(namePart);
						} else {
							queryParts.push(`${namePart} {\n${resultPart}\n${indent}}`);
						}
					} else if (value) {
						queryParts.push(aliasKey ?? key);
					}
				}
			}

			return indent + queryParts.join(`,\n${indent}`);
		} else {
			return "";
		}
	};

	return {
		query: buildShape(shape, options),
		variables
	};
}

export type SimpleValue = string | number | bigint | boolean | symbol | RegExp | Date | null | undefined;
export type IgnoredLookupValue =
	| SimpleValue
	| CallableFunction
	| Set<unknown>
	| WeakSet<never>
	| Map<unknown, unknown>
	| WeakMap<never, unknown>;

export type NextDepth<T extends number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10][T];

export type GQLShape<TModel, TCurrentDepth extends number = 0> = TCurrentDepth extends 10
	? any
	: TModel extends IgnoredLookupValue
		? boolean
		: TModel extends (infer ArrayElement)[]
			? GQLShape<ArrayElement, NextDepth<TCurrentDepth>>
			: TCurrentDepth extends 0
				? { [TKey in keyof TModel]?: GQLShape<TModel[TKey], NextDepth<TCurrentDepth>> }
				: { [TKey in keyof TModel]?: GQLShape<TModel[TKey], NextDepth<TCurrentDepth>> } | boolean;

// GraphQL Union Helper
const gqlUnionSymbol = Symbol("GQL_UNION_SYMBOL");

export type GQLUnion<TShape> = Record<string, TShape>;

export function makeGqlUnionShape<TShape>(unionShape: GQLUnion<TShape>): GQLUnion<TShape> {
	return {
		[gqlUnionSymbol]: true,
		...unionShape
	};
}

// GraphQL Function Helper
const gqlFunctionSymbol = Symbol("GQL_FUNCTION_SYMBOL");
export function makeGqlFunctionShape<TShape extends Record<any, any> | boolean>(
	args: Record<string, any>,
	ret: TShape
): TShape {
	if (ret === false) {
		// No need for applying this shape
		return false as TShape;
	}

	if (typeof ret === "boolean") {
		// It's not possible to add symbol to "true/false" primitive types, so disguise it in an object instead
		return { [gqlFunctionSymbol]: args } as unknown as TShape;
	}

	return { [gqlFunctionSymbol]: args, ...(ret as Object) } as unknown as TShape;
}
