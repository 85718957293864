import { css } from "lit";

export const sharedStyles = css`
	* {
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
	}

	input[type="color"]::-webkit-color-swatch-wrapper {
		padding: 0;
	}

	input[type="color"]::-webkit-color-swatch {
		border: none;
	}

	::-webkit-file-upload-button {
		display: none;
		border: none;
		background: none;
	}

	input[type="range"]::-webkit-slider-thumb {
		cursor: ew-resize;
	}
`;
