import { MindmapPaletteKind } from "shared/src/types/mindmap-palette-kind";
import {
	MINDMAP_COLOR_BLACK_DIRTY,
	MINDMAP_COLOR_WHITE,
	MINDMAP_COLOR_WHITE_DIRTY,
	MINDMAP_THEME_PALETTE_COLORS_BRIGHT,
	MINDMAP_THEME_PALETTE_COLORS_ENERGY,
	MINDMAP_THEME_PALETTE_COLORS_FIRE,
	MINDMAP_THEME_PALETTE_COLORS_FOREST,
	MINDMAP_THEME_PALETTE_COLORS_GLOW,
	MINDMAP_THEME_PALETTE_COLORS_MURKY,
	MINDMAP_THEME_PALETTE_COLORS_NATURE,
	MINDMAP_THEME_PALETTE_COLORS_NOSTALGIA,
	MINDMAP_THEME_PALETTE_COLORS_PASTEL,
	MINDMAP_THEME_PALETTE_COLORS_RUST,
	MINDMAP_THEME_PALETTE_COLORS_VACATION,
	MINDMAP_THEME_PALETTE_COLORS_WAVES,
	MindmapPalette,
	THEME_PALETTE_COLORS_CANDY
} from "mindmap";

export const PREMIUM_MINDMAP_PALETTE_KINDS: MindmapPaletteKind[] = [
	"candy",
	"pastel",
	"glow",
	"murky",
	"fire",
	"vacation",
	"bright",
	"mono"
];

export const MINDMAP_PALETTES: Record<MindmapPaletteKind, (isDark: boolean) => Partial<MindmapPalette>> = {
	energy: () => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_ENERGY
	}),
	rust: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_RUST,
		backgroundColor: isDark ? "#0a0000" : "#f4f4f4",
		backgroundDotsColor: isDark ? null : "#e8e7e7"
	}),
	nature: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_NATURE
	}),
	waves: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_WAVES,
		backgroundColor: isDark ? "#010f1c" : "#f2fbff",
		backgroundDotsColor: null
	}),
	forest: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_FOREST,
		backgroundColor: isDark ? "#100b00" : "#fff5ea",
		backgroundDotsColor: null
	}),
	nostalgia: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_NOSTALGIA,
		backgroundColor: isDark ? "#150a00" : "#fff3f6",
		backgroundDotsColor: null
	}),
	candy: (isDark) => ({
		colors: THEME_PALETTE_COLORS_CANDY
	}),
	pastel: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_PASTEL
	}),
	glow: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_GLOW,
		backgroundColor: isDark ? "#050113" : "#f9f4ff",
		backgroundDotsColor: null
	}),
	murky: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_MURKY,
		backgroundColor: isDark ? "#000b00" : "#f7ffec",
		backgroundDotsColor: null
	}),
	fire: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_FIRE,
		backgroundColor: isDark ? "#0b0000" : "#ffecec",
		backgroundDotsColor: null
	}),
	vacation: (isDark) => ({
		colors: MINDMAP_THEME_PALETTE_COLORS_VACATION
	}),
	bright: (isDark) =>
		isDark
			? {
					colors: [...MINDMAP_THEME_PALETTE_COLORS_BRIGHT, { color: "#f9f9f9", contrast: MINDMAP_COLOR_BLACK_DIRTY }],
					backgroundColor: "#090f1c",
					backgroundDotsColor: null
			  }
			: {
					colors: [...MINDMAP_THEME_PALETTE_COLORS_BRIGHT, { color: "#16233a", contrast: MINDMAP_COLOR_WHITE }],
					backgroundColor: "#f9f9f9",
					backgroundDotsColor: null
			  },
	mono: (isDark) => ({
		colors: isDark
			? [{ color: MINDMAP_COLOR_WHITE_DIRTY, contrast: MINDMAP_COLOR_BLACK_DIRTY }]
			: [{ color: MINDMAP_COLOR_BLACK_DIRTY, contrast: MINDMAP_COLOR_WHITE }]
	})
};
