import { debounce } from "shared";
import { TrackerPageViewData, TrackerService, TrackerServiceProps } from "./trackers/tracker-service";
import { IS_DEBUGGING, ROUTES } from "../config";

const SCRAMBLED_PATHS = [ROUTES.room(""), ROUTES.joinRoom(""), ROUTES.joinWorkspace("")];

/**
 * Scrambles a path so IDs are not leaked.
 * @param path
 */
function scramblePath(path: string) {
	for (const p of SCRAMBLED_PATHS) {
		if (path.startsWith(p)) {
			return `${p}:id`;
		}
	}
	return path;
}

/**
 * Build up and scramble URL data.
 */
function getPageViewData(): TrackerPageViewData {
	return {
		referrer: previousHref,
		url: scramblePath(location.pathname),
		hostname: location.hostname,
		language: navigator.language,
		title: document.title,
		screen: `${window.screen.width}x${window.screen.height}`
	};
}

// Keep track of previous URL for referrer
let previousHref = document.referrer;

/**
 * Tracking service.
 */
export class EventService {
	protected trackers: TrackerService[] = [];
	protected props: TrackerServiceProps = {};

	get isAnalyticsDisabled() {
		return this.props.workspace?.data?.disableAnalytics ?? false;
	}

	hasTracker(tracker: TrackerService) {
		return this.trackers.includes(tracker);
	}

	addTrackersAndSetup(...trackers: TrackerService[]) {
		this.trackers.push(...trackers);
		for (const tracker of trackers) {
			tracker.setup?.(this.props);
		}
	}

	setProps(props: TrackerServiceProps): Promise<void> | void {
		this.props = { ...this.props, ...props };
		if (this.isAnalyticsDisabled) return;

		if (IS_DEBUGGING) {
			console.log(`%c[props]: set props`, "color: cyan", this.props);
		}

		for (const tracker of this.trackers) {
			tracker.setProps?.(this.props);
		}
	}

	trackPageView({ debounceMs = 300 }: { debounceMs?: number } = {}) {
		if (this.isAnalyticsDisabled) return;

		debounce(
			() => {
				const data = getPageViewData();
				previousHref = data.url;

				if (IS_DEBUGGING) {
					console.log(`%c[view]: ${data.url} `, "color: cyan", { data, props: this.props });
				}

				for (const tracker of this.trackers) {
					tracker.trackPageView?.({ data, props: this.props });
				}
			},
			"track-page-view",
			debounceMs
		);
	}

	/**
	 * Tracks an event.
	 * @param name
	 * @param data
	 */
	async trackEvent(name: string, data?: Object) {
		if (this.isAnalyticsDisabled) return;

		if (IS_DEBUGGING) {
			console.log(`%c[event]: ${name}`, "color: cyan", { data, props: this.props });
		}

		await Promise.all(
			this.trackers.map(async (tracker) => {
				try {
					await tracker.trackEvent?.(name, { data, props: this.props, pageData: getPageViewData() });
				} catch (err) {
					// Ignore errors
				}
			})
		);
	}
}

export const eventService = new EventService();
