import { ColorMode, ColorModeColors, HexColor, Palette, ThemeColor } from "../types/color-model";

export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#000000";
export const COLOR_WHITE_DIRTY = "#eeeeee";
export const COLOR_WHITE_DIRTIER = "#cecece";
export const COLOR_BLACK_DIRTY = "#0B0B19";

export const COLOR_DARK = COLOR_BLACK_DIRTY;
export const COLOR_LIGHT = COLOR_WHITE_DIRTY;

export const TRANSPARENT_COLOR = "#FFFFFF00";

export const MS_TEAMS_BACKGROUND_LIGHT = "#f5f5f5";
export const MS_TEAMS_BACKGROUND_DARK = "#1f1f1f";

export const STRIBES_DARK = `repeating-linear-gradient(45deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 5px, rgba(0, 0, 0, 0.1) 5px, rgba(0, 0, 0, 0.1) 10px)`;
export const STRIBES_LIGHT = `repeating-linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 5px, rgba(255, 255, 255, 0.1) 5px, rgba(255, 255, 255, 0.1) 10px)`;

const COLOR_LIGHT_RED = {
	darker: "#760016",
	dark: "#A9363D",
	normal: "#E57373",
	light: "#FEBBBC",
	lighter: "#FFE0E0",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_RED = {
	darker: "#7C0000",
	dark: "#B3002B",
	normal: "#F43F5E",
	light: "#FFB9C8",
	lighter: "#FFE0E6",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_WHITE,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_PINK = {
	darker: "#520064",
	dark: "#812C91",
	normal: "#BA68C8",
	light: "#E8BDEF",
	lighter: "#F7E0FA",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_PURPLE = {
	darker: "#2B156A",
	dark: "#5B3F97",
	normal: "#9575CD",
	light: "#D5C4F3",
	lighter: "#ECE3FC",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_WHITE,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_OCEAN_BLUE = {
	darker: "#002E91",
	dark: "#3B56C0",
	normal: "#818CF8",
	light: "#C0C8FF",
	lighter: "#E2E6FF",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_BLUE = {
	darker: "#004494",
	dark: "#006EC3",
	normal: "#60A5FA",
	light: "#9BD2FF",
	lighter: "#CFEBFF",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_TURQUOISE = {
	darker: "#00687C",
	dark: "#0096A9",
	normal: "#4DD0E1",
	light: "#10E0F5",
	lighter: "#96F7FF",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_GREEN = {
	darker: "#006933",
	dark: "#00985F",
	normal: "#2bc28c",
	light: "#45f3b7",
	lighter: "#A0F9DB",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_WHITE,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_DEEP_GREEN = {
	darker: "#003309",
	dark: "#005F33",
	normal: "#059669",
	light: "#96D9C3",
	lighter: "#CBEFE4",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_WHITE,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_LIME_GREEN = {
	darker: "#416D13",
	dark: "#719B44",
	normal: "#AED581",
	light: "#ABDA77",
	lighter: "#CDF4A1",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_BLACK,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_LIME_YELLOW = {
	darker: "#707700",
	dark: "#A3A50E",
	normal: "#E2E05D",
	light: "#D2D148",
	lighter: "#EFEE5C",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_BLACK,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_YELLOW = {
	darker: "#876B00",
	dark: "#BB9900",
	normal: "#FCD34D",
	light: "#E9CA3A",
	lighter: "#f1df8c",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_BLACK,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_ORANGE = {
	darker: "#893F00",
	dark: "#BD6C00",
	normal: "#FFA500",
	light: "#FFC240",
	lighter: "#FFE59D",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

const COLOR_BROWN = {
	darker: "#430000",
	dark: "#680000",
	normal: "#A52A2A",
	light: "#EBC1C3",
	lighter: "#F7E2E2",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_WHITE,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

export const COLOR_GREY: ThemeColor = {
	darker: "#5B5B5B",
	dark: "#B8B8B8",
	normal: "#E0E0E0",
	light: "#F2F2F2",
	lighter: "#FFFFFF",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

export const COLOR_LIGHT_GREY: ThemeColor = {
	darker: "#3C4A4E",
	dark: "#67787F",
	normal: "#90A4AE",
	light: "#B4C2CB",
	lighter: "#E2E8F0",
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_WHITE,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

export const COLOR_SHADE: ThemeColor = {
	darker: COLOR_DARK,
	dark: "#6a6e72",
	normal: "#D1D5DB",
	light: "#ECEDF0",
	lighter: COLOR_LIGHT,
	contrast: {
		darker: COLOR_WHITE,
		dark: COLOR_WHITE,
		normal: COLOR_BLACK,
		light: COLOR_BLACK,
		lighter: COLOR_BLACK
	}
};

export const COLOR_SHADE_DARK_MODE: ThemeColor = {
	lighter: "#18191A", //"#15202B",
	light: "#242526", //"#192734",
	normal: "#424344", //"#22303C",
	dark: "#444849",
	darker: "#808183",
	contrast: {
		darker: COLOR_BLACK,
		dark: COLOR_WHITE,
		normal: COLOR_WHITE,
		light: COLOR_WHITE,
		lighter: COLOR_WHITE
	}
};

export const COLOR_SHADE_CONTRAST_MODE: ThemeColor = {
	lighter: COLOR_BLACK,
	light: COLOR_BLACK,
	normal: COLOR_WHITE,
	dark: COLOR_WHITE,
	darker: COLOR_WHITE,
	contrast: {
		darker: COLOR_BLACK,
		dark: COLOR_BLACK,
		normal: COLOR_BLACK,
		light: COLOR_WHITE,
		lighter: COLOR_WHITE
	}
};

export const COLOR_SHADE_INVERSE: ThemeColor = {
	lighter: COLOR_SHADE.darker,
	light: COLOR_SHADE.dark,
	normal: COLOR_SHADE.normal,
	dark: COLOR_SHADE.light,
	darker: COLOR_SHADE.lighter,
	contrast: {
		lighter: COLOR_SHADE.contrast.darker,
		light: COLOR_SHADE.contrast.dark,
		normal: COLOR_SHADE.contrast.normal,
		dark: COLOR_SHADE.contrast.light,
		darker: COLOR_SHADE.contrast.lighter
	}
};

export const COLOR_MODE_COLORS: Record<ColorMode, ColorModeColors> = {
	light: {
		appForeground: COLOR_BLACK_DIRTY,
		//appBackground: COLOR_SHADE.light,
		appBackground: `linear-gradient(0deg, ${COLOR_SHADE.light} 60%, ${COLOR_SHADE.lighter})`,

		background: COLOR_WHITE,
		foreground: COLOR_BLACK_DIRTY,

		light: COLOR_WHITE_DIRTY,
		dark: COLOR_BLACK_DIRTY,

		shadow: "rgba(0, 0, 0, 0.1)", //;COLOR_BLACK_DIRTY,
		shade: COLOR_SHADE,
		rgb: "255, 255, 255",
		border: "unset"
	},
	dark: {
		appForeground: COLOR_WHITE_DIRTIER,
		//appBackground: "#0e0e0e", //"#101010", //"#292929", //"#121212",
		appBackground: `linear-gradient(0deg, #0e0e0e 60%, #1e1e1e)`,

		background: "#19191c",
		foreground: COLOR_WHITE_DIRTIER,

		dark: "#19191c",
		light: COLOR_WHITE_DIRTIER,

		shadow: "rgba(0, 0, 0, 0.1)", //;COLOR_BLACK
		shade: COLOR_SHADE_DARK_MODE,
		rgb: "25, 25, 28",
		border: `1px solid ${COLOR_SHADE_DARK_MODE.dark}`,
		isDark: true
	},
	contrast: {
		appForeground: COLOR_WHITE,
		appBackground: COLOR_BLACK,

		background: COLOR_BLACK_DIRTY,
		foreground: COLOR_YELLOW.lighter,
		//foreground: COLOR_WHITE,

		light: COLOR_BLACK,
		dark: COLOR_WHITE,

		shadow: COLOR_BLACK,
		shade: COLOR_SHADE_CONTRAST_MODE,
		rgb: "0, 0, 0",
		border: `1px solid ${COLOR_WHITE_DIRTY}`,
		//border: `1px solid ${COLOR_YELLOW.lighter}`,
		isDark: true
	}
};

// All colors
export const PALETTE: Palette = {
	primary: COLOR_GREEN,
	accent: COLOR_PURPLE,
	success: COLOR_GREEN,
	warn: COLOR_ORANGE,
	error: COLOR_RED,
	highlight: COLOR_YELLOW,
	/*focus: COLOR_RED,
	select: COLOR_BLUE,*/

	lightRed: COLOR_LIGHT_RED,
	red: COLOR_RED,
	pink: COLOR_PINK,
	purple: COLOR_PURPLE,
	oceanBlue: COLOR_OCEAN_BLUE,
	blue: COLOR_BLUE,
	turquoise: COLOR_TURQUOISE,
	green: COLOR_GREEN,
	deepGreen: COLOR_DEEP_GREEN,
	limeGreen: COLOR_LIME_GREEN,
	limeYellow: COLOR_LIME_YELLOW,
	yellow: COLOR_YELLOW,
	orange: COLOR_ORANGE,
	brown: COLOR_BROWN,
	grey: COLOR_GREY
};

export const QUICK_COLORS: HexColor[] = [
	COLOR_LIGHT_RED.normal,
	//COLOR_RED.normal,
	COLOR_PINK.normal,
	COLOR_PURPLE.normal,
	COLOR_OCEAN_BLUE.normal,
	COLOR_BLUE.normal,
	COLOR_TURQUOISE.normal,
	COLOR_GREEN.normal,
	COLOR_DEEP_GREEN.normal,
	COLOR_LIME_GREEN.normal,
	COLOR_LIME_YELLOW.normal,
	COLOR_YELLOW.normal,
	COLOR_ORANGE.normal,
	COLOR_BROWN.normal,
	COLOR_SHADE.light
];
