import { TrackerPageViewData, TrackerService, TrackerServiceProps } from "./tracker-service";
import { takeId } from "shared";
import { ENVIRONMENT, UMAMI_LANDING_ID, UMAMI_PLAYGROUND_ID, UMAMI_WEBAPP_ID } from "../../config";

// https://umami.is/docs/tracker-functions
type Umami = {
	// umami.track([payload]);
	// umami.track(event_name, [event_data])
	// umami.track(props => ({ ...props, url: '/home', title: 'Home page' }));
	track:
		| ((eventName: string, eventData?: any) => void | Promise<void>)
		| ((eventData?: any) => void | Promise<void>)
		| ((cb: (props: any) => any) => void | Promise<void>);
};

// Define umami on window
declare global {
	interface Window {
		umami: Umami;
	}
}

/**
 * Umami tracker service.
 * https://umami.is/docs/tracker-functions
 * umami.trackEvent(event_name, [event_data], [url], [website_id])
 */
export class UmamiService implements TrackerService {
	private getPropsData(props?: TrackerServiceProps) {
		const { user, workspace, room } = props || {};
		return {
			workspace: workspace != null ? takeId(workspace) : undefined,
			room: room != null ? takeId(room) : undefined,
			user: user != null ? takeId(user) : undefined
		};
	}

	async trackPageView({ data, props }: { data?: TrackerPageViewData; props?: TrackerServiceProps }) {
		return this.track({ baseData: data, props });
	}

	async trackEvent(
		name: string,
		{ data, props, pageData }: { data?: Object; props?: TrackerServiceProps; pageData?: TrackerPageViewData }
	) {
		return this.track({ name, props, eventData: data, baseData: pageData });
	}

	private async track({
		name,
		baseData,
		eventData,
		props
	}: { baseData?: Object; name?: string; eventData?: any; props?: TrackerServiceProps } = {}) {
		const platform = props?.platform || "webapp";
		const website =
			ENVIRONMENT === "production"
				? platform === "landing"
					? UMAMI_LANDING_ID
					: UMAMI_WEBAPP_ID
				: UMAMI_PLAYGROUND_ID;

		await window.umami?.track((umamiData: any) => ({
			...(umamiData ?? {}),
			...(baseData ?? {}),
			name,
			website,
			data: {
				platform,
				...this.getPropsData(props),
				...(eventData ?? {})
			}
		}));
	}
}

export const umamiService = new UmamiService();
