import { MindmapLayout } from "./mindmap-theme";
import { DEFAULT_MINDMAP_FONT } from "./font";
import { MINDMAP_SPACING_MEDIUM, MINDMAP_SPACING_SMALL, MINDMAP_SPACING_TINY } from "./theme-constants";

export const DEFAULT_MINDMAP_LAYOUT: MindmapLayout = {
	previewStyle: "default",
	font: DEFAULT_MINDMAP_FONT,
	nodeShape: {
		kind: "rounded",
		fillStyle: "default",
		border: {
			width: 4,
			style: "line"
		},
		padding: {
			top: MINDMAP_SPACING_MEDIUM,
			right: MINDMAP_SPACING_MEDIUM,
			bottom: MINDMAP_SPACING_MEDIUM,
			left: MINDMAP_SPACING_MEDIUM
		},
		maxWidth: 350,
		minWidth: 200,
		maxHeight: 300,
		minHeight: 30,
		isAuthorVisible: true
	},
	mainNodeShape: {
		kind: "rounded",
		fillStyle: "solid",
		border: {
			width: 4,
			style: "none"
		},
		maxWidth: 350,
		minWidth: 200,
		maxHeight: 300,
		minHeight: 50,
		padding: {
			top: MINDMAP_SPACING_MEDIUM,
			right: MINDMAP_SPACING_MEDIUM,
			bottom: MINDMAP_SPACING_MEDIUM,
			left: MINDMAP_SPACING_MEDIUM
		},
		isAuthorVisible: true
	},
	nodeConnectionShape: {
		kind: "curve",
		lineStyle: "line",
		lineWidth: 4
	},
	board: {
		marginHorizontal: 50,
		marginVertical: 10,
		selectionBorderWidth: 5
	},
	collapsedNode: {
		size: 50,
		sticker: {
			// TODO
			kind: "image",
			// url: "" //iconMediaUrl("💡", "small")
			url: "https://api.ideamap.ai/icons/u1f4a1/small", // TODO
			thumbUrl: "https://api.ideamap.ai/icons/u1f4a1/thumb", // TODO
			id: "u1f60d"
		}
	},
	author: {
		size: 30,
		marginRight: MINDMAP_SPACING_SMALL
	},
	sticker: {
		heroHeight: 100
	},
	floatingSticker: {
		size: 40
	},
	typingIndicator: {
		width: 33,
		height: 18,
		dotSize: 4,
		dotCount: 3,
		borderWidth: 2
	},
	media: {
		heroImageHeight: 200,
		heroImageBorderRadius: 8,
		heroImageFit: "cover",
		gap: MINDMAP_SPACING_TINY,
		count: 3,
		maxWidth: 160,
		height: 80,
		borderRadius: 8,
		moreFontSize: 12
	},
	unfold: {
		borderWidth: 2
	}
};
