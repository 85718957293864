import { pickRandomItems } from "./array-util";

export function parseSectionsInText(text: string): Record<string, string> | undefined {
	text = text.trim();

	const sections: Record<string, string> = {};

	/* First, try parse this case (sections without multilines)
	# Title 1: Idea 1, Idea 2
  # Title 2: Idea 1, Idea 2

	 */

	const lines = text.split("\n").map((line) => line.trim());

	if (!lines.some((line) => !line.startsWith("# "))) {
		// All lines start with "# "
		for (const line of lines) {
			// Split ":"
			const parts = line.split(":").map((line) => unquotify(line.trim())) as [string, string];
			if (parts.length !== 2) return undefined;
			sections[parts[0].replace("# ", "")] = parts[1];
		}
	} else {
		/* Second, try parse this case (sections with multilines)
		# Title 1
		- Idea 1
		- Idea 2
		# Title 2
		- Idea 1
		- Idea 2
		 */

		const END_MARKER = "!!END!!"; // Js regex doesn't support \z so we use custom !!END!! marker instead
		const regex = /^(#\s*[*\s]*(?<title1>.+?)[*\s:]*)\n(?<content>[\s\S]+?)(?=(\n#)|\*\*|!!END!!)/gm;
		//const regex = /(#\s*(?<title1>.+?):?|\*\*(?<title2>.+?)\*\*)\n(?<content>[\s\S]+?)(?=(\n#)|\*\*|$)/g;

		const matches = [...(text + END_MARKER).matchAll(regex)];

		if (matches.length === 0) return undefined;

		for (const match of matches) {
			sections[match.groups?.title1 ?? match.groups?.title2 ?? ""] = (match.groups?.content ?? "").replace(
				END_MARKER,
				""
			);
		}
	}

	return sections;
}

/**
 * Extracts a JSON object from a string.
 * @param text
 */
export function parseJSONInText(text: string) {
	// Find the start and end indexes of the JSON string
	const startIndex = text.indexOf("{");
	const endIndex = text.lastIndexOf("}") + 1;

	// Extract the JSON string from the text
	let jsonString = text.substring(startIndex, endIndex);

	// Parse the JSON string and return the resulting object
	let json: any;
	try {
		json = JSON.parse(jsonString);
	} catch (e) {
		console.error(e);

		// Fix potential errors in  the JSON so it can parse
		jsonString = jsonString.replace(/(\w+):/g, '"$1":');

		json = JSON.parse(jsonString);
	}

	// Convert all values in the JSON to arrays
	for (const key in json) {
		json[key] = Array.isArray(json[key]) ? json[key] : [json[key]];
	}

	return json;
}

export function quotify(text: string): string {
	return `"${text}"`;
}

export function removeEndPunctuation(text: string): string {
	// If the only punctuation is in the end, remove it
	if (text.endsWith(".") && text.split(".").length === 2) {
		return text.substring(0, text.length - 1);
	}

	return text;
}

export function unquotify(text: string): string {
	const pairs = [`"`, `'`, "`"];

	for (const pair of pairs) {
		//const quotesInTextCount = countMatches(text, pair);

		// Remove quotes if there are two quotes in the beginning and end (eg. '"Idea 1"')
		if (text.startsWith(pair) && text.endsWith(pair)) {
			text = text.substring(1);
			text = text.substring(0, text.length - 1);
		}

		if (text.startsWith(pair)) {
			// Remove quote if there is only one quote, and the text starts with it (eg. '"Idea 1')
			if (countMatches(text, pair) === 1) {
				text = text.substring(1);
			}

			// Remove quotes if there are two quotes but the text doesn't end with it (eg. '"Idea 1": Test')
			if (!text.endsWith(pair)) {
				text = text.replace(new RegExp(`^${pair}(.*?)${pair}`), "$1");
			}
		}
	}

	// Remove quote if there is only one quote, and the text starts with it
	// if (text.startsWith(`"`) && text.lastIndexOf(`"`) === 0) {
	// 	return text.replace(/^"(.*)"$/, "$1");
	// }

	return text;
}

export function unmarkdownify(text: string): string {
	// Remove:
	// - "**"
	// - "__"
	return text.replace(/(\*\*|__)/g, "");
}

/**
 * Turns a string into kebab case.
 * @param str
 */
export function pascalToKebabCase(str: string): string {
	return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

/**
 * Turns string parts into a numbered list.
 * @param parts
 */
export function listifyText(parts: string[]): string {
	return parts.map((part, index) => `${index + 1}. ${part}`).join("\n");
}

/**
 * Adds commands between parts and ends with "and" before last item if there are more than one.
 * @param parts
 * @param lastSeparator
 */
export function commaListifyText(parts: string[], lastSeparator = "and"): string {
	const len = parts.length;

	if (len > 1) {
		const allButLast = parts.slice(0, -1).join(", ");
		return `${allButLast} ${lastSeparator} ${parts[len - 1]}`;
	}

	return parts.join(", ");
}

/**
 * Uppercases the first letter of a string.
 * @param str
 */
export function uppercaseFirstLetter(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Returns a random character.
 */
export function randomLetter() {
	return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
}

/**
 * Determines if a string is empty.
 * @param text
 */
export function hasValue(text: string | undefined | null): text is string {
	return text != null && text.trim().length > 0;
}

/**
 * Sanitizes HTML.
 * @param str
 */
export function sanitizeHTML(str: string) {
	return str.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}

/**
 * Highlights a text by adding <mark> element around.
 * @param text
 * @param query
 * @param isMarkdown
 */
export function highlightText({ text, query, isHTML }: { text?: string; query?: string; isHTML?: boolean }): string {
	if (isHTML) {
		text = sanitizeHTML(text ?? "");
	}

	if (text == null || text.length === 0 || query == null || query.length === 0) return text ?? "";
	if (query == null || query.length === 0) return text;
	query = removeSpecialCharacters(query);
	return text.replaceAll(
		new RegExp(
			`(${query
				?.split(" ")
				.filter((t) => t.length > 0)
				.join("|")})`,
			"gi"
		),
		(match: string) => (isHTML ? `<mark>${match}</mark>` : `==${match}==`)
	);
}

/**
 * Removes regex characters and markdown characters from text.
 * @param text
 */
export function removeSpecialCharacters(text: string) {
	return text.replaceAll(/([.*+?^=!:${}()|[\]/\\]<>#*_-`)/gi, "\\$1");
}

/**
 * Truncates a string to a given length from the middle.
 * @param str
 * @param maxLength
 * @param separator
 */
export function truncateString(str: string, maxLength: number, separator = "..."): string {
	if (str.length <= maxLength) {
		return str;
	}

	// Prioritize the last part of the string
	const firstPart = str.substring(0, maxLength / 3).split(" ");
	const lastPart = str.substring(str.length - maxLength / 1.5).split(" ");

	return [
		...(firstPart.length > 1 ? firstPart.slice(0, firstPart.length - 1) : firstPart),
		separator,
		...(lastPart.length > 1 ? lastPart.slice(1) : lastPart)
	].join(" ");
}

export type TruncateLengthKind = "short" | "medium" | "long" | "none";

/**
 * Truncates AI user input.
 * @param text
 * @param length
 */
export function truncateAIUserInput<T extends string | number>(
	text?: T,
	length: TruncateLengthKind = "medium"
): T | undefined {
	if (text == null) return undefined;
	if (typeof text !== "string") return text;

	switch (length) {
		case "short":
			return truncateString(text, 500) as T;
		case "medium":
			return truncateString(text, 1000) as T;
		case "long":
			return truncateString(text, 2000) as T;
		default:
			// return truncateString(text, 400000) as T;
			// Limit set based on: This model's maximum context length is 16385 tokens. However, you requested 18586 tokens (15586 in the messages, 3000 in the completion). Please reduce the length of the messages or completion.
			return truncateString(text, 35000) as T;
	}
}

/**
 * Counts the number of matches in a string.
 * @param text
 * @param needle
 */
export function countMatches(text: string, needle: string) {
	return text.split(needle).length - 1;
}

/**
 * Joins text parts with a separator and filters out the empty ones.
 */
export function joinSentences(sentences: (string | undefined | null)[]) {
	const filteredSentences = sentences.map((sentence) => sentence?.trim()).filter(hasValue) as string[];

	if (filteredSentences.length === 0) return "";

	return filteredSentences
		.map(
			(sentence, i) => sentence + (sentence.endsWith(".") ? "" : ".") + (i === filteredSentences.length - 1 ? "" : " ")
		)
		.join("");
}

/**
 * Returns a random letter.
 * @param count
 * @param alphabet
 */
export function getRandomLetters(count: number, alphabet: string = "abcdefghijklmnopqrstuvwxyz"): string[] {
	return pickRandomItems(alphabet.split(""), count);
}

/**
 * Extracts emojis from text.
 * @param text
 */
export function extractEmojisFromText(text: string): { emojis: string[]; text: string } {
	// Regex to match Unicode emojis
	//const emojiRegex = /([\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F1E0}-\u{1F1FF}]|[\u{1F201}-\u{1F2FF}]|[\u{2300}-\u{23FF}]|[\u{FE0F}])/gu;
	const emojiRegex = /(\p{Emoji}\uFE0F|\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;

	// Extract emojis
	let emojis = text.match(emojiRegex) || [];

	// Remove emojis from text
	let cleanedText = text.replace(emojiRegex, "").trim();

	return {
		emojis: emojis,
		text: cleanedText
	};
}

export function getFirstAndLastName(name: string): [string, string] | [string] {
	const parts = name.split(/\s+/g).filter((part) => part?.length);

	if (parts.length === 0) return [name];
	if (parts.length <= 2) return parts as ReturnType<typeof getFirstAndLastName>;

	return [parts[0], parts[parts.length - 1]];
}

export function extractElementsFromText(text: string): {
	newText: string;
	details?: string;
	images: string[];
} {
	// Example text: Hello [World](https://world.com) and ![Image](https://image.com) || These are details

	// Get the details
	let [newText, details] = text.split("||").map((part) => part.trim());

	// Get all images from newText and replace them in newText
	const images: string[] = [];
	newText = newText.replace(/!\[([^\]]+)\]\(([^)]+)\)/g, (match, alt, src) => {
		images.push(src);
		return "";
	});

	return {
		newText: newText.trim(),
		//newText: text,
		details: details?.trim(),
		images
	};
}

export function toBinaryStr(str: string) {
	const encoder = new TextEncoder();
	const charCodes = encoder.encode(str);
	return String.fromCharCode(...charCodes);
}

export function fromBinaryStr(binaryStr: string) {
	const decoder = new TextDecoder();
	const charCodes = new Uint8Array([...binaryStr].map((char) => char.charCodeAt(0)));
	return decoder.decode(charCodes);
}
