import { HexColor, Palette, ThemeColor } from "../types/color-model";
import { pickRandomItem } from "./array-util";
import { COLOR_BLACK_DIRTY, COLOR_WHITE_DIRTY, PALETTE } from "../constants/palette-constants";

/**
 * Gets a random light color from a palette.
 * @param palette
 */
export function getRandomLightColor(palette: Palette) {
	return getRandomColor(palette, ["light", "lighter"]);
}

/**
 * Gets a random dark color from a palette.
 * @param palette
 */
export function getRandomDarkColor(palette: Palette) {
	return getRandomColor(palette, ["dark", "darker"]);
}

export function getRandomMindmapPaletteColorIndex() {
	return String(Math.floor(Math.random() * 1000));
}

/**
 * Gets a random color from a palette.
 * @param palette
 * @param variants
 */
export function getRandomColor(
	palette: Palette = PALETTE,
	variants: (keyof ThemeColor)[] = ["darker", "dark", "normal", "light", "lighter"]
) {
	const colors = Object.values(palette);
	const color = pickRandomItem(colors);
	const variant = pickRandomItem(variants);
	return color[variant] as HexColor;
}

/**
 * Returns a contrast color based on a hex.
 * @param hex
 */
export function getContrastColor(hex: HexColor) {
	// If a leading # is provided, remove it
	if (hex.slice(0, 1) === "#") {
		hex = hex.slice(1);
	}

	// Convert to RGB value
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	// Get YIQ ratio
	const yiq = (r * 299 + g * 587 + b * 114) / 1000;

	// Check contrast
	return yiq >= 128 ? COLOR_BLACK_DIRTY : COLOR_WHITE_DIRTY;
}

export function isHex(hex: string) {
	return /^#[0-9A-F]{6}$/i.test(hex);
}

/**
 * Lightens or darkens a hex color.
 * @param hex - the hex color string (e.g. "#RRGGBB")
 * @param factor - the amount to lighten or darken the color (0 = no change, 1 = lighter, -1 = darker)
 * @returns the modified hex color string
 */
/*export function modifyColor(hex: string, factor: number = 0): string {
	// Parse the hex color string to an integer value
	const intValue = parseInt(hex.substring(1), 16);

	// Get the red, green, and blue components of the color
	const red = (intValue >> 16) & 255;
	const green = (intValue >> 8) & 255;
	const blue = intValue & 255;

	// Modify the color components based on the factor
	const modifier = factor < 0 ? 0 : 255;
	const delta = Math.round(Math.abs(factor) * modifier);

	const newRed = Math.max(Math.min(red + delta * factor, 255), 0);
	const newGreen = Math.max(Math.min(green + delta * factor, 255), 0);
	const newBlue = Math.max(Math.min(blue + delta * factor, 255), 0);

	// Convert the modified color components back to a hex color string
	const newIntValue = (newRed << 16) + (newGreen << 8) + newBlue;
	return `#${newIntValue.toString(16).padStart(6, "0")}`;
}*/

// /**
//  * Lightens or darkens a hex color while preserving some hue.
//  * @param hex - the hex color string (e.g. "#RRGGBB")
//  * @param factor - the amount to lighten or darken the color (0 = no change, 1 = lighter, -1 = darker)
//  * @param hue - the hue of the modified color (0 to 360)
//  * @returns the modified hex color string
//  */
// export function modifyColor(hex: string, factor: number = 0, hue: number = 0): string {
// 	// Convert hex color to RGB color
// 	const r = parseInt(hex.substring(1, 3), 16);
// 	const g = parseInt(hex.substring(3, 5), 16);
// 	const b = parseInt(hex.substring(5, 7), 16);
//
// 	// Convert RGB color to HSL color
// 	const hsl = rgbToHsl(r, g, b);
//
// 	// Modify the lightness component of the HSL color based on the factor
// 	const newL = Math.max(Math.min(hsl.l + factor * 100, 100), 0);
//
// 	// Set the hue component of the HSL color based on the given hue
// 	const newHsl = { h: hue, s: hsl.s, l: newL };
//
// 	// Convert the modified HSL color to RGB color
// 	const rgb = hslToRgb(newHsl.h, newHsl.s, newHsl.l);
//
// 	// Convert the modified RGB color to hex color
// 	return rgbToHex(rgb.r, rgb.g, rgb.b);
// }
//
// /**
//  * Converts an RGB color to an HSL color.
//  * @param r - the red component (0 to 255)
//  * @param g - the green component (0 to 255)
//  * @param b - the blue component (0 to 255)
//  * @returns an object containing the HSL components (h: 0 to 360, s: 0 to 1, l: 0 to 1)
//  */
// export function rgbToHsl(r: number, g: number, b: number): { h: number; s: number; l: number } {
// 	r /= 255;
// 	g /= 255;
// 	b /= 255;
//
// 	const max = Math.max(r, g, b);
// 	const min = Math.min(r, g, b);
// 	let h = 0;
// 	let s = 0;
// 	let l = (max + min) / 2;
//
// 	if (max !== min) {
// 		const d = max - min;
// 		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
// 		switch (max) {
// 			case r:
// 				h = (g - b) / d + (g < b ? 6 : 0);
// 				break;
// 			case g:
// 				h = (b - r) / d + 2;
// 				break;
// 			case b:
// 				h = (r - g) / d + 4;
// 				break;
// 		}
// 		h /= 6;
// 	}
//
// 	return { h: Math.round(h * 360), s, l };
// }
//
// /**
//
// Converts an HSL color to an RGB color.
// @param h - the hue component (0 to 360)
// @param s - the saturation component (0 to 1)
// @param l - the lightness component (0 to 1)
// @returns an object containing the RGB components (r: 0 to 255, g: 0 to 255, b: 0 to 255)
//  */
// export function hslToRgb(h: number, s: number, l: number): { r: number; g: number; b: number } {
// 	let r, g, b;
// 	if (s === 0) {
// 		r = g = b = l;
// 	} else {
// 		const hue2rgb = function hue2rgb(p: number, q: number, t: number) {
// 			if (t < 0) t += 1;
// 			if (t > 1) t -= 1;
// 			if (t < 1 / 6) return p + (q - p) * 6 * t;
// 			if (t < 1 / 2) return q;
// 			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
// 			return p;
// 		};
// 		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
// 		const p = 2 * l - q;
// 		r = hue2rgb(p, q, h + 1 / 3);
// 		g = hue2rgb(p, q, h);
// 		b = hue2rgb(p, q, h - 1 / 3);
// 	}
// 	return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
// }
//
// /**
//
// Converts an RGB color to a hex color.
// @param r - the red component (0 to 255)
// @param g - the green component (0 to 255)
// @param b - the blue component (0 to 255)
// @returns the hex color string (e.g. "#RRGGBB")
//  */
// export function rgbToHex(r: number, g: number, b: number): string {
// 	return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
// }
