import { Room } from "../model/room";
import { RoomAccessRoleKind } from "../model/room-access";
import { cmpIds } from "./model-util";
import { Idea } from "../model/idea";
import { User } from "../model/user";
import { DEFAULT_REACTIONS } from "../constants/constants";

export function canEditIdea({
	room,
	idea,
	sessionUser
}: {
	room?: Room | null;
	idea?: Idea;
	sessionUser?: User | null;
}) {
	return canFacilitateRoom(room) || cmpIds(idea?.createdBy, sessionUser);
}

export function canUseReactions({ room, idea }: { room?: Room | null; idea?: Idea }) {
	return (room?.reactions ?? DEFAULT_REACTIONS).length > 0 && !idea?.isCollapsed;
}

export function canFacilitateRoom(room?: Room | null) {
	return room?.role === RoomAccessRoleKind.FACILITATOR;
}
