const debounceMap = new Map<any, { timeout: any; date: Date; forceMs?: number }>();
const throttleMap = new Map<any, number>();

/**
 * Clears a debounced function.
 * @param id
 */
export function clearDebounce(id: any) {
	const match = debounceMap.get(id);
	if (match != null) clearTimeout(match.timeout);
	debounceMap.delete(id);
}

/**
 * Debounces a function.
 * @param cb
 * @param id
 * @param ms
 * @param options
 */
export function debounce(cb: Function, id: any, ms: number = 200, options: { forceMs?: number } = {}) {
	const match = debounceMap.get(id);
	if (match != null) clearTimeout(match.timeout);

	if (match != null && match.forceMs != null && Date.now() - match.date.getTime() >= match.forceMs) {
		debounceMap.delete(id);
		cb();
		return;
	}

	debounceMap.set(id, {
		timeout: setTimeout(() => {
			cb();
			debounceMap.delete(id);
		}, ms),
		date: match?.date || new Date(),
		forceMs: options.forceMs
	});
}

export function throttle(cb: Function, id: any, ms: number = 200) {
	const last = throttleMap.get(id);
	const now = Date.now();
	if (last != null && now - last < ms) return;
	throttleMap.set(id, now);
	cb();
}
