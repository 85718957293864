import { ActionSubjectCached, runAction } from "../action";
import { teamsService } from "../../services/teams-service";
import { app, authentication } from "@microsoft/teams-js";

export const teamsAction = {
	initialize: new ActionSubjectCached<boolean>(),
	getAuthToken: new ActionSubjectCached<string | undefined>(),
	setContext: new ActionSubjectCached<app.Context | undefined>()
};

export const initializeTeams = (validMessageOrigins?: string[]) =>
	runAction(teamsAction.initialize, () => teamsService.initialize(validMessageOrigins));
export const getTeamsAuthToken = (options?: authentication.AuthTokenRequestParameters) =>
	runAction(teamsAction.getAuthToken, () => teamsService.getAuthToken(options));
export const setTeamsContext = (context: app.Context | undefined) => runAction(teamsAction.setContext, () => context);
