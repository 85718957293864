import { AIEngineChatMessage, PreparedAIAction } from "shared";

interface LocalAIAssistantSession {
	prompt: (message: string, options?: LocalAIAssistantPromptOptions) => Promise<string>;
	promptStreaming: (message: string, options?: LocalAIAssistantPromptOptions) => ReadableStream;
	clone: () => Promise<LocalAIAssistantSession>;
	destroy: () => void;

	countPromptTokens(input: string, options?: LocalAIAssistantPromptOptions): Promise<number>;
	readonly maxTokens: number;
	readonly tokensSoFar: number;
	readonly tokensLeft: number;

	readonly topK: number;
	readonly temperature: number;

	oncontextoverflow: ((this: LocalAIAssistant, ev: Event) => any) | null;
	ondownloadprogress: ((this: LocalAIAssistant, ev: Event) => any) | null;
}

/**
 * Monitor progress callback.
 * In cases where the model needs to be downloaded as part of creation, you can monitor the download progress (e.g. in order to show your users a progress bar) using code such as the following.
 * https://github.com/explainers-by-googlers/prompt-api?tab=readme-ov-file#download-progress
 */
type LocalAICreateMonitorCallback = (m: LocalAIAssistantSession) => void;

/**
 * Options for the assistant prompt.
 */
interface LocalAIAssistantPromptOptions {
	signal?: AbortSignal;
}

/*
 */
/**
 * The availability of the AI assistant.
 * https://github.com/explainers-by-googlers/prompt-api?tab=readme-ov-file#capabilities-detection
 * "no", indicating the device or browser does not support prompting a language model at all.
 * "after-download", indicating the device or browser supports prompting a language model, but it needs to be downloaded before it can be used.
 * "readily", indicating the device or browser supports prompting a language model and it’s ready to be used without any downloading steps.
 */
type LocalAIAvailability = "no" | "after-download" | "readily";

/**
 * Different roles the prompts can have.
 */
type LocalAIAssistantPromptRole = "system" | "user" | "assistant";

/**
 * Options for the initial prompts when creating a session.
 */
interface LocalAIAssistantPrompt {
	role: LocalAIAssistantPromptRole;
	content: string;
}

/**
 * Options for creating a session.
 */
interface LocalAIAssistantCreateSessionOptions {
	initialPrompts?: LocalAIAssistantPrompt[];
	systemPrompt?: string;
	monitor?: LocalAICreateMonitorCallback;
	temperature?: number;
	topK?: number;
	signal?: AbortSignal;
}

/**
 * Factory for creating AI assistants and checking availability.
 */
interface LocalAIAssistantFactory {
	capabilities: () => Promise<{ available: LocalAIAvailability }>;
	create: (options?: LocalAIAssistantCreateSessionOptions) => Promise<LocalAIAssistantSession | undefined>;
}

declare global {
	interface Window {
		ai?: {
			assistant: LocalAIAssistantFactory;
		};
	}
}

/**
 * Wrapper around the browser AI instance.
 */
class LocalAIAssistant {
	constructor(
		private session: LocalAIAssistantSession,
		private options?: LocalAIAssistantCreateSessionOptions
	) {}

	async prompt(message: string, options?: LocalAIAssistantPromptOptions): Promise<string> {
		return this.session.prompt(message, options);
	}

	async *promptStreaming(message: string, options?: LocalAIAssistantPromptOptions): AsyncGenerator<string> {
		const stream = this.session.promptStreaming(message, options);

		let lastCharCount = 0; // Tracks the character count up to the last chunk

		// For some reason the API pushes ALL the text in each iteration and not just the new part.
		// Only yield the new text.
		// @ts-ignore
		for await (const accumulatedText of stream) {
			const newText = accumulatedText.slice(lastCharCount);
			lastCharCount = accumulatedText.length; // Update the character count
			yield newText;
		}
	}

	async clone(): Promise<LocalAIAssistant> {
		return new LocalAIAssistant(await this.session.clone(), this.options);
	}

	destroy() {
		this.session.destroy();
	}
}

/**
 * Turns an ai action message into a string.
 * @param message
 */
function aiActionMessageToString(message: AIEngineChatMessage): string {
	return typeof message.content === "string"
		? message.content
		: message.content.map((c) => (c.kind === "text" ? c.value : "")).join(" ");
}

/**
 * Chromes new experimental AI.
 * https://github.com/explainers-by-googlers/prompt-api
 * https://github.com/explainers-by-googlers/prompt-api/blob/main/chrome-implementation-differences.md
 */
class LocalAIService {
	/**
	 * Turns an ai action into session options.
	 * @param action
	 */
	aiActionToCreateSessionOptions(action: PreparedAIAction): {
		prompt: string | undefined;
		options: LocalAIAssistantCreateSessionOptions;
	} {
		// Take the last prompt from the array
		const messages = [...action.messages];
		const lastMessage = messages.splice(-1)?.[0];

		// Convert the messages to something usable
		const systemPrompts: string[] = [];
		const nonSystemPrompts: LocalAIAssistantPrompt[] = [];
		for (const message of messages) {
			// It is required for the system prompt to always come first so we need to merge the first ones...
			if (message.role === "system") {
				systemPrompts.push(aiActionMessageToString(message));
			} else {
				// Add the prompt and convert system to user, ensuring system prompt never comes after the first
				nonSystemPrompts.push({
					role: message.role,
					content: aiActionMessageToString(message)
				});
			}
		}

		return {
			prompt: lastMessage != null ? aiActionMessageToString(lastMessage) : undefined,
			options: {
				initialPrompts: [
					// Merges system is always the first!
					...(systemPrompts.length > 0
						? [
								{
									role: "system",
									content: systemPrompts.join("\r\n\r\n")
								} as LocalAIAssistantPrompt
							]
						: []),
					...nonSystemPrompts
				],
				temperature: action.modelOptions?.temperature ?? 1,
				topK: action.modelOptions?.topP ?? 0.8
			}
		};
	}

	/**
	 * Checks if the local assistant AI is available.
	 * https://medium.com/@kenzic/getting-started-window-ai-in-chrome-c2982efada33
	 */
	async getAssistantAvailability(): Promise<LocalAIAvailability> {
		try {
			if ("ai" in window && window.ai != null) {
				return (await window.ai.assistant.capabilities?.()).available ?? "no";
			}
		} catch (err) {
			console.error("Could not create local AI assistant: ", err);
		}

		return "no";
	}

	/**
	 * Determines if the ai assistant is available.
	 */
	async checkIsAssistantAvailable() {
		return (await this.getAssistantAvailability()) === "readily";
	}

	/**
	 * Creates an ai assistant.
	 * @param options
	 */
	async createAIAssistant(options?: LocalAIAssistantCreateSessionOptions): Promise<LocalAIAssistant | undefined> {
		if (await this.checkIsAssistantAvailable()) {
			const session = await window.ai?.assistant?.create?.(options);
			if (session != null) {
				return new LocalAIAssistant(session, options);
			}
		}

		return undefined;
	}
}

export const localAIService = new LocalAIService();
