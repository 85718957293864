import { BehaviorSubject, defaultIfEmpty, firstValueFrom, Observable, take } from "rxjs";

export async function takeFirst<T>(observable: BehaviorSubject<T>): Promise<T>;
export async function takeFirst<T>(observable: Observable<T> | BehaviorSubject<T>): Promise<T | undefined>;
export async function takeFirst<T>(observable: Observable<T> | BehaviorSubject<T>): Promise<T | undefined> {
	if (observable instanceof BehaviorSubject) return observable.value;

	// return await firstValueFrom(observable.pipe(startWith(undefined), take(1)));
	const result = await firstValueFrom(observable.pipe(/*startWith(null),*/ defaultIfEmpty(null), take(1)));
	return result === null ? undefined : result;
}
