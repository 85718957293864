import { api } from "../../api/api";
import { ActionSubject, runAction } from "../action";
import {
	AIAction,
	BuiltInAIActionKind,
	BulkUpdateEntry,
	canEditIdea,
	getRandomMindmapPaletteColorIndex,
	hasValue,
	Id,
	Idea,
	Media,
	ModelData,
	paginateItems,
	parseAIActionOutput,
	Poll,
	PollRankedIdeasFilter,
	QueryOptions,
	Ref,
	Room,
	StabilityAiStylePreset,
	takeId
} from "shared";
import { ideaStore } from "./idea-store";
import { modelStore } from "../model/model-store";
import { openConfirmDelete } from "../../util/dialog-helper";
import { getAvailablePositionInMindmap } from "../../molecules/mindmap/mindmap-action";
import { MindmapComponentNodeSource } from "mindmap";
import { entityToName, entityToNameWithCount } from "../../util/translate-util";
import { showToast } from "../../atoms/toast";
import { ICON_ERROR, ICON_SUCCESS_CIRCLE } from "../../../icons";
import { sessionStore } from "../session/session-store";
import { get } from "lit-translate";

export const ideaAction = {
	get: new ActionSubject<Idea>(),
	list: new ActionSubject<Idea[], Ref<Room>>(),
	clear: new ActionSubject<undefined>(),
	listForComparison: new ActionSubject<[Idea, Idea][], Ref<Poll>>(),
	listInPoll: new ActionSubject<Idea[], Ref<Poll>>(),
	reactToIdea: new ActionSubject<{ reaction: string; count?: number }, Ref<Idea>>(),
	create: new ActionSubject<Idea, Ref<Room>>(),
	bulkCreate: new ActionSubject<Idea[], Ref<Room>>(),
	createIdeaWithParent: new ActionSubject<{ idea: Idea; startEditing?: boolean }, Ref<Room>>(),
	update: new ActionSubject<Idea>(),
	bulkUpdate: new ActionSubject<Idea[]>(),
	groupIdeas: new ActionSubject<Idea[]>(),
	remove: new ActionSubject<Ref<Idea>>(),
	bulkRemove: new ActionSubject<Id<Idea>[]>(),
	transformIdeasWithAIAction: new ActionSubject<{ ideas: Ref<Idea>[] }, Ref<Room>>(),
	createIdeasFromMindmapComponentSources: new ActionSubject<Idea[], Ref<Room>>()
};

export const clearIdeas = () => runAction(ideaAction.clear, () => undefined);

export const listIdeasInRoom = (room: Ref<Room>, options?: QueryOptions<Idea>) =>
	runAction(
		ideaAction.list,
		() => api.idea.list({ ...options, filter: { ...(options?.filter ?? {}), room: takeId(room) } }),
		room
	);

export const listIdeasInPoll = (poll: Ref<Poll>, pollFilter?: PollRankedIdeasFilter, options?: QueryOptions<Idea>) =>
	runAction(ideaAction.listInPoll, () => api.idea.listInPoll(poll, pollFilter, options), poll);

export const listIdeasForComparison = (poll: Ref<Poll>) =>
	runAction(ideaAction.listForComparison, () => api.idea.listIdeasForComparison(takeId(poll)), poll);

export const updateIdea = (idea: Ref<Idea>, data: ModelData<Idea>) => {
	if (Object.keys(data).length === 0) return;

	return runAction(ideaAction.update, async () => {
		// Optimistic update
		modelStore.mutate(idea, (idea) => ({
			...idea,
			...(data as Partial<Idea>)
		}));

		if ("text" in data) {
			const url = data.text?.match(/^https:\/\/[^\s]+/)?.[0];
			if (!data.media?.length && url != null) {
				try {
					const unfurled = await api.unfurlUrl(url);

					if (unfurled != null) {
						if (unfurled.mediaId != null) {
							data.media = [unfurled.mediaId];
						}

						data.color = unfurled.color ?? data.color;
					}
				} catch (error) {
					console.error("Failed to unfurl URL", error);
				}
			}
		}

		return await api.idea.update(takeId(idea), data);
	});
};

export const bulkUpdateIdeasWithData = (ideas: Ref<Idea>[], getData: (idea: Ref<Idea>) => ModelData<Idea>) => {
	return bulkUpdateIdeas(
		ideas.map((idea) => ({
			id: takeId(idea),
			data: getData(idea)
		}))
	);
};

export const bulkUpdateIdeas = (entries: BulkUpdateEntry<Idea>[]) =>
	runAction(ideaAction.bulkUpdate, async () => {
		// Make sure that unsetting parents will also reset the color
		entries = entries.map((entry) => {
			if (entry.data.parent != null) {
				return {
					...entry,
					data: {
						...entry.data,
						color: null
					}
				} as BulkUpdateEntry<Idea>;
			}

			return entry;
		});

		for (const entry of entries) {
			console.log("bulkUpdateIdeas", entry.id, entry.data);
			modelStore.mutate(entry.id, (idea) => ({
				...idea,
				...(entry.data as Partial<Idea>)
			}));
		}

		// Wait until all parents have been created
		await Promise.all(
			entries.map(async (entry) => {
				if (entry.data.parent != null) {
					await ideaStore.waitForIdeaSynced(entry.data.parent);
				}
			})
		);

		// Make sure that all ids have been synced
		await Promise.all(
			entries.map(async (entry) => {
				await ideaStore.waitForIdeaSynced(entry.id);
			})
		);

		return api.idea.bulkUpdate(entries);
	});

export const detachIdeaFromParent = (idea: Idea) =>
	updateIdea(idea, {
		parent: null,
		color: idea.color ?? getRandomMindmapPaletteColorIndex()
	});

export async function bulkDeleteIdeasWithConfirm(ideas: Ref<Idea>[]) {
	const sessionUser = sessionStore.sessionUser;
	if (sessionUser == null || ideas.length === 0) return;

	const canEdit = ideas.every((id) => {
		const idea = modelStore.getInstant(id);
		if (idea == null) return false;
		const room = modelStore.getInstant(idea.room);
		if (room == null) return false;

		return canEditIdea({ idea, room, sessionUser });
	});

	// Make sure the user has access
	if (!canEdit) {
		showToast("As a participant, you can only delete your own ideas", { icon: ICON_ERROR });
		return;
	}

	const isSingular = ideas.length === 1;
	if (
		await openConfirmDelete({
			text: isSingular
				? get(`Are you sure you want to delete this idea and it's branches? This action cannot be undone.`)
				: get(`Are you sure you want to delete {{entityWithCount}} and their branches? This action cannot be undone.`, {
						entityWithCount: entityToNameWithCount("idea", ideas.length)
					}),
			confirmText: get(`Delete {{entityWithCount}}`, { entityWithCount: entityToName("idea", isSingular) })
		})
	) {
		try {
			bulkDeleteIdeas(ideas, { includeChildren: true });

			showToast(get(`Ideas successfully deleted`), { icon: ICON_SUCCESS_CIRCLE });
		} catch (err) {
			showToast(get(`Something went wrong while deleting ideas`), { icon: ICON_ERROR });
		}
	}
}

export const bulkDeleteIdeas = (ideas: Ref<Idea>[], options?: { includeChildren?: boolean }) =>
	runAction(ideaAction.bulkRemove, async () => {
		const ideaIds = ideas.map((idea) => takeId(idea));

		const removedIds = api.idea.bulkRemove(ideaIds, options);

		// If "includeChildren" wait for server to calculate which children
		return options?.includeChildren ? await removedIds : ideaIds;
	});

export const deleteIdea = (idea: Ref<Idea>) =>
	runAction(ideaAction.remove, async () => {
		await api.idea.remove(takeId(idea));
		return idea;
	});

export const getIdea = (idea: Id<Idea>) => runAction(ideaAction.get, () => api.idea.get(idea));

export const bulkCreateIdeas = (room: Ref<Room>, data: ModelData<Idea>[]) =>
	runAction(
		ideaAction.bulkCreate,
		async () => {
			// Optimistically create the ideas
			const localIdeas: Idea[] = [];

			for (const ideaData of data) {
				// Always apply a position by asking the mindmap for an available position
				const position =
					ideaData.parent != null ? undefined : ideaData.position ?? (await getAvailablePositionInMindmap(room));

				// Parent
				const parentId = takeId(ideaData.parent);
				if (parentId != null) await ideaStore.waitForIdeaSynced(parentId);

				const localIdea = ideaStore.createLocalIdea(room, { ...ideaData, position, parent: parentId });

				localIdeas.push(localIdea);
			}

			// TODO
			for (const localIdea of localIdeas) {
				// Create the idea in the background so we avoid waiting for the idea on bad WIFI connection
				api.idea
					.create(room, {
						...localIdea,
						id: localIdea.id,
						parent: localIdea.parent,
						position: localIdea.position,
						color: localIdea.color,
						order: localIdea.order
					})
					.then();
			}

			return localIdeas;
		},
		room
	);

export const createIdea = (room: Ref<Room>, data: ModelData<Idea>) =>
	runAction(
		ideaAction.create,
		async () => {
			// Always apply a position by asking the mindmap for an available position
			const position = data.parent != null ? undefined : data.position ?? (await getAvailablePositionInMindmap(room));

			// Parent
			const parentId = takeId(data.parent);
			if (parentId != null) await ideaStore.waitForIdeaSynced(parentId);

			const localIdea = ideaStore.createLocalIdea(room, { ...data, position, parent: parentId });

			// Create the idea in the background so we avoid waiting for the idea on bad WIFI connection
			api.idea
				.create(room, {
					...data,
					id: localIdea.id,
					parent: localIdea.parent,
					position: localIdea.position,
					color: localIdea.color,
					order: localIdea.order
				})
				.then();

			return localIdea;
		},
		room
	);

export const createIdeaWithParent = (
	room: Ref<Room>,
	data: ModelData<Idea>,
	{ startEditing }: { startEditing?: boolean }
) =>
	runAction(
		ideaAction.createIdeaWithParent,
		async () => {
			// Always apply a position by asking the mindmap for an available position
			const position = data.parent != null ? undefined : data.position ?? (await getAvailablePositionInMindmap(room));

			// Parent
			const parentId = takeId(data.parent);
			if (parentId != null) await ideaStore.waitForIdeaSynced(parentId);

			const localIdea = ideaStore.createLocalIdea(room, { ...data, position, parent: parentId });

			// Create the idea in the background so we avoid waiting for the idea on bad WIFI connection
			api.idea
				.create(room, {
					...data,
					id: localIdea.id,
					parent: localIdea.parent,
					position: localIdea.position,
					color: localIdea.color,
					order: localIdea.order
				})
				.then();

			return {
				idea: localIdea,
				startEditing
			};
		},
		room
	);

export const reactToIdea = (idea: Ref<Idea>, reaction: string, count?: number) =>
	runAction(
		ideaAction.reactToIdea,
		async () => {
			await api.idea.reactToIdea(idea, reaction, count);

			ideaStore.addReactionToIdea(idea, reaction, count ?? 1);

			return {
				reaction,
				count
			};
		},
		idea
	);

export const lockIdea = (idea: Ref<Idea>, locked: boolean) => api.idea.lockIdea(idea, locked);

/**
 * Experimental. Not currently used.
 * @param room
 * @param zoom
 */
// export const highlightIdeasAIAction = (room: Ref<Room>, { zoom }: { zoom?: boolean }) =>
// 	runAction(
// 		ideaAction.highlightIdeas,
// 		async () => {
// 			const selectionSet = new Map<FriendlyId, Promise<Idea>>();
//
// 			// let isStreaming = true;
// 			let text = "";
// 			for await (const chunk of api.aiAction.runStream("selectIdeas", {
// 				room: takeId(room)
// 			})) {
// 				text += chunk;
//
// 				const ideaFriendlyIds = [...text.matchAll(/\[\^(\d+)\]/g)].map((match) => Number(match[1]));
// 				for (const ideaFriendlyId of ideaFriendlyIds) {
// 					if (!selectionSet.has(ideaFriendlyId)) {
// 						selectionSet.set(ideaFriendlyId, api.idea.get(ideaFriendlyId));
// 					}
// 				}
//
// 				console.log(text, ideaFriendlyIds);
// 			}
//
// 			console.log(text, selectionSet);
// 			const ideas = await Promise.all(selectionSet.values());
// 			return { ideas, zoom };
// 		},
// 		room
// 	);

export const transformIdeasWithAIAction = (
	action: BuiltInAIActionKind | Ref<AIAction>,
	room: Ref<Room>,
	ideas: Ref<Idea>[]
) =>
	runAction(
		ideaAction.transformIdeasWithAIAction,
		async () => {
			await paginateItems(
				ideas,
				async (idea) => {
					const text = parseAIActionOutput(
						await api.aiAction.run(action, {
							room: takeId(room),
							ideas: [takeId(idea)]
						}),
						"text"
					);

					await updateIdea(idea, { text });
				},
				{
					pageSize: 3,
					parallel: true
				}
			);

			return {
				ideas
			};
		},
		room
	);

export const groupIdeas = ({
	parentIdea,
	room,
	ideas,
	updateChildIdeasData,
	parentIdeaData
}: {
	room: Ref<Room>;
	ideas: Ref<Idea>[];
	updateChildIdeasData?: Partial<Idea>;
	parentIdeaData?: Partial<Idea>;
	parentIdea?: Idea;
}) =>
	runAction(
		ideaAction.bulkUpdate,
		async () => {
			// Create a parent idea if one is missing
			if (parentIdea == null) {
				parentIdea = await createIdea(room, {
					...(parentIdeaData ?? {})
				});

				await ideaStore.waitForIdeaSynced(parentIdea);
			}

			return [
				parentIdea,
				...(await bulkUpdateIdeasWithData(ideas, () => ({
					parent: takeId(parentIdea),
					color: null as any,
					...(updateChildIdeasData ?? {})
				})))
			];
		},
		room
	);

export const groupIdeasWithAIAction = ({
	action,
	room,
	ideas,
	updateChildIdeasData,
	getParentIdeaData
}: {
	action: BuiltInAIActionKind | Ref<AIAction>;
	room: Ref<Room>;
	ideas: Ref<Idea>[];
	updateChildIdeasData?: Partial<Idea>;
	getParentIdeaData?: (text: string) => Partial<Idea>;
}) =>
	runAction(
		ideaAction.transformIdeasWithAIAction,
		async () => {
			const text = parseAIActionOutput(
				await api.aiAction.run(action, {
					room: takeId(room),
					ideas: ideas.map((idea) => takeId(idea))
				}),
				"text"
			);

			const parentIdea = await createIdea(room, {
				text,
				...(getParentIdeaData?.(text) ?? {})
			});

			await groupIdeas({
				room,
				ideas,
				updateChildIdeasData,
				parentIdea
			});

			return {
				ideas
			};
		},
		room
	);

export const visualizeIdeas = (room: Ref<Room>, ideas: Idea[], style?: StabilityAiStylePreset) =>
	runAction(
		ideaAction.transformIdeasWithAIAction,
		async () => {
			await paginateItems(
				ideas,
				async (idea) => {
					const prompt = parseAIActionOutput(
						await api.aiAction.run("imagePrompt", {
							room: takeId(room),
							ideas: [takeId(idea)]
						}),
						"text"
					);

					if (!hasValue(prompt)) return;

					const image = await api.media.generate(prompt, undefined, style);
					if (image == null) return;

					await updateIdea(idea, { media: [...(idea.media ?? []), takeId(image)] });
				},
				{
					pageSize: 3,
					parallel: true
				}
			);

			return {
				ideas
			};
		},
		room
	);

export const removeMediaFromIdeaWithConfirm = async (idea: Idea, media: Ref<Media>) => {
	const yes = await openConfirmDelete({
		text: get("Are you sure you want to delete this file?"),
		confirmText: get("Delete file")
	});

	if (yes) {
		await updateIdea(idea, { media: (idea.media ?? []).filter((m) => takeId(m) !== takeId(media)) });
	}

	return yes;
};

export interface MindmapComponentSourceMeta {
	notes: string;
}

export type MindmapComponentSourceMetaMap = Map<string, MindmapComponentSourceMeta>;

export const createIdeasFromMindmapComponentSources = (
	room: Ref<Room>,
	components: MindmapComponentNodeSource[],
	nodeMeta?: MindmapComponentSourceMetaMap
) =>
	runAction(
		ideaAction.createIdeasFromMindmapComponentSources,
		async () => {
			const componentIdToIdeaMap = new Map<string, Idea>();

			const mindmapComponentNodeSourceToIdeaData = (component: MindmapComponentNodeSource): Partial<Idea> => {
				const meta = nodeMeta?.get(component.id);

				return {
					text: component.text,
					direction: component.direction,
					isGroup: component.isGroup,
					isLocked: component.isLocked,
					isCollapsed: component.isCollapsed,
					isChildrenFolded: component.isChildrenFolded,
					position: component.position,
					order: component.order,
					color: component.color,
					sticker: component.sticker?.id,
					media: component.media?.map((media) => media.id as Id<Media>),
					details: meta?.notes
				};
			};

			const createIdeasRecursive = async (component: MindmapComponentNodeSource) => {
				if (componentIdToIdeaMap.has(component.id)) return;

				const parentIdea = component.parentId != null ? componentIdToIdeaMap.get(component.parentId) : undefined;

				const idea = await createIdea(room, {
					...mindmapComponentNodeSourceToIdeaData(component),
					parent: takeId(parentIdea)
				});

				// Sleep to make sure that access has been updated
				await ideaStore.waitForIdeaSynced(idea.id);

				componentIdToIdeaMap.set(component.id, idea);

				// Create children components
				const nextComponents = components.filter((c) => c.parentId === component.id);
				await Promise.all(nextComponents.map((c) => createIdeasRecursive(c)));
			};

			// Create root components
			const rootComponents = components.filter((c) => c.parentId == null);
			await Promise.all(rootComponents.map((c) => createIdeasRecursive(c)));

			return Array.from(componentIdToIdeaMap.values());
		},
		room
	);
