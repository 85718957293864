import { GQLShape } from "./gql-util";
import {
	AIAction,
	AuthToken,
	AuthUserInfo,
	ChatMessage,
	Copilot,
	Idea,
	IdeaCluster,
	IdeaMetadata,
	Link,
	Media,
	Model,
	Plan,
	PlanPrice,
	Poll,
	Robot,
	Room,
	RoomSnapshot,
	Session,
	Subscription,
	User,
	WebSocketMessage,
	Workspace,
	WorkspaceAccess
} from "shared";
import { RoomAccess } from "shared/src/model/room-access";
import { UnfurledUrl } from "shared/src/types/unfurled-url";

export const GQL_ID_SHAPE = { id: true };

export const GQL_MODEL_SHAPE: GQLShape<Model> = {
	id: true,
	createdAt: true,
	updatedAt: true
};

export const GQL_MEDIA_SHAPE: GQLShape<Media> = {
	...GQL_MODEL_SHAPE,
	title: true,
	path: true,
	originalUrl: true,
	signedUrl: true,
	createdBy: GQL_ID_SHAPE
};

export const GQL_AUTH_TOKEN: GQLShape<AuthToken> = {
	sessionToken: true,
	refreshToken: true
};

export const GQL_AUTH_USER_INFO_TOKEN: GQLShape<AuthUserInfo> = {
	sub: true,
	name: true,
	email: true
};

export const GQL_ROBOT_SHAPE: GQLShape<Robot> = {
	...GQL_MODEL_SHAPE,
	name: true,
	description: true,
	details: true,
	emptyMessages: true,
	errorMessages: true,
	personality: true,
	group: true,

	createdBy: GQL_ID_SHAPE,
	avatar: GQL_ID_SHAPE,
	workspace: GQL_ID_SHAPE
};

export const GQL_USER_SHAPE: GQLShape<User> = {
	...GQL_MODEL_SHAPE,
	name: true,
	email: true,
	color: true,
	data: true,

	isAnonymous: true,
	copilot: GQL_ID_SHAPE,
	avatar: GQL_ID_SHAPE
};

export const GQL_SESSION_SHAPE: GQLShape<Session> = {
	user: GQL_USER_SHAPE
};

export const GQL_ROOM_ACCESS_SHAPE: GQLShape<RoomAccess> = {
	...GQL_MODEL_SHAPE,
	kind: true,
	role: true,
	user: GQL_USER_SHAPE,
	workspace: GQL_ID_SHAPE,
	room: GQL_ID_SHAPE
};

export const GQL_ACTIVITY_SHAPE: GQLShape<WebSocketMessage> = {};

export const GQL_IDEA_METADATA_SHAPE: GQLShape<IdeaMetadata> = {
	...GQL_MODEL_SHAPE,
	pollResults: true,
	kind: true,
	sentiment: true,
	language: true
};

export const GQL_IDEA_SHAPE: GQLShape<Idea> = {
	...GQL_MODEL_SHAPE,
	friendlyId: true,
	isCollapsed: true,
	isChildrenFolded: true,
	isGroup: true,
	color: true,
	createdBy: GQL_ID_SHAPE,
	reactions: true,
	room: GQL_ID_SHAPE,
	parent: GQL_ID_SHAPE,
	position: true,
	order: true,
	direction: true,
	text: true,
	details: true,
	sticker: true,
	media: true,
	isAIAssisted: true,

	meta: GQL_IDEA_METADATA_SHAPE
};

export const GQL_LINK_SHAPE: GQLShape<Link> = {
	...GQL_MODEL_SHAPE,
	role: true,
	rank: true,
	isDisabled: true,
	code: true
};

export const GQL_COPILOT_SHAPE: GQLShape<Copilot> = {
	...GQL_MODEL_SHAPE,
	room: GQL_ID_SHAPE,
	robot: GQL_ROBOT_SHAPE,
	user: GQL_ID_SHAPE,
	isActive: true
};

export const GQL_CHAT_MESSAGE_SHAPE: GQLShape<ChatMessage> = {
	...GQL_MODEL_SHAPE,
	text: true,
	idea: GQL_ID_SHAPE,
	user: GQL_ID_SHAPE,
	kind: true,
	copilot: GQL_ID_SHAPE
};

export const GQL_WORKSPACE_SHAPE: GQLShape<Workspace> = {
	...GQL_MODEL_SHAPE,
	name: true,
	description: true,
	rank: true,
	logo: GQL_ID_SHAPE,
	link: GQL_ID_SHAPE,
	data: true,
	plan: true,
	features: true,
	subscriptionStatus: true,
	stats: {
		members: true,
		seats: true
	}
};

export const GQL_WORKSPACE_ACCESS_SHAPE: GQLShape<WorkspaceAccess> = {
	...GQL_MODEL_SHAPE,
	user: GQL_USER_SHAPE,
	workspace: GQL_ID_SHAPE,
	rank: true
};

export const GQL_POLL_SHAPE: GQLShape<Poll> = {
	...GQL_MODEL_SHAPE,
	text: true,
	data: true,

	room: GQL_ID_SHAPE
};

export const GQL_ROOM_SNAPSHOT: GQLShape<RoomSnapshot> = {
	...GQL_MODEL_SHAPE,
	room: GQL_ID_SHAPE,
	changeCount: true,
	ideaCount: true,
	users: GQL_USER_SHAPE
};

export const GQL_ROOM_SHAPE: GQLShape<Room> = {
	...GQL_MODEL_SHAPE,
	challenge: true,
	timer: true,
	reactions: true,
	role: true,
	slug: true,
	data: true,
	isPrivate: true,

	activeUsers: GQL_USER_SHAPE,
	link: GQL_ID_SHAPE,
	cover: GQL_ID_SHAPE,
	workspace: GQL_ID_SHAPE,
	poll: GQL_POLL_SHAPE
};

export const GQL_PLAN_PRICE_SHAPE: GQLShape<PlanPrice> = {
	currency: true,
	interval: true,
	price: true
};

export const GQL_PLAN_SHAPE: GQLShape<Plan> = {
	trialDays: true,
	prices: GQL_PLAN_PRICE_SHAPE,
	kind: true,
	features: true
};

export const GQL_SUBSCRIPTION_SHAPE: GQLShape<Subscription> = {
	status: true,
	currentPeriodStart: true,
	cancelAtPeriodEnd: true,
	nextChargeDate: true,
	canceledAt: true,
	cardLast4: true,
	currentPeriodEnd: true,
	quantity: true,
	trialEnd: true,
	pausedUntil: true,
	upcomingPrice: GQL_PLAN_PRICE_SHAPE,
	unitPrice: GQL_PLAN_PRICE_SHAPE,
	totalPrice: GQL_PLAN_PRICE_SHAPE,
	planKind: true
};

export const GQL_AI_ACTION_SHAPE: GQLShape<AIAction> = {
	...GQL_MODEL_SHAPE,
	recipe: true,
	metadata: true,
	icon: GQL_ID_SHAPE,
	workspace: GQL_ID_SHAPE,
	createdBy: GQL_ID_SHAPE
};

export const GQL_IDEA_CLUSTER_SHAPE: GQLShape<IdeaCluster> = {
	id: true,
	title: true,
	color: true,
	sticker: true,
	ideas: GQL_IDEA_SHAPE
};

export const GQL_UNFURLED_URL_SHAPE: GQLShape<UnfurledUrl> = {
	url: true,
	title: true,
	description: true,
	imageUrl: true,
	mediaId: true,
	color: true
};
