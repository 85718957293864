import { GQLMutationRoot, GQLQueryRoot } from "./gql/gql-root";
import { GQLShape, hydrateGQLResult, makeGQLQueryFromShapeAndArgs } from "./gql/gql-util";
import { API_BASE_URL, APP_BUILD_DATE, SESSION_ID } from "../config";
import { APIError, takeId } from "shared";
import { modelStore } from "../state/model/model-store";
import { caughtError } from "../state/activity/activity-action";
import { roomSocket } from "../socket/room-socket";
import { takeFirst } from "../util/observable-util";
import { appStore } from "../state/app/app-store";
import { workspaceStore } from "../state/workspace/workspace-store";
import { roomStore } from "../state/room/room-store";
import { languageStore } from "../state/language/language-store";

export interface ErrorEvent {
	error?: Error;
	response?: Response;
}

export interface RequestOptionsBase {
	noAuth?: boolean;
	abortSignal?: AbortSignal;
	headers?: Record<string, string | null>;
	skipLogoutOnUnauthorized?: boolean;
	skipAbsorbModel?: boolean;
}

export interface RequestOptions extends RequestOptionsBase {
	method: "POST" | "PUT" | "GET" | "DELETE";
	body?: any;
	path: string;
}

export abstract class ApiBase {
	protected abstract logout(): Promise<void>;

	protected async query<
		TRootKey extends keyof GQLQueryRoot,
		TArgs extends GQLQueryRoot[TRootKey]["args"],
		TResult extends GQLQueryRoot[TRootKey]["result"],
		TResultNonArray extends TResult extends (infer TArrayElement)[] ? TArrayElement : TResult
	>(
		rootKey: TRootKey,
		args: TArgs,
		resultShape: GQLShape<TResultNonArray> | true,
		fetchOptions?: RequestOptionsBase
	): Promise<TResult> {
		const { query, variables } = makeGQLQueryFromShapeAndArgs(rootKey, args, resultShape, { opName: rootKey });
		const result = await this.sendGQLRequest(query, variables, fetchOptions);
		const hydratedResult = hydrateGQLResult(result);

		if (fetchOptions?.skipAbsorbModel) {
			return hydratedResult[rootKey];
		}

		return modelStore.absorb(hydratedResult[rootKey]);
	}

	protected async mutate<
		TRootKey extends keyof GQLMutationRoot,
		TArgs extends GQLMutationRoot[TRootKey]["args"],
		TResult extends GQLMutationRoot[TRootKey]["result"],
		TResultNonArray extends TResult extends Array<infer R> ? R : TResult
	>(
		rootKey: TRootKey,
		args: TArgs,
		resultShape: GQLShape<TResultNonArray>,
		fetchOptions?: RequestOptionsBase
	): Promise<TResult> {
		const { query, variables } = makeGQLQueryFromShapeAndArgs(rootKey, args, resultShape as GQLShape<any>, {
			isMutation: true,
			opName: rootKey
		});

		const result = await this.sendGQLRequest(query, variables, fetchOptions);
		const hydratedResult = hydrateGQLResult(result);

		if (fetchOptions?.skipAbsorbModel) {
			return hydratedResult[rootKey];
		}

		return modelStore.absorb(hydratedResult[rootKey]);
	}

	async *streamRequest(options: RequestOptions): AsyncGenerator<string> {
		const response = await this.sendRequest({
			...options,
			headers: {
				...options.headers,
				Accept: "text/event-stream"
			}
		});

		if (response.body == null) return;

		for (const reader = response.body.getReader(); ; ) {
			const { value, done } = await reader.read();

			if (done) break;

			const text = new TextDecoder().decode(value);

			const regex = /(?<kind>data|error): (?<content>[\s\S]*?)(?=\n\n(data|error|$))/g;

			for (const match of text.matchAll(regex)) {
				const { kind, content } = match.groups as { kind: "data" | "error"; content: string };

				if (kind === "error") {
					const parsedError = JSON.parse(content);
					throw APIError.fromObject(parsedError) ?? APIError.fromStatusCode(response.status, content);
				} else {
					yield content;
				}
			}
		}
	}

	async sendRequest(options: RequestOptions): Promise<Response> {
		const headers: HeadersInit = {
			"Content-Type": "application/json",
			"X-Session-Id": SESSION_ID,
			"X-Socket-Id": roomSocket.id,
			"X-App-Version": APP_BUILD_DATE.getTime().toString(),
			"X-App-Context": (await takeFirst(appStore.model$.appContext)) ?? "web",
			"X-Workspace": takeId(workspaceStore.currentWorkspace) ?? "",
			"X-Room": takeId(roomStore.currentRoom) ?? "",
			"Accept-Language": languageStore.currentLanguage,
			...options.headers
		};

		for (const [key, value] of Object.entries(headers)) {
			if (!value) {
				delete headers[key];
			}
		}

		const response = await fetch(`${API_BASE_URL}${options.path}`, {
			method: options.method,
			headers,
			credentials: "include",
			body:
				options.body === undefined
					? undefined
					: typeof options.body === "string"
						? options.body
						: JSON.stringify(options.body),
			...(options.abortSignal != null ? { signal: options.abortSignal } : {})
		});

		if (response.status === 401 && !options.skipLogoutOnUnauthorized) {
			await this.logout();
			throw new APIError("unauthorized", "You are not authorized to perform this action");
		}

		if (!response.ok) {
			const text = await response.text();

			let apiError: APIError | undefined = undefined;

			try {
				const json = JSON.parse(text);
				const gqlError = json?.errors?.[0];

				apiError = APIError.fromObject(json) ?? APIError.fromObject(gqlError.extensions);
			} catch {
				// Ignore
			}

			apiError = apiError ?? APIError.fromStatusCode(response.status, text);

			//console.error(apiError);
			caughtError(apiError);

			throw apiError ?? APIError.fromStatusCode(response.status, text);
		}

		return response;
	}

	protected async sendGQLRequest<T = any>(
		query: string,
		variables: Record<string, unknown>,
		options?: RequestOptionsBase
	): Promise<T | null> {
		try {
			const queryName = query.match(/(query|mutation) (?<queryName>\w+)/)?.groups?.queryName;

			const response = await this.sendRequest({
				...options,
				path: `/graphql${queryName != null ? `/${queryName}` : ""}`,
				method: "POST",
				body: JSON.stringify({
					query,
					variables
				})
			});

			// Grab the json
			let json: { data: any; errors?: { message: string; extensions?: { exception?: any } }[] } | null = null;
			try {
				json = await response.json();
			} catch (err) {
				// Couldnt grab json
			}

			if (response.status !== 200 || json == null || (json.errors?.length || 0) > 0) {
				const exception = json?.errors?.[0]?.extensions as Record<string, string>;

				const apiError =
					APIError.fromObject(exception) ??
					APIError.fromStatusCode(response.status, (json?.errors || []).map((e) => e.message).join(", "));

				if ("stack" in exception) {
					console.error(exception.stack);
				}

				//console.error(apiError);
				caughtError(apiError);

				if (apiError.kind === "unauthorized" && !options?.skipLogoutOnUnauthorized) {
					await this.logout();
				}

				// noinspection ExceptionCaughtLocallyJS
				throw apiError;
			} else {
				return json.data;
			}
		} catch (error) {
			// If the error code is 20 it means we cancelled the request with an abort controller.
			// This doesnt count as an error,
			if (error.code === 20) {
				throw new Error(`The request with abort id "${options?.abortSignal || "N/A"}" was cancelled by the user.`);
			}

			// Replace the stack with the frontend stack
			if (error instanceof APIError) {
				error.stack = `${error.stack != null ? `${error.stack}\n` : ""}${new Error().stack}`;
			}

			throw error;
		}
	}
}
