import { COLOR_MODE_COLORS, ColorMode } from "shared";
import { DEFAULT_COLOR_MODE, STORAGE_KEYS } from "../config";
import { getItem, setItem } from "./local-storage";

/**
 * Gets the color mode colors
 * @param mode
 */
export function getColorModeColors(mode: ColorMode) {
	return COLOR_MODE_COLORS[mode];
}

/**
 * Sets the color mode in local storage
 * @param mode
 */
export function saveColorMode(mode: ColorMode) {
	setItem(STORAGE_KEYS.colorMode, mode);
}

/**
 * Gets the color mode from local storage
 */
export function getColorModeFromStorage(): ColorMode {
	const mode = getItem<ColorMode>(STORAGE_KEYS.colorMode);

	// Validate the color mode
	if (mode == null || getColorModeColors(mode) == null) {
		return DEFAULT_COLOR_MODE;
	}

	return mode;
}

export function isColorModeDark(mode: ColorMode | undefined) {
	return mode != null && mode !== "light";
}
