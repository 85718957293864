const crypto = globalThis.crypto ?? (globalThis as unknown as { msCrypto: Crypto }).msCrypto;
const subtle = crypto.subtle ?? (crypto as unknown as { webkitSubtle: SubtleCrypto }).webkitSubtle;

export async function sha256(buf: string | ArrayBuffer): Promise<string> {
	if (typeof buf === "string") buf = strToBuf(buf);

	// Browsers throw if they lack support for an algorithm.
	// Promise will be rejected on non-secure origins. (http://goo.gl/lq4gCo)
	const hash = await subtle.digest({ name: "sha-256" }, buf);
	return hex(new Uint8Array(hash));
}

function strToBuf(str: string): ArrayBuffer {
	const len = str.length;
	const buf = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		buf[i] = str.charCodeAt(i);
	}
	return buf;
}

function hex(buf: Uint8Array): string {
	const len = buf.length;
	const chars = [];
	for (let i = 0; i < len; i++) {
		const byte = buf[i];
		chars.push((byte >>> 4).toString(16));
		chars.push((byte & 0x0f).toString(16));
	}
	return chars.join("");
}

export function uuidv4(seed?: number) {
	if (seed != null) {
		return (
			"0".repeat(8) +
			"-" +
			"0".repeat(4) +
			"-" +
			"0".repeat(4) +
			"-" +
			"0".repeat(4) +
			"-" +
			seed.toString(16).padStart(12, "0")
		);
	}

	if (crypto.randomUUID != null) {
		return crypto.randomUUID();
	}

	// @ts-ignore
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
}

export function makeFriendlyId(len: number) {
	let id = "";

	while (id.length < len) {
		id += Math.random().toString(36).slice(2);
	}

	return id.slice(0, len);
}

/**
 * Hashes a string using the DJB2 algorithm.
 * @param input - The string to hash.
 * @returns The hash of the string.
 */
export function djb2Hash(input: string): string {
	let hash = 5381;
	for (let i = 0; i < input.length; i++) {
		hash = (hash * 33) ^ input.charCodeAt(i);
	}
	return String(hash >>> 0); // Ensure the hash is a positive integer.
}
