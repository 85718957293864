export function loadScript(src: string) {
	if (document.querySelector(`script[src="${src}"]`)) return Promise.resolve();

	return new Promise<void>((resolve, reject) => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = src;
		script.onload = () => resolve();
		script.onerror = reject;
		document.body.appendChild(script);
	});
}
