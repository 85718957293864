import { Id, Model, Ref } from "./model";
import { User, UserBase, UserDAO } from "./user";
import { Room, RoomBase, RoomDAO } from "./room";
import { Workspace, WorkspaceBase, WorkspaceDAO } from "./workspace";

export enum RoomAccessRoleKind {
	NO_ACCESS = 0,
	PARTICIPANT = 1000,
	FACILITATOR = 2000
}

export const DEFAULT_ROOM_ACCESS_ROLE: RoomAccessRoleKind = RoomAccessRoleKind.PARTICIPANT;

export const ROOM_ACCESS_ROLES = [RoomAccessRoleKind.PARTICIPANT, RoomAccessRoleKind.FACILITATOR] as const;

export type RoomAccessKind = "user" | "workspace";

export interface RoomAccessBase extends Model {
	__entity?: "RoomAccess";

	role: RoomAccessRoleKind;
	room: Ref<RoomBase>;

	kind: RoomAccessKind;
	user?: Ref<UserBase>;
	workspace?: Ref<WorkspaceBase>;
}

export interface RoomAccess extends RoomAccessBase {
	room: Ref<Room>;

	user?: Ref<User>;
	workspace?: Ref<Workspace>;
}

export interface RoomAccessDAO extends RoomAccessBase {
	room: Id<RoomDAO>;

	user?: Id<UserDAO>;
	workspace?: Id<WorkspaceDAO>;
}
