export function setItem<T>(key: string, value: T, parser: (v: T) => string = JSON.stringify) {
	try {
		localStorage.setItem(key, parser(value));
	} catch (err) {
		console.error(err);
	}
}

export function getItem<T>(key: string, parser: (v: string) => T = JSON.parse): T | undefined {
	try {
		const data = localStorage.getItem(key);
		if (data != null) {
			return parser(data) as T;
		}

		return undefined;
	} catch (err) {
		console.error(err);
		return undefined;
	}
}

export function removeItem(key: string) {
	localStorage.removeItem(key);
}
