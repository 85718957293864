import { ActionSubject, runAction } from "../../state/action";
import { Idea, Position, Ref, Room, takeId } from "shared";
import { MindmapEngineControllerAction } from "mindmap";
import { MindmapEngineMessageInRequestAvailablePositionOptions } from "mindmap/src/engine/message/mindmap-engine-message-in";

export const mindmapAction = {
	executeAction: new ActionSubject<MindmapEngineControllerAction, Ref<Room>>(),
	requestGetAvailablePosition: new ActionSubject<
		{ options?: MindmapEngineMessageInRequestAvailablePositionOptions; resolve: (pos: Position) => void },
		Ref<Room>
	>()
};

export const mindmapSelectIdeas = (
	room: Ref<Room>,
	ideas: Ref<Idea>[],
	{
		fitViewPort
	}: {
		fitViewPort?: boolean;
	}
) => executeMindmapAction(room, { kind: "selectNodes", nodeIds: ideas.map((idea) => takeId(idea)), fitViewPort });

export const executeMindmapAction = (room: Ref<Room>, action: MindmapEngineControllerAction) =>
	runAction(mindmapAction.executeAction, () => action, room);

export const getAvailablePositionInMindmap = (
	room: Ref<Room>,
	options?: MindmapEngineMessageInRequestAvailablePositionOptions
) => {
	return new Promise<Position>((resolve) => {
		// Timeout after 500ms
		let timeout: ReturnType<typeof setTimeout> | null = setTimeout(() => {
			timeout = null;
			resolve({ x: 0, y: 0 });
		}, 500);

		runAction(
			mindmapAction.requestGetAvailablePosition,
			() => ({
				options,
				resolve: (position) => {
					if (timeout == null) return;

					clearTimeout(timeout);
					resolve(position);
				}
			}),
			room
		);
	});
};
