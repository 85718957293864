import { Workbox } from "workbox-window";

/**
 * Asks the user if he/she wants to update.
 */
/*export async function promptForUpdate(): Promise<boolean> {
	return new Promise(async (res) => {
		await showToast("A new update is available. Do you want to update now?", {
			duration: 10 * 1000,
			icon: ICON_DOWNLOAD,
			buttons: [
				{
					text: "Later",
					onClick: () => res(false)
				},
				{
					text: "Update now",
					important: true,
					onClick: () => res(true)
				}
			]
		});

		res(false);
	});
}*/

/**
 * Uses the workbox window library to load a service worker.
 * @param path
 */
export async function registerServiceWorkerWithWorkbox(path: string) {
	if ("serviceWorker" in navigator) {
		const wb = new Workbox(path);

		// Add an event listener to detect when the registered
		// service worker has installed but is waiting to activate.
		wb.addEventListener("waiting", (e) => {
			wb.messageSkipWaiting();
		});

		wb.register();
	}
}
