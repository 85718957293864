import { filter, Observable, ReplaySubject, Subject, Subscription, switchMap } from "rxjs";
import { Action } from "./action";

export type UnpackObservables<T> = {
	[K in keyof T]: T[K] extends ReplaySubject<infer U> ? U : T[K] extends Observable<infer U> ? U | undefined : unknown;
};

export type StoreModel<TStore extends Store> = UnpackObservables<TStore["model$"]>;

export abstract class Store {
	abstract model$: any;
	protected subscriptions?(): Subscription[];

	protected _subscriptions: Subscription[] = [];
	protected subCount = 0;

	addSubscription() {
		if (this.subCount === 0) {
			this._subscriptions = this.subscriptions?.() || [];
		}

		this.subCount++;

		// console.log(`${this.constructor.name}: add: ${this.subCount}`);
	}

	removeSubscription() {
		this.subCount--;

		// console.log(`${this.constructor.name}: remove: ${this.subCount}`);

		if (this.subCount === 0) {
			this._subscriptions.forEach((sub) => sub.unsubscribe());
		}
	}
}

export abstract class GlobalStore extends Store {
	constructor() {
		super();

		// (async () => this.addSubscription())();
		setTimeout(() => this.addSubscription(), 1);
	}
}

export function filterMetaValue<TValue, TMeta>(obs: Observable<Action<TValue, TMeta>>, meta?: Observable<TMeta>) {
	if (!meta) return obs;
	return meta.pipe(switchMap((metaValue) => obs.pipe(filter((action) => action.meta === metaValue))));
}

export function subSuccessActionValue<TValue, TMeta>(
	action: Subject<Action<TValue, TMeta>>,
	cb: (value: TValue, meta: TMeta) => void,
	meta?: Observable<any>
) {
	return filterMetaValue(action, meta).subscribe((action) => {
		if (action.kind === "success") cb(action.value, action.meta);
	});
}

export function subscribeSuccessActionValueDirectly<TValue, TMeta>(
	action: Observable<Action<TValue, TMeta>>,
	sub: Subject<TValue>,
	meta?: Observable<TMeta>
) {
	return filterMetaValue(action, meta).subscribe((action) => {
		if (action.kind === "success") {
			sub.next(action.value);
		}
	});
}

export function subscribeSuccessActionDirectly<TValue, TMeta>(
	action: Subject<Action<TValue, TMeta>>,
	sub: Subject<Action<TValue, TMeta>>,
	meta?: Observable<any>
) {
	return filterMetaValue(action, meta).subscribe((action) => {
		sub.next(action);
	});
}
