import { css, html, LitElement, svg } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import { property } from "lit/decorators/property.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import { Icon } from "../../icons";
import { when } from "lit/directives/when.js";

@customElement("bs-icon")
export class BsIcon extends LitElement {
	@property({ type: Object }) icon!: Icon;
	@property({ type: Boolean, reflect: true }) fill = false;
	@property({ type: Boolean, reflect: true }) button = false;
	@property({ type: Boolean, reflect: true }) disabled = false;
	@property({ type: Boolean, reflect: true }) active = false;

	static get styles() {
		return [
			sharedStyles,
			css`
				:host {
					position: relative;
					user-select: none;
					-webkit-user-select: none;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					padding: var(--icon-padding, 0);
					flex-shrink: 0;
					color: var(--icon-color, currentColor);
				}

				#svg {
					width: var(--icon-size, 22px);
					aspect-ratio: 1 / 1;
				}

				:host(:not([disabled])[button]) {
					cursor: pointer;
				}

				:host([button]) #svg {
					transition: transform 120ms ease-out, opacity 120ms ease-out;
					opacity: var(--icon-button-opacity, 0.5);
				}

				:host([disabled]) {
					--icon-button-opacity: 0.2;
					pointer-events: none;
				}

				#touch-area {
					display: none;
					position: absolute;
					inset: calc(-1 * var(--space-xs));
				}

				:host([button]) #touch-area {
					display: block;
				}

				@media (hover: hover) {
					:host(:not([disabled])[button]:hover) #svg,
					:host(:not([disabled])[button][active]) #svg {
						opacity: var(--icon-button-opacity, 1);
					}

					:host(:not([disabled])[button]:hover) #svg {
						transform: var(--icon-button-hover-transform, scale(1.1));
					}
				}

				:host(:not([disabled])[button]:active) #svg {
					transform: var(--icon-button-hover-transform, scale(0.95));
				}

				:host([size="tiny"]) #svg {
					width: var(--icon-size, 12px);
				}

				:host([size="small"]) #svg {
					width: var(--icon-size, 18px);
				}

				:host([size="large"]) #svg {
					width: var(--icon-size, 36px);
				}
			`
		];
	}

	render() {
		return svg`
		${when(this.button, () => html`<div id="touch-area"></div>`)}
		<svg id="svg" width="100%" height="100%" viewBox="${this.icon.viewBox}">
			${this.fill && this.icon.svgFilled != null ? unsafeSVG(this.icon.svgFilled) : unsafeSVG(this.icon.svg)}
		</svg>
	`;
	}
}
