import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import "./icon";
import "./form-item";
import { property } from "lit/decorators/property.js";
import { promisifyAnimation, wait } from "../util/util";
import { repeat } from "lit/directives/repeat.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { Icon } from "../../icons";
import { when } from "lit/directives/when.js";

const TOAST_CONTAINER_ID = "toast-container";

export interface ToastButton {
	text: string;
	important?: boolean;
	onClick?: () => void;
}

/**
 * Returns the toast container, making sure only one is in the DOM at a time.
 */
export function getToastContainer(): HTMLElement {
	let $container = document.getElementById(TOAST_CONTAINER_ID);
	if ($container == null) {
		$container = document.createElement("div");
		$container.id = TOAST_CONTAINER_ID;
		Object.assign($container.style, {
			position: "fixed",
			left: "0",
			bottom: "0",
			zIndex: "1234567899",
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "flex-start",
			flexDirection: "column"
		});

		document.documentElement.appendChild($container);
	}

	return $container;
}

export function countToastsWithId(id: string) {
	return document.querySelectorAll(`#${id}`).length;
}

export function hideToastWithId(id: string) {
	const $existingToast = document.querySelector(`#${id}`) as BsToast;
	if ($existingToast != null) {
		$existingToast.close?.(true);
		$existingToast.remove();
	}
}

export async function showToast(
	message: string,
	{
		duration = 3000,
		id,
		buttons,
		icon,
		label
	}: { duration?: number; id?: string; buttons?: ToastButton[]; icon?: Icon; label?: string } = {}
) {
	return new Promise(async (res) => {
		const $container = getToastContainer();

		// Remove existing toast with the ID
		if (id != null) {
			hideToastWithId(id);
		}

		const $toast = new BsToast();
		$toast.id = id || "";
		$toast.message = message;
		$toast.buttons = buttons || [];
		$toast.icon = icon;
		$toast.label = label;
		const animation = $toast.animate(
			{
				opacity: [0, 1],
				transform: ["translateY(100%)", "translateY(0)"]
			},
			{ duration: 130, easing: "ease-out" }
		);
		$container.appendChild($toast);
		const close = async (cancelAnimation?: boolean) => {
			if (cancelAnimation) {
				animation.cancel();
			} else {
				animation.reverse();
			}

			try {
				await promisifyAnimation(animation);
			} catch (err) {
				// Animation was cancelled
			}
			$toast.remove();
			res(undefined);
		};

		$toast.close = close;

		if (duration != null && duration != Infinity) {
			await wait(duration);
			await close();
		}
	});
}

@customElement("bs-toast")
export class BsToast extends LitElement {
	@property({ type: String }) message: string | undefined;
	@property({ type: String }) label: string | undefined;
	@property({ type: Object }) close: ((cancelAnimation?: boolean) => Promise<void>) | undefined;
	@property({ type: Array }) buttons: ToastButton[] = [];
	@property({ type: Object }) icon: Icon | undefined;

	static get styles() {
		return [
			sharedStyles,
			css`
				:host {
					display: inline-flex;
					margin: 0 var(--space-m) var(--space-m) var(--space-m);
					background: var(--dark);
					color: var(--light);
					border-radius: var(--border-radius-m);
					padding: var(--space-s) var(--space-m);
					animation: float 6s ease-in-out infinite;
					transform: translateY(100%);
					align-items: center;
					gap: var(--space-m);
					flex-wrap: wrap;
				}

				#message {
					display: flex;
					gap: var(--space-s);
					align-items: center;
				}

				#buttons {
					display: flex;
					gap: var(--space-s);
					flex-shrink: 0;
					flex-grow: 1;
					justify-content: flex-end;
				}

				@keyframes float {
					0% {
						box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.6);
						transform: translatey(0px);
					}
					50% {
						box-shadow: 0 15px 10px 0px rgba(0, 0, 0, 0.2);
						transform: translatey(-10px);
					}
					100% {
						box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.6);
						transform: translatey(0px);
					}
				}
			`
		];
	}

	render() {
		const { message, buttons, icon, label } = this;
		return html`
			<div id="message" @click="${() => this.close?.()}">
				${icon != null ? html` <bs-icon size="small" .icon="${icon}"></bs-icon> ` : ""}
				<slot>
					<div>
						${when(message, () => html`<bs-text>${message}</bs-text>`)}
						${when(label, () => html`<bs-text size="small" label>${label}</bs-text>`)}
					</div>
				</slot>
			</div>
			${buttons.length > 0
				? html`
						<div id="buttons">
							${repeat(
								buttons,
								({ text, onClick, important }) => html`
									<bs-form-item
										?invert="${ifDefined(important)}"
										@click="${() => {
											this.close?.();
											onClick?.();
										}}"
									>
										<button slot="input">${text}</button>
									</bs-form-item>
								`
							)}
						</div>
				  `
				: undefined}
		`;
	}
}
