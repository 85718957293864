import { APIError, joinSentences } from "shared";
import { TrackerPageViewData, TrackerService } from "./tracker-service";

const MAX_LATEST_EVENTS_TRACKED = 30;

export class DebugService implements TrackerService {
	protected latestTrackedEvents: string[] = [];

	/**
	 * Creates debug information for an error.
	 * @param error
	 */
	createDebugInformation(error?: APIError | Error | null): string {
		return joinSentences([
			error != null && "kind" in error ? error.kind : undefined,
			error?.stack,
			error?.message,
			"\n\n",
			this.latestTrackedEvents.map((v, i) => `${i + 1}. ${v}`).join("\n")
		]);
	}

	/**
	 * Tracks an event.
	 * @param name
	 * @param data
	 */
	trackEvent(name: string, { data }: { data?: Object }) {
		// Add event to latest events tracked
		this.latestTrackedEvents.unshift(`${name ?? "event"}${data != null ? ` - "${JSON.stringify(data)}"` : ""}`);
		if (this.latestTrackedEvents.length > MAX_LATEST_EVENTS_TRACKED) {
			this.latestTrackedEvents.pop();
		}
	}

	trackPageView({ data }: { data?: TrackerPageViewData }) {
		this.trackEvent("route.changed", { data: { pathname: data?.url || location.pathname } });
	}
}

export const debugService = new DebugService();
