import {
	DEFAULT_FONTS,
	DEFAULT_MINDMAP_LAYOUT,
	MINDMAP_SPACING_LARGE,
	MINDMAP_SPACING_TINY,
	MindmapLayout
} from "mindmap";
import { MindmapLayoutKind } from "shared/src/types/mindmap-layout-kind";

export const PREMIUM_MINDMAP_LAYOUT_KINDS: MindmapLayoutKind[] = ["draw"];

export const MINDMAP_LAYOUTS: Record<MindmapLayoutKind, MindmapLayout> = {
	default: DEFAULT_MINDMAP_LAYOUT,
	invert: {
		...DEFAULT_MINDMAP_LAYOUT,
		previewStyle: "invert",
		nodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeShape,
			fillStyle: "solid"
		},
		mainNodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.mainNodeShape,
			fillStyle: "none",
			border: {
				...DEFAULT_MINDMAP_LAYOUT.mainNodeShape.border,
				style: "line"
			}
		}
	},
	solid: {
		...DEFAULT_MINDMAP_LAYOUT,
		previewStyle: "solid",
		nodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeShape,
			fillStyle: "solid"
		},
		mainNodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.mainNodeShape,
			fillStyle: "solid"
		}
	},
	compact: {
		...DEFAULT_MINDMAP_LAYOUT,
		previewStyle: "compact",
		mainNodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.mainNodeShape,
			kind: "slightlyRounded",
			fillStyle: "solid",
			isAuthorVisible: false
		},
		nodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeShape,
			kind: "line",
			fillStyle: "none",
			isAuthorVisible: false,
			minWidth: 50
		},
		nodeConnectionShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeConnectionShape,
			kind: "sharp"
		},
		media: {
			...DEFAULT_MINDMAP_LAYOUT.media,
			heroImageHeight: 130,
			heroImageFit: "contain"
		},
		board: {
			...DEFAULT_MINDMAP_LAYOUT.board,
			marginHorizontal: 20,
			marginVertical: 5
		}
	},
	flat: {
		...DEFAULT_MINDMAP_LAYOUT,
		previewStyle: "flat",
		mainNodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.mainNodeShape,
			kind: "rounded",
			fillStyle: "default",
			border: {
				...DEFAULT_MINDMAP_LAYOUT.mainNodeShape.border,
				style: "line"
			},
			isAuthorVisible: false,
			padding: {
				top: MINDMAP_SPACING_LARGE,
				right: MINDMAP_SPACING_LARGE,
				bottom: MINDMAP_SPACING_LARGE,
				left: MINDMAP_SPACING_LARGE
			}
		},
		nodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeShape,
			kind: "none",
			fillStyle: "none",
			isAuthorVisible: false,
			padding: {
				top: MINDMAP_SPACING_TINY,
				right: MINDMAP_SPACING_TINY,
				bottom: MINDMAP_SPACING_TINY,
				left: MINDMAP_SPACING_TINY
			},
			minHeight: 30,
			minWidth: 50
		},
		nodeConnectionShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeConnectionShape,
			kind: "sharp"
		},
		board: {
			...DEFAULT_MINDMAP_LAYOUT.board,
			marginVertical: 5
		}
	},
	dash: {
		...DEFAULT_MINDMAP_LAYOUT,
		previewStyle: "dash",
		nodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeShape,
			border: {
				...DEFAULT_MINDMAP_LAYOUT.nodeShape.border,
				style: "dash"
			}
		},
		nodeConnectionShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeConnectionShape,
			lineStyle: "dash"
		}
	},
	draw: {
		...DEFAULT_MINDMAP_LAYOUT,
		font: {
			fontSize: 18,
			fontFamily: DEFAULT_FONTS["shantell-sans"].family,
			textAlign: "left",
			textBaseline: "alphabetic",
			textLineGap: 4
		},
		previewStyle: "hand-drawn",
		nodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.nodeShape,
			fillStyle: "hand-drawn",
			border: {
				...DEFAULT_MINDMAP_LAYOUT.nodeShape.border,
				style: "none"
			}
		},
		mainNodeShape: {
			...DEFAULT_MINDMAP_LAYOUT.mainNodeShape,
			fillStyle: "hand-drawn",
			border: {
				...DEFAULT_MINDMAP_LAYOUT.mainNodeShape.border,
				style: "none"
			}
		}
	}
};
