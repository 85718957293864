import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import { WebDialog } from "web-dialog";
import "./dialog-close-button";

export function prepareDialogForWrapper($d: WebDialog) {
	$d.style.setProperty("--dialog-padding", "0");
	$d.style.setProperty("--dialog-overflow-x", "hidden");
	$d.style.setProperty("--dialog-overflow-y", "hidden");
	$d.style.setProperty("--dialog-max-height", "auto");
}

@customElement("bs-dialog-wrapper")
export class BsDialogWrapper<T = any> extends LitElement {
	static get styles() {
		return [
			sharedStyles,
			css`
				:host {
					display: flex;
					flex-direction: column;
					height: 100%;
					max-height: var(--dialog-wrapper-max-height, 80vh);
				}

				header,
				footer,
				form,
				article,
				::slotted(header),
				::slotted(footer),
				::slotted(form),
				::slotted(article) {
					position: relative;
				}

				header,
				footer,
				::slotted(header),
				::slotted(footer) {
					flex-shrink: 0;
				}

				header,
				::slotted(header) {
					padding: var(--dialog-wrapper-header-padding, var(--space-l) var(--space-l) var(--space-s));
				}

				footer::after,
				header::after {
					display: block;
					content: "";
					position: absolute;
					background: var(
						--dialog-wrapper-scroll-gradient,
						linear-gradient(180deg, rgba(var(--rgb), 1) 0%, rgba(var(--rgb), 0) 100%)
					);
					left: 0;
					width: 100%;
					height: var(--space-m);
					z-index: 1;
				}

				footer::after {
					transform: rotate(180deg);
					bottom: 100%;
				}

				header::after {
					top: 100%;
				}

				footer,
				::slotted(footer) {
					padding: var(--dialog-wrapper-header-padding, var(--space-s) var(--space-l) var(--space-l));
					display: flex;
					justify-content: flex-end;
					align-items: center;
					position: relative;
				}

				form,
				article,
				::slotted(form),
				::slotted(article) {
					padding: var(--dialog-wrapper-content-padding, var(--space-l));
					flex-grow: 1;
					overflow-y: var(--dialog-wrapper-content-overflow-y, auto);
					display: flex;
					flex-direction: column;
					gap: var(--space-l);
				}
			`
		];
	}

	cancel() {
		this.dispatchEvent(new CustomEvent("cancel"));
	}

	done(data?: T) {
		this.dispatchEvent(new CustomEvent("done", { bubbles: true, composed: true, detail: data }));
	}

	render() {
		return html` <slot></slot> `;
	}
}
