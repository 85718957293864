import {
	AIActionAttributeKind,
	AIActionContentLengthKind,
	AIActionFormItemKind,
	AIModelKind,
	AISensitivityKind,
	APIError,
	AuthProviderKind,
	ColorMode,
	CopilotStateKind,
	DEFAULT_LANGUAGE_CODE,
	EntityKind,
	findBestLanguageCode,
	IdeaKind,
	LanguageCode,
	MindmapLayoutKind,
	PlanIntervalKind,
	PollData,
	PollKind,
	RobotGroupKind,
	RoomAccessRoleKind,
	StabilityAiStylePreset,
	WorkspaceAccessRankKind,
	WorkspaceKind
} from "shared";
import { ExportKind } from "./export/export-util";
import { get } from "lit-translate";

export function entityToNameWithCount(kind: EntityKind, count: number) {
	const name = entityToName(kind, count === 1);
	return `${count} ${name.toLowerCase()}`;
}

export function entityToName(kind: EntityKind, singular?: boolean) {
	switch (kind) {
		case "mindmap":
			return singular ? get("Mindmap") : get("Mindmaps");
		case "idea":
			return singular ? get("Idea") : get("Ideas");
		case "question":
			return singular ? get("Question") : get("Questions");
		case "room":
			return singular ? get("Room") : get("Rooms");
		case "user":
			return singular ? get("User") : get("Users");
		case "challenge":
			return singular ? get("Challenge") : get("Challenges");
		case "group":
			return singular ? get("Group") : get("Groups");
		case "item":
			return singular ? get("Item") : get("Items");
		case "association":
			return singular ? get("Association") : get("Associations");
		case "topic":
			return singular ? get("Topic") : get("Topics");
		case "member":
			return singular ? get("Member") : get("Members");
		case "people":
			return singular ? get("Person") : get("People");
		case "match":
			return singular ? get("Match") : get("Matches");
		case "inspiration":
			return singular ? get("Inspiration") : get("Inspirations");
		case "vote":
			return singular ? get("Vote") : get("Votes");
		case "photo":
			return singular ? get("Photo") : get("Photos");
		case "participant":
			return singular ? get("Participant") : get("Participants");
		case "text":
			return singular ? get("Text") : get("Texts");
		case "image":
			return singular ? get("Image") : get("Images");
		case "file":
			return singular ? get("File") : get("Files");
	}
}

export function robotGroupKindToName(kind: RobotGroupKind) {
	switch (kind) {
		case "hats":
			return get("Six Thinking Hats");
		case "ideanote":
			return get("Ideanote");
		case "famous":
			return get("Famous");
		case "general":
			return get("General");
		case "seasonal":
			return get("Seasonal");
		case "animals":
			return get("Animals");
		case "custom":
			return get("Custom");
	}
}

export function colorModeToName(mode: ColorMode) {
	switch (mode) {
		case "light":
			return get("Light");
		case "dark":
			return get("Dark");
		case "contrast":
			return get("High Contrast");
	}
}

export function authKindToName(kind: AuthProviderKind) {
	switch (kind) {
		case "apple":
			return get("Apple");
		case "google":
			return get("Google");
		case "microsoft":
			return get("Microsoft");
		case "guest":
			return get("Guest");
	}
}

export function continueWithAuthKindText(kind: AuthProviderKind) {
	const authName = authKindToName(kind) ?? "";

	switch (kind) {
		case "apple":
		case "google":
		case "microsoft":
			return get(`Continue with {{authMethod}}`, { authMethod: authName });
		case "guest":
			return get(`Continue anonymously`);
	}
}

export function roomAccessRoleToText(kind: RoomAccessRoleKind): string {
	switch (kind) {
		case RoomAccessRoleKind.NO_ACCESS:
			return get("No access");
		case RoomAccessRoleKind.PARTICIPANT:
			return get("Participant");
		case RoomAccessRoleKind.FACILITATOR:
			return get("Facilitator");
	}
}

export function copilotStateKindToText(kind: CopilotStateKind) {
	switch (kind) {
		case "initiating":
			return get("Initiating");
		case "typing":
			return get("Typing");
		case "thinking":
			return get("Thinking");
		case "idle":
			return get("Idle");
	}
}

export function workspaceRankToName(rank: WorkspaceAccessRankKind) {
	switch (rank) {
		case WorkspaceAccessRankKind.OWNER:
			return get("Owner");
		case WorkspaceAccessRankKind.MEMBER:
			return get("Member");
		case WorkspaceAccessRankKind.GUEST:
			return get("Guest");
	}
}

export function votingTypeToName(type: PollKind) {
	switch (type) {
		case "comparison":
			return get("Comparison voting");
		case "dot":
			return get("Dot voting");
		case "criteria":
			return get("Evaluation voting");
	}
}

export function getDefaultVotingText(data: Partial<PollData>) {
	switch (data.kind) {
		case "dot":
			return data.votesPerPerson === 1 || data.votesPerPerson == null
				? get("Pick your favorite")
				: get(`Pick your {{count}} favorites`, { count: data.votesPerPerson.toString() });

		case "comparison":
			return get("Pick the one you like the most");
		case "criteria":
			return get("Evaluate based on the criteria");
		default:
			return get("Vote");
	}
}

export function ideaKindToText(kind?: IdeaKind) {
	switch (kind) {
		case "question":
			return get("Question");
		case "comment":
			return get("Comment");
		case "media":
			return get("Media");
		case "solution":
		default:
			return get("Solution");
	}
}

export function errorToText(error?: Error) {
	if (error != null && error instanceof APIError) {
		switch (error.kind) {
			case "contentFilterTriggered":
				return get(
					`Our AI cannot come up with results because it deems the content inappropriate{{reason}}. If you believe this is a mistake, please contact our support team.`,
					{
						reason:
							error.message != null
								? get(` - it was flagged with "{{reason}}"`, { reason: error.message.toLowerCase() })
								: ""
					}
				);
			//return "The content filter was triggered";
			case "invitationConsumed":
				return get("The invitation has already been used");
			case "notFound":
				return get("Resource not found");
			case "noAccountFound":
				return get("No account could be found with this email");
			case "timeout":
				return get("The connection timed out. Please try again.");
			case "rateLimitExceeded":
				return get("Rate limit exceeded");
			case "internalServerError":
				return get("Internal server error");
			case "notEnoughContent":
				return get("Too few ungrouped ideas: could not find topics");
		}
	}

	return error?.message ?? get("Something went wrong");
}

export function booleanToText(v?: boolean) {
	return v ? get("Yes") : get("No");
}

export function sentimentToText(sentiment: number) {
	const bucket = (index: number) => (index * 2) / 5 - 1;
	if (sentiment < bucket(1)) return get("Very negative");
	else if (sentiment < bucket(2)) return get("Negative");
	else if (sentiment < bucket(3)) return get("Neutral");
	else if (sentiment < bucket(4)) return get("Positive");
	else return get("Very positive");
}

export function exportKindToText(kind: ExportKind) {
	switch (kind) {
		case "txt":
			return get("Text");
		case "pdf":
			return get("PDF");
		case "powerpoint":
			return get("Powerpoint");
		case "csv":
			return get("CSV");
		case "docx":
			return get("Word");
		case "json":
			return get("JSON");
		case "image":
			return get("Image");
		case "freemind":
			return get("Freemind");
	}
}

export function aiContentLengthToText(length: AIActionContentLengthKind) {
	switch (length) {
		case "short":
			return get("Short");
		case "medium":
			return get("Medium");
		case "long":
			return get("Long");
	}
}

export function getPlanIntervalText(interval: PlanIntervalKind) {
	switch (interval) {
		case "year":
			return get("Annual");
		default:
			return get("Monthly");
	}
}

export function getAIModelKindText(kind: AIModelKind) {
	switch (kind) {
		case "turbo":
			return get("Basic");
		case "premium":
			return get("Premium");
	}
}

export function sensitivityKindToText(kind: AISensitivityKind) {
	switch (kind) {
		case "low":
			return get("Allow some sensitive topics");
		case "high":
			return get("Allow very few sensitive topics");
		case "snowflake":
			return get("Allow no sensitive topics");
	}
}

export function getAIActionAttributeText(kind: AIActionAttributeKind) {
	switch (kind) {
		case "original":
			return get("Original");
		case "practical":
			return get("Practical");
		case "creative":
			return get("Creative");
		case "wild":
			return get("Wild");
		case "weird":
			return get("Weird");
		case "funny":
			return get("Funny");
	}
}

export function getAIActionContentLengthText(kind: AIActionContentLengthKind) {
	switch (kind) {
		case "word":
			return get("One word");
		case "very-short":
			return get("Very short");
		case "short":
			return get("Short");
		case "medium":
			return get("Medium");
		case "long":
			return get("Long");
	}
}

export function getAIActionFormItemKindText(kind: AIActionFormItemKind) {
	switch (kind) {
		case "constant":
			return get("Constant");
		case "text":
			return get("Text");
		case "select":
			return get("Select");
	}
}

/**
 * Intl doesnt have a way of getting the name of a time unit.
 * @param unit
 */
export function getTimeFormatText(unit: Intl.RelativeTimeFormatUnit): string {
	switch (unit) {
		case "year":
			return get("year");
		case "years":
			return get("years");
		case "quarter":
			return get("quarter");
		case "quarters":
			return get("quarters");
		case "month":
			return get("month");
		case "months":
			return get("months");
		case "week":
			return get("week");
		case "weeks":
			return get("weeks");
		case "day":
			return get("day");
		case "days":
			return get("days");
		case "hour":
			return get("hour");
		case "hours":
			return get("hours");
		case "minute":
			return get("minute");
		case "minutes":
			return get("minutes");
		case "second":
			return get("second");
		case "seconds":
			return get("seconds");
	}
}

export function getMindmapLayoutName(kind: MindmapLayoutKind) {
	switch (kind) {
		case "default":
			return get("Default");
		case "flat":
			return get("Flattened");
		case "compact":
			return get("Compact");
		case "dash":
			return get("Dashed");
		case "solid":
			return get("Solid");
		case "invert":
			return get("Inverted");
		case "draw":
			return get("Hand-drawn");
	}
}

export function workspaceKindToName(kind: WorkspaceKind) {
	switch (kind) {
		case "personal":
			return get("Personal");
		case "company":
			return get("Company");
	}
}

export function getBestBrowserLanguageCode(): LanguageCode {
	return findBestLanguageCode(navigator.language) ?? DEFAULT_LANGUAGE_CODE;
}

export function getAnonymousUserName() {
	return get("Anonymous");
}

export function stabilityStylePresetToText(kind: StabilityAiStylePreset) {
	switch (kind) {
		case "3d-model":
			return get("3D model");
		case "analog-film":
			return get("Analog Film");
		case "anime":
			return get("Anime");
		case "cinematic":
			return get("Cinematic");
		case "comic-book":
			return get("Comic Book");
		case "digital-art":
			return get("Digital Art");
		case "enhance":
			return get("Enhance");
		case "fantasy-art":
			return get("Fantasy Art");
		case "isometric":
			return get("Isometric");
		case "line-art":
			return get("Line Art");
		case "low-poly":
			return get("Low Poly");
		case "modeling-compound":
			return get("Modeling Compound");
		case "neon-punk":
			return get("Neon Punk");
		case "origami":
			return get("Origami");
		case "photographic":
			return get("Photographic");
		case "pixel-art":
			return get("Pixel Art");
		case "tile-texture":
			return get("Tile Texture");
	}
}
