import { CustomFont } from "../theme/mindmap-theme";

export async function loadCustomFonts(fonts: CustomFont[]) {
	await Promise.all(fonts.map(loadCustomFont));
}

export async function loadCustomFont(font: CustomFont) {
	const { family, url } = font;
	try {
		const loadedFont = await new FontFace(family, `url(${url})`, { display: "swap" }).load();

		// Make it work both in browser and in worker
		if ("document" in globalThis) {
			(globalThis as any).document.fonts.add(loadedFont);
		} else {
			(globalThis as any).fonts.add(loadedFont);
		}

		console.log(`[custom font] - Loaded: ${family}, ${url}`);
	} catch (err) {
		console.error(`[custom font] - Error: ${name}, ${url}`, err);

		// A desperate attempt to make it work in Safari
		if ("document" in globalThis) {
			const $style = document.createElement("style");
			$style.innerHTML = `@font-face { font-family: "${family}"; src: url(${url}); }`;
			(globalThis as any).document.head.appendChild($style);
		}
	}
}
