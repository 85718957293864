import { Id, Model, Ref } from "./model";
import { User, UserBase, UserDAO } from "./user";
import { Workspace, WorkspaceBase, WorkspaceDAO } from "./workspace";

export enum WorkspaceAccessRankKind {
	GUEST = 1000,
	MEMBER = 2000,
	OWNER = 3000
}

export const DEFAULT_WORKSPACE_ACCESS_RANK: WorkspaceAccessRankKind = WorkspaceAccessRankKind.GUEST;

export const WORKSPACE_ACCESS_RANKS = [
	WorkspaceAccessRankKind.GUEST,
	WorkspaceAccessRankKind.MEMBER,
	WorkspaceAccessRankKind.OWNER
] as const;

export interface WorkspaceAccessBase extends Model {
	__entity?: "WorkspaceAccess";

	rank: WorkspaceAccessRankKind;
	workspace: Ref<WorkspaceBase>;
	user: Ref<UserBase>;
}

export interface WorkspaceAccess extends WorkspaceAccessBase {
	workspace: Ref<Workspace>;
	user: Ref<User>;
}

export interface WorkspaceAccessDAO extends WorkspaceAccessBase {
	workspace: Id<WorkspaceDAO>;
	user: Id<UserDAO>;
}
