import { BehaviorSubject, map } from "rxjs";
import { takeId, User } from "shared";
import { GlobalStore, subSuccessActionValue } from "../store";
import { sessionAction } from "./session-action";
import { removeItem, setItem } from "../../util/local-storage";
import { STORAGE_KEYS } from "../../config";
import { model$ } from "../model/model-list";
import { modelStore } from "../model/model-store";
import { getInitialSessionState } from "../../util/auth-util";

const initialSessionState = getInitialSessionState();

// Always absorb the initial cached session user from localstorage if there is one
if (initialSessionState.user != null) {
	modelStore.absorb(initialSessionState.user);
}

export class SessionStore extends GlobalStore {
	private sessionUserId = new BehaviorSubject(initialSessionState.userId);

	private _latestSessionUser = initialSessionState.user;
	get sessionUser() {
		return this._latestSessionUser;
	}

	model$ = {
		sessionUserId: this.sessionUserId,
		hasSession: this.sessionUserId.pipe(map((id) => id != null)),
		sessionUser: model$<User | null>([this.sessionUserId])
	};

	protected subscriptions() {
		return [
			model$([this.model$.sessionUser]).subscribe((user) => {
				if (user == null) {
					removeItem(STORAGE_KEYS.sessionUser);
				} else {
					setItem(STORAGE_KEYS.sessionUser, user);
				}
				this._latestSessionUser = user;

				this.sessionUserId.next(takeId(user));
			}),
			subSuccessActionValue(sessionAction.signup, (session) => {
				this.sessionUserId.next(takeId(session.user));
			}),
			subSuccessActionValue(sessionAction.login, (session) => {
				this.sessionUserId.next(takeId(session.user));
			}),
			subSuccessActionValue(sessionAction.refreshSessionUser, (user) => {
				this.sessionUserId.next(takeId(user));
			}),
			subSuccessActionValue(sessionAction.logout, () => {
				this.sessionUserId.next(null);
			})
		];
	}
}

export const sessionStore = new SessionStore();
