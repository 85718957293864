import { io, Socket as IOSocket } from "socket.io-client";
import { DefaultEventsMap, EventsMap } from "@socket.io/component-emitter";

/**
 * A socket class that connects to a websocket and responds to events.
 * Documentation about the socket client here: https://socket.io/docs/v4/client-socket-instance/
 */
export class Socket<ListenEvents extends EventsMap = DefaultEventsMap, EmitEvents extends EventsMap = ListenEvents> {
	protected connection!: IOSocket<ListenEvents, EmitEvents>;

	public get id() {
		return this.connection.id;
	}

	constructor(public url: string) {
		this.connection = io(url, {
			path: "/api/ws/activity",
			withCredentials: true,
			autoConnect: true,
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 30000,
			reconnectionAttempts: 30,
			transports: ["websocket"]
		});

		// Handle disconnect events
		this.connection.on("disconnect", (reason) => {
			// The disconnection was initiated by the server, we need to reconnect manually.
			if (reason === "io server disconnect") {
				this.reconnect();
			}

			// Else the socket will automatically try to reconnect
		});

		// Make sure we catch any errors here and not in the global
		this.connection.on("connect_error", (error) => {
			console.error("Socket connection error", error);
		});

		this.connection.on("connect", () => {
			this.didConnect();
		});
	}

	forceReconnect() {
		this.connection.disconnect();
		this.connection.connect();
	}

	protected reconnect() {
		this.connection.connect();
	}

	protected connect() {
		this.connection.connect();
	}

	disconnect() {
		this.connection.disconnect();
	}

	protected didConnect() {}
}
