import { QUERY_PARAMS, STORAGE_KEYS } from "../config";
import { pushState } from "./route-util";
import { api } from "../api/api";

/**
 * Saves the affiliate code to local storage if there is one.
 */
export async function saveAffiliateCode() {
	const params = new URLSearchParams(location.search);
	const affiliateCode = params.get(QUERY_PARAMS.affiliateVia);
	if (affiliateCode?.length) {
		// Track the referral click and save the referral ID
		const referralId = await api.promotekit.track(affiliateCode).then();
		if (referralId?.length) {
			localStorage.setItem(STORAGE_KEYS.affiliateReferralId, referralId);
		} else {
			localStorage.removeItem(STORAGE_KEYS.affiliateReferralId);
		}

		// Remove the affiliate code from the URL
		params.delete(QUERY_PARAMS.affiliateVia);
		pushState(`${location.pathname}${params.toString() ? `?${params.toString()}` : ""}`, {
			replace: true,
			silent: true
		});
	}
}

/**
 * Consumes the affiliate code from local storage if there is one.
 */
export function consumeAffiliateReferralId(): string | undefined {
	const affiliateCode = localStorage.getItem(STORAGE_KEYS.affiliateReferralId);
	if (affiliateCode?.length) {
		localStorage.removeItem(STORAGE_KEYS.affiliateReferralId);
		return affiliateCode;
	}

	return undefined;
}
