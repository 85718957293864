import { CustomFont, FontMetrics, MindmapFont } from "./mindmap-theme";
import { loadCustomFonts } from "../util/load-custom-font";

const BASE_URL = "/fonts";

export const DEFAULT_FONT_NAMES = ["lato", "shantell-sans"] as const;
export type DefaultFontName = (typeof DEFAULT_FONT_NAMES)[number];

// Use this website to figure out the metrics: https://seek-oss.github.io/capsize/
export const DEFAULT_FONTS: Record<DefaultFontName, CustomFont> = {
	lato: {
		family: "Lato",
		url: `${BASE_URL}/lato-regular.ttf`,
		metrics: {
			capHeight: 1433,
			ascent: 1974,
			descent: -426,
			unitsPerEm: 2000,
			xHeight: 1013,
			xWidthAvg: 959
		}
	},
	"shantell-sans": {
		family: "Shantell Sans",
		url: `${BASE_URL}/shantell-sans-regular.ttf`,
		metrics: {
			capHeight: 700,
			ascent: 1020,
			descent: -320,
			unitsPerEm: 1000,
			xHeight: 485,
			xWidthAvg: 535
		}
	}
};

export const DEFAULT_MINDMAP_FONT: MindmapFont = {
	fontSize: 18,
	fontFamily: DEFAULT_FONTS.lato.family,
	textAlign: "left",
	/* Only "alphabetic" is rendered the same across all browsers (fml) https://www.bennadel.com/blog/4322-canvas-alphabetic-textbaseline-is-consistent-across-browsers.htm */
	textBaseline: "alphabetic",
	textLineGap: 8
};

export async function loadDefaultFonts() {
	await loadCustomFonts(Object.values(DEFAULT_FONTS));
}

export function getFontMetrics(name: string): FontMetrics | undefined {
	for (const font of Object.values(DEFAULT_FONTS)) {
		if (font.family === name) {
			return font.metrics;
		}
	}

	return undefined;
}
