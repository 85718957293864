import { Id, isId, isModel, Model, Ref, takeId } from "shared";
import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	Observable,
	ReplaySubject,
	shareReplay,
	Subject,
	switchMap
} from "rxjs";
import { takeFirst } from "../../util/observable-util";

export class ModelStore {
	modelMap = new Map<Id, Subject<Model>>();

	private iterateNestedObjectsAndArrays(
		obj: any,
		callbacks: { transformModel?: (model: Model) => any },
		seenRefs = new Set<any>()
	): any {
		if (typeof obj === "object" && seenRefs.has(obj)) return;
		seenRefs.add(obj);

		if (obj instanceof Array) {
			obj = obj.map((item) => this.iterateNestedObjectsAndArrays(item, callbacks, seenRefs));
		} else if (obj instanceof Object && !(obj instanceof Date)) {
			const newObject = {};

			for (const [key, value] of Object.entries(obj)) {
				(newObject as any)[key] = this.iterateNestedObjectsAndArrays(value, callbacks, seenRefs);
			}

			obj = newObject;
		}

		if (isModel(obj) && callbacks.transformModel != null) {
			return callbacks?.transformModel(obj);
		}

		return obj;
	}

	private nextModel<TModel extends Model>(model: TModel) {
		const existing$ = this.modelMap.get(model.id);

		if (existing$ != null) {
			const existingModel = existing$ instanceof BehaviorSubject ? existing$.value : undefined;
			const didChange = existingModel == null || JSON.stringify(existingModel) !== JSON.stringify(model);
			if (!didChange) return;
			// console.log("nextModel", model);
			// console.trace();
			const nextModel = existingModel != null ? mergeNested(existingModel, model) : model;
			this.modelMap.get(model.id)!.next(nextModel);
			return nextModel;
		} else {
			this.modelMap.set(model.id, new BehaviorSubject<Model>(model));
			return model;
		}
	}

	async print() {
		// use "console.table" to print the modelMap

		const ids = Array.from(this.modelMap.keys());
		const list = await takeFirst(this.list$(ids));

		console.table(list);
		// console.table(list.map((model) => ({ id: model.id, model: JSON.stringify(model) })));
	}

	absorb<T>(model: T[]): T[];
	absorb<T>(model: T): T;
	absorb<T>(model: T): T | T[] {
		const idMap = new Map<Id, any>();

		const result = this.iterateNestedObjectsAndArrays(model, {
			transformModel: (obj) => {
				const id = takeId(obj);
				if (id != null) {
					idMap.set(id, obj);
					this.nextModel(obj);
				}
				return id;
			}
		});

		if (Array.isArray(result) && result.length > 0 && isId(result[0])) {
			return result.map((id) => idMap.get(id) ?? id);
		} else if (isId(result)) {
			return idMap.get(result) ?? result;
		}

		return result;
	}

	get$<TModel extends Model | null | undefined>(
		model: Ref<NonNullable<TModel>> | Observable<Ref<NonNullable<TModel>>>
	): Observable<TModel> {
		if (model instanceof Observable) {
			return model.pipe(
				// debounceTime(10),
				distinctUntilChanged(),
				switchMap((model) => this.get$(model)),
				shareReplay(1)
			);
		} else {
			const id = takeId(model);

			if (id == null) return new BehaviorSubject(id);

			if (this.modelMap.has(id)) {
				return this.modelMap.get(id)! as unknown as Observable<TModel>;
			} else {
				const subject = new ReplaySubject<Model>(1);
				this.modelMap.set(id, subject);
				return subject as unknown as Observable<TModel>;
			}
		}
	}

	list$<TModel extends Model>(model: Ref<TModel>[] | Observable<Ref<TModel>[]>): Observable<TModel[]> {
		if (model instanceof Observable) {
			return model.pipe(
				// debounceTime(10),
				distinctUntilChanged(),
				switchMap((model) => this.list$(model)),
				shareReplay(1)
			);
		} else {
			const modelObservables = model.map((ref) => this.get$(ref));
			if (modelObservables.length === 0) return new BehaviorSubject([]);
			return combineLatest(modelObservables).pipe(
				// debounceTime(10),
				distinctUntilChanged(),
				shareReplay(1)
			);
		}
	}

	async mutate<TModel extends Model>(model: Ref<TModel>, mutate: (model: TModel) => TModel) {
		const id = takeId(model);
		if (id == null) return;

		const existing$ = this.modelMap.get(id);
		if (existing$ == null) return;

		const existingModel = existing$ instanceof BehaviorSubject ? existing$.value : await takeFirst(existing$);
		if (existingModel == null) return;

		const newModel = mutate(existingModel);

		return this.nextModel(newModel);
	}

	async get<TModel extends Model>(model: Ref<TModel>): Promise<TModel | null | undefined> {
		return await takeFirst(this.get$(model));
	}

	getInstant<TModel extends Model>(model: Ref<TModel> | undefined): TModel | null | undefined {
		if (model == null) return undefined;

		const obs$ = this.modelMap.get(takeId(model));

		if (obs$ == null) return undefined;

		return obs$ instanceof BehaviorSubject ? obs$.value : null;
	}
}

function mergeNested<T>(obj: T, patch: Partial<T>): T {
	const result = {} as T;

	for (const [key, value] of Object.entries(patch)) {
		if (Array.isArray(value)) {
			(result as any)[key] = value;
		} else if (value instanceof Object && !(value instanceof Date)) {
			(result as any)[key] = mergeNested((result as any)[key], value);
		} else {
			(result as any)[key] = value;
		}
	}

	return result;
}

export const modelStore = new ModelStore();
