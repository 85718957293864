import { GlobalStore } from "../store";
import { loading$, success$ } from "../action";
import { model$, modelList$ } from "../model/model-list";
import { cmpIds, Id, Ref, takeId, Workspace } from "shared";
import { workspaceAction } from "./workspace-action";
import { BehaviorSubject, distinctUntilChanged, filter, Observable } from "rxjs";
import { getItem, removeItem, setItem } from "../../util/local-storage";
import { STORAGE_KEYS } from "../../config";
import { modelStore } from "../model/model-store";
import { hydrateGQLResult } from "../../api/gql/gql-util";

export class WorkspaceStore extends GlobalStore {
	private get cachedCurrentWorkspace() {
		return (modelStore.absorb(hydrateGQLResult(getItem(STORAGE_KEYS.currentWorkspace))) ?? null) as Workspace | null;
	}

	private _latestCurrentWorkspace = this.cachedCurrentWorkspace;
	get currentWorkspace() {
		return this._latestCurrentWorkspace;
	}

	private _currentWorkspaceId: Id<Workspace> | null = this.cachedCurrentWorkspace?.id ?? null;
	private currentWorkspaceId$ = new BehaviorSubject(takeId(this._currentWorkspaceId));
	private uniqueCurrentWorkspaceId = this.currentWorkspaceId$.pipe(distinctUntilChanged((a, b) => cmpIds(a, b)));

	model$ = {
		currentWorkspaceId: this.uniqueCurrentWorkspaceId,
		currentWorkspace: model$<Workspace | null>([this.uniqueCurrentWorkspaceId]),
		workspacesLoading: loading$(workspaceAction.list),
		workspaces: modelList$<Workspace>([
			[workspaceAction.list, "next"],
			[workspaceAction.create, "prepend"],
			[workspaceAction.remove, "remove"],
			[
				success$(workspaceAction.setCurrentWorkspace).pipe(filter((workspace) => workspace != null)) as Observable<
					Ref<Workspace>
				>,
				"append"
			]
		])
	};

	get currentWorkspaceId() {
		return this._currentWorkspaceId;
	}

	protected subscriptions() {
		return [
			this.model$.currentWorkspaceId.subscribe((workspaceId) => {
				this._currentWorkspaceId = workspaceId;
			}),
			success$(workspaceAction.setCurrentWorkspace).subscribe((workspace) => {
				this.currentWorkspaceId$.next(takeId(workspace));
			}),
			this.model$.currentWorkspace.subscribe((workspace) => {
				this._latestCurrentWorkspace = workspace;
				if (workspace != null) {
					setItem(STORAGE_KEYS.currentWorkspace, workspace);
				} else {
					removeItem(STORAGE_KEYS.currentWorkspace);
				}
			})
		];
	}
}

export const workspaceStore = new WorkspaceStore();
