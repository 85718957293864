/**
 * Shuffles an array using the Fisher-Yates shuffle algorithm.
 * @param arr
 */
export function shuffleArray<T>(arr: T[]): T[] {
	// Copy the original array to avoid modifying it
	const copy = [...arr];

	// Shuffle the copy using the Fisher-Yates shuffle algorithm
	for (let i = copy.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[copy[i], copy[j]] = [copy[j], copy[i]];
	}

	return copy;
}

/**
 * Picks x random items from an array.
 * @param arr
 * @param n
 */
export function pickRandomItems<T>(arr: T[], n: number): T[] {
	return shuffleArray(arr).slice(0, n);
}

/**
 * Picks a random item from an array.
 * @param arr
 */
export function pickRandomItem<T = any>(arr: T[] | readonly T[]): T {
	if (!arr.length) throw new Error("Cannot pick a random item from an empty array.");
	return arr[Math.floor(Math.random() * arr.length)];
}

export function firstInArray<T>(arr: T[] | T): T {
	return Array.isArray(arr) ? arr[0] : arr;
}

export function pickRandomWeightedItem<T>(arr: [T, number][]): T {
	if (arr.length === 0) throw new Error("Cannot pick a random item from an empty array.");

	const totalWeight = arr.reduce((acc, [_, weight]) => acc + weight, 0);
	const random = Math.random() * totalWeight;

	let currentWeight = 0;

	for (const [item, weight] of arr) {
		currentWeight += weight;
		if (random <= currentWeight) return item;
	}

	throw new Error("Unexpected error while picking a random weighted item.");
}

export function filterUndefinedFromArray<T>(arr: (T | undefined | null)[]): T[] {
	return arr.filter((v): v is T => v != null);
}

export async function paginateItems<T>(
	items: T[],
	callback: (item: T) => Promise<void>,
	{ pageSize, parallel }: { pageSize: number; parallel: boolean }
) {
	items = [...items];
	while (items.length > 0) {
		const pageItems = items.splice(0, pageSize);
		if (parallel) {
			await Promise.all(pageItems.map(callback));
		} else {
			for (const item of pageItems) {
				await callback(item);
			}
		}
	}
}
