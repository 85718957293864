import { openDialog, WebDialog } from "web-dialog";
import { html, render } from "lit";
import { prepareDialogForWrapper } from "../atoms/dialog-wrapper";

export async function openAcceptTerms(): Promise<boolean> {
	import("../molecules/accept-terms");
	return (
		(await openDialog<WebDialog>({
			initialize: () => {
				const $d = new WebDialog();
				$d.style.setProperty("--dialog-max-width", "600px");
				$d.dataset["blur"] = "true";
				$d.addEventListener("closing", (e) => e.preventDefault());
				prepareDialogForWrapper($d);
				return $d;
			},
			center: true,
			$content: ($dialog) =>
				render(
					html`
						<bs-accept-terms
							@decline="${() => $dialog.close(false)}"
							@accept="${() => $dialog.close(true)}"
						></bs-accept-terms>
					`,
					$dialog
				)
		}).resolver) ?? false
	);
}
