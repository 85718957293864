import { css, html, LitElement, PropertyValues } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";
import { registerServiceWorkerWithWorkbox } from "../util/sw-util";
import {
	APP_BUILD_DATE,
	DEFAULT_COLOR_MODE,
	ENVIRONMENT,
	getIsMenuOpenFromStorage,
	QUERY_PARAMS,
	ROUTES,
	SERVICE_WORKER_ENABLED,
	SERVICE_WORKER_PATH,
	STORAGE_KEYS,
	UTM_MEDIUM_PWA
} from "../config";
import {
	addRouteChangedListener,
	dispatchRouteChangedEvent,
	getQueryParam,
	pushState,
	reloadApp
} from "../util/route-util";
import {
	APIError,
	APIErrorKind,
	COLOR_BLACK,
	COLOR_WHITE,
	debounce,
	DEFAULT_LANGUAGE_CODE,
	djb2Hash,
	EVENT_TEAMS_AUTH_TOKEN_ERROR,
	EVENT_UNHANDLED_ERROR,
	findBestLanguageCode,
	fromBinaryStr,
	getLanguageEmoji,
	languageCodeToISO,
	MS_TEAMS_BACKGROUND_DARK,
	MS_TEAMS_BACKGROUND_LIGHT,
	PALETTE,
	sleep,
	truncateString
} from "shared";
import { Controller, ControllerHost } from "../state/controller";
import { sessionStore, SessionStore } from "../state/session/session-store";
import { activityAction, caughtError } from "../state/activity/activity-action";
import { openAlert, openReportFeedback } from "../util/dialog-helper";
import { eventService } from "../services/event-service";
import { getColorModeColors, getColorModeFromStorage, saveColorMode } from "../util/color-mode-util";
import { teamsService } from "../services/teams-service";
import { teamsStore } from "../state/teams/teams-store";
import { getTeamsAuthToken, initializeTeams, setTeamsContext } from "../state/teams/teams-action";
import { Store } from "../state/store";
import { setColorModeColors, setPaletteCSSProperties, setThemeColorCSSProperty } from "../util/color-util";
import { ifDefined } from "lit/directives/if-defined.js";
import { setIsMenuOpen, setIsMobile, setIsPwa, setIsSandbox } from "../state/app/app-action";
import { getAppHeight, isMobile } from "../util/util";
import { setItem } from "../util/local-storage";
import { appStore } from "../state/app/app-store";
import { themeStore } from "../state/theme/theme-store";
import { setColorMode } from "../state/theme/theme-action";
import { FrameContexts } from "@microsoft/teams-js";
import { ensureExternalLinkDialog, guessIsTeamsContext } from "../util/teams-util";
import { captureInstallPromptEvent } from "../util/install-prompt";
import { countToastsWithId, showToast } from "../atoms/toast";
import { ICON_ERROR, ICON_SUCCESS_CIRCLE, ICON_UPDATE_AVAILABLE } from "../../icons";
import { workspaceStore } from "../state/workspace/workspace-store";
import { success$ } from "../state/action";
import { errorToText } from "../util/translate-util";
import { debugService } from "../services/trackers/debug-service";
import { umamiService } from "../services/trackers/umami-service";
import { roomStore } from "../state/room/room-store";
import { getDialogCount } from "web-dialog";
import { takeFirst } from "../util/observable-util";
import { login } from "../state/session/session-ac";
import { roomSocket } from "../socket/room-socket";
import { distinctUntilChanged, merge } from "rxjs";
import { sessionAction } from "../state/session/session-action";
import { saveAffiliateCode } from "../util/affiliate-util";
import { get, registerTranslateConfig, use } from "lit-translate";
import { languageStore } from "../state/language/language-store";
import { setLanguage } from "../state/language/language-action";

const DEBUG_STRINGS = false;

registerTranslateConfig({
	loader: (lang) =>
		lang === DEFAULT_LANGUAGE_CODE
			? Promise.resolve({})
			: fetch(`/i18n/${lang}/translations.json?v=${APP_BUILD_DATE.getTime()}`).then((res) => res.json()),
	empty: (key) => `${key}`,
	lookup: (key, config) => {
		if (DEBUG_STRINGS) {
			return languageStore.currentLanguage != null ? getLanguageEmoji(languageStore.currentLanguage) : "✅";
		}

		return config.strings != null ? (config.strings[djb2Hash(key)] as string) : key;
	}
});

const API_ERRORS_WITHOUT_TOAST: APIErrorKind[] = [
	"unauthorized",
	"rateLimitExceeded",
	"forbidden",
	"notFound",
	"noAccountFound"
];

/**
 * Set up global stuff.
 */
async function setupApp() {
	eventService.addTrackersAndSetup(umamiService, debugService);

	// Setup colors as the first thing
	setPaletteCSSProperties(PALETTE);
	setThemeColorCSSProperty(`--white`, COLOR_WHITE);
	setThemeColorCSSProperty(`--black`, COLOR_BLACK);

	setColorMode(getColorModeFromStorage());
	setIsMenuOpen(getIsMenuOpenFromStorage());

	// Initialize teams super early :-(
	initializeTeams().then();

	// Listen for the install prompt event so we can show an install button
	captureInstallPromptEvent();

	// Register service worker if not local host
	if (SERVICE_WORKER_ENABLED) {
		registerServiceWorkerWithWorkbox(SERVICE_WORKER_PATH).then();
	}

	window.addEventListener("popstate", dispatchRouteChangedEvent);

	// This event handler is called when a Promise is rejected, but there is no rejection handler attached to it
	window.onunhandledrejection = (e) => {
		caughtError(e.reason instanceof Error ? e.reason : new Error(e.reason));
	};

	// This event handler is called for unhandled runtime errors.
	window.onerror = (message, source, lineNumber, colno, error) => {
		if (error != null) caughtError(error);
	};

	// Add offline toast
	const internetToastId = "internet-toast";
	window.addEventListener("online", () =>
		showToast(get("You are online again"), { id: internetToastId, icon: ICON_SUCCESS_CIRCLE })
	);
	window.addEventListener("offline", () =>
		showToast(get("You are offline"), { id: internetToastId, duration: Infinity, icon: ICON_ERROR })
	);

	// https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
	const updateAppHeight = () => document.documentElement.style.setProperty("--app-height", `${getAppHeight()}px`);
	window.addEventListener("resize", updateAppHeight);
	updateAppHeight();

	const medium = getQueryParam(QUERY_PARAMS.utmMedium)?.toString();
	setIsPwa(medium === UTM_MEDIUM_PWA);

	// Save the affiliate code if there is one
	saveAffiliateCode();

	// Hookup language store
	languageStore.model$.currentLanguage.pipe(distinctUntilChanged()).subscribe((code) => {
		use(code);
		document.documentElement.lang = languageCodeToISO(code);
	});

	// Handle uncaught errors
	success$(activityAction.caughtError).subscribe(async (error) => {
		// If we cant find a chunk its most likely because we updated the app while the user was using it
		// and we are not saving old files. Can this be checked in a better way?
		if (navigator.onLine && ["module", "chunk"].find((str) => error.message.toLowerCase().includes(str)) != null) {
			const reloadToastId = "reload-toast";
			if (countToastsWithId(reloadToastId) > 0) {
				return;
			}

			showToast(get("A new version of Ideamap is available"), {
				id: reloadToastId,
				icon: ICON_UPDATE_AVAILABLE,
				duration: Infinity,
				buttons: [
					{
						text: get("Dismiss")
					},
					{
						text: get("Reload"),
						onClick: () => reloadApp(),
						important: true
					}
				]
			});

			return;
		}

		if (error instanceof APIError) {
			switch (error.kind) {
				case "rateLimitExceeded":
					const { openRateLimited } = await import("../dialogs/open-rate-limited");

					if (getDialogCount(document.documentElement) > 0) return;
					openRateLimited(error);
					break;
			}

			// Check if we should continue
			if (API_ERRORS_WITHOUT_TOAST.includes(error.kind)) {
				return;
			}
		}

		// The server is most likely updating if it contains the word fetch
		// Necessary until we have multipod (todo: move to server).
		if (["fetch", "load"].some((text) => error.message.toLowerCase().includes(text))) {
			if (getDialogCount(document.documentElement) === 0) {
				const { openUpdatingServer } = await import("../dialogs/open-updating-server");
				const { checkServerStatus } = await import("./../molecules/updating-server");
				debounce(
					async () => {
						if (await checkServerStatus()) return;
						await openUpdatingServer();
					},
					"open-updating-server",
					500
				);
			}
			return;
		}

		// Show an error message to the user
		if (ENVIRONMENT !== "production") {
			showToast(
				error instanceof APIError ? get(`The server responded with an error`) : get(`An unexpected error occurred`),
				{
					icon: ICON_ERROR,
					duration: 7000,
					id: "error-toast",
					label: error instanceof APIError ? errorToText(error) : truncateString(error.message, 90),
					buttons: [
						{
							text: get("Report"),
							onClick: () => {
								const debugInformation = debugService.createDebugInformation(error);
								openReportFeedback({ debug: debugInformation });
							}
						}
					]
				}
			);
		}

		// Track the error
		eventService.trackEvent(EVENT_UNHANDLED_ERROR(error));
	});

	if (ENVIRONMENT === "production") {
		const { sentryService } = await import("../services/trackers/sentry-service");
		eventService.addTrackersAndSetup(sentryService);
	}

	// Force roomSocket to reconnect when the user logs in or out
	merge(success$(sessionAction.login), success$(sessionAction.signup), success$(sessionAction.logout)).subscribe(() => {
		roomSocket.forceReconnect();
	});

	window.addEventListener(
		"beforeunload",
		(e) => {
			roomSocket.leaveRoom();
		},
		{ passive: true }
	);
}

setupApp();

class AppPageStore extends Store {
	model$ = {
		teamsAuthToken: teamsStore.model$.authToken,
		teamsAuthTokenLoading: teamsStore.model$.authTokenLoading,
		isTeamsInitialized: teamsStore.model$.initialized,
		sessionUser: sessionStore.model$.sessionUser,
		hasSession: sessionStore.model$.hasSession,
		teamsContext: teamsStore.model$.context,
		isMenuOpen: appStore.model$.isMenuOpen,
		colorMode: themeStore.model$.colorMode,
		currentWorkspace: workspaceStore.model$.currentWorkspace,
		currentRoom: roomStore.model$.currentRoom,
		appContext: appStore.model$.appContext
	};
}

@customElement("bs-app-page")
class BsApp extends LitElement implements ControllerHost<SessionStore> {
	protected controller = new Controller(this, new AppPageStore(), {
		teamsAuthToken: async (token) => {
			console.log("[teams]: Auth token", token);
			if (!(await takeFirst(sessionStore.model$.hasSession))) {
				try {
					await login({
						kind: "teams",
						token
					});

					// Wait a bit to avoid flashing the login screen
					await sleep(500);
				} catch (error) {
					if (error instanceof APIError && error.kind === "noAccountFound") {
						// This error is intended for first time users
					} else {
						openAlert(
							get(`An unknown error occurred when trying to login with Microsoft Teams: {{message}}`, {
								message: error.message
							})
						);
					}
				}
			}

			teamsService.notifyAppLoaded(true);
		},
		teamsAuthTokenLoading: (state) => {
			if (state.error) {
				//const errorName = state.error.message;
				//const success = ["CancelledByUser", "noAccessForResource"].includes(errorName);
				console.log("[teams]: Error loading auth token", state.error);

				// Always notify success since we tell in the teams setup how to fix the error
				teamsService.notifyAppLoaded(true);
			}
		},
		colorMode: this.updateColorModeColors.bind(this),
		teamsContext: async (context) => {
			console.log("[teams]: Teams context", context);

			if (context == null) return;
			setColorMode(teamsService.msThemeToColorMode(context.app.theme));
			this.updateColorModeColors();

			teamsService.registerOnThemeChangeHandler((colorMode) => {
				setColorMode(colorMode);
				reloadApp();
			});

			// Use the language of the UI
			const languageCode = findBestLanguageCode(context.app.locale);
			if (languageCode != null) {
				setLanguage(languageCode);
			}

			// Never show the menu in teams if its not content and not hosted within a chat or a channel
			if (context.page.frameContext !== FrameContexts.content || context.chat != null || context.channel != null) {
				console.log("[teams]: Force sandbox");
				setIsSandbox(true);
				teamsService.registerBackButtonHandler(() => false);
			}

			// If we have a sub page id (link code), replace the url with join room link navigated here (deep link)
			if (context.page.subPageId != null && context.page.subPageId.length > 0) {
				const joinRoomPath = `${ROUTES.joinRoom(context.page.subPageId)}?${QUERY_PARAMS.continue}`;
				console.log("[teams]: Joining room", joinRoomPath);
				pushState(joinRoomPath, { replace: true });
			}
		},
		isTeamsInitialized: async (success) => {
			console.log("[teams]: initialize status", success);
			if (success) {
				console.log("[teams]: Getting context...");

				// Get the context
				const context = await teamsService.getContext();
				setTeamsContext(context);
				ensureExternalLinkDialog();

				console.log("[teams]: Getting auth token...");
				try {
					await getTeamsAuthToken({});
				} catch (err) {
					eventService.trackEvent(EVENT_TEAMS_AUTH_TOKEN_ERROR(err));
				}
			} else {
				teamsService.notifyAppLoaded(false);
			}
		},
		isMenuOpen: (val) => setItem(STORAGE_KEYS.isMenuOpen, val),
		currentWorkspace: () => this.updateEventProps(),
		sessionUser: () => this.updateEventProps(),
		currentRoom: () => this.updateEventProps(),
		appContext: () => this.updateEventProps()
	});

	static get styles() {
		return [sharedStyles, css``];
	}

	protected firstUpdated(props: PropertyValues) {
		super.firstUpdated(props);
		this.setup();
	}

	async setup() {
		addRouteChangedListener(this.onRouteChanged.bind(this));
		setIsSandbox(getQueryParam(QUERY_PARAMS.embed) != null);
		window.addEventListener("resize", () => this.updateIsMobile(100));
		this.updateIsMobile();
		this.updateEventProps();
		eventService.trackPageView();
	}

	onRouteChanged() {
		this.requestUpdate();
		eventService.trackPageView();
	}

	updateEventProps() {
		const { sessionUser, currentWorkspace, currentRoom, appContext } = this.controller.model;
		eventService.setProps({
			user: sessionUser,
			workspace: currentWorkspace,
			room: currentRoom,
			platform: appContext ?? (guessIsTeamsContext() ? "teams" : undefined)
		});
	}

	updateIsMobile(ms: number = 0) {
		debounce(() => setIsMobile(isMobile()), "update-is-mobile", ms);
	}

	updateColorModeColors() {
		const { isTeamsInitialized, colorMode = DEFAULT_COLOR_MODE } = this.controller.model;
		saveColorMode(colorMode);
		const colors = getColorModeColors(colorMode);
		setColorModeColors(colors);

		// Overwrite the background so it blends in with the teams background
		if (isTeamsInitialized) {
			setThemeColorCSSProperty("app-background", colors.isDark ? MS_TEAMS_BACKGROUND_DARK : MS_TEAMS_BACKGROUND_LIGHT);
		}
	}

	render() {
		const { isTeamsInitialized, hasSession } = this.controller.model;
		const pathname = location.pathname;

		// Check if the user is using a join room link
		// Always prioritize join room links, also over teams since it can be hosted in a tab
		if (pathname.startsWith(ROUTES.joinRoom(""))) {
			const accessCode = pathname.split("/")?.[2]?.trim();
			import("./join/join-room-page");
			return html` <bs-join-room-page accessCode="${accessCode}"></bs-join-room-page> `;
		}

		// If the session user is null but teams is initialized we ALWAYS send the user to the teams page
		// so the user can set up the teams integration.
		if (!hasSession && isTeamsInitialized) {
			import("./teams/setup-teams-page");
			return html`<bs-setup-teams-page></bs-setup-teams-page>`;
		}

		// If the route is poster we show the poster page
		if (pathname.startsWith(ROUTES.poster)) {
			import("./poster/poster-page");
			return html` <bs-poster-page></bs-poster-page> `;
		}

		// If the route is brainstorm we show the brainstorm page
		if (pathname.startsWith(ROUTES.brainstormEmbed)) {
			import("./brainstorm-embed/brainstorm-embed-page");
			return html`<bs-brainstorm-embed-page></bs-brainstorm-embed-page>`;
		}

		// If the route is mindmap we show the mindmap page
		if (pathname.startsWith(ROUTES.mindmapEmbed)) {
			import("./mindmap-embed/mindmap-embed-page");
			return html`<bs-mindmap-embed-page></bs-mindmap-embed-page>`;
		}

		// If the route is pdf we show the pdf page
		if (pathname.startsWith(ROUTES.pdf)) {
			import("./pdf/pdf-page");
			return html` <bs-pdf-page></bs-pdf-page>`;
		}

		// Check if the user is using a join workspace link
		if (pathname.startsWith(ROUTES.joinWorkspace(""))) {
			const pathnameParts = pathname.split("/");
			const accessCode = pathnameParts?.[2];
			const name = (() => {
				const namePart = pathnameParts?.[3];
				if (namePart == null) return null;
				try {
					return fromBinaryStr(atob(decodeURIComponent(namePart)))
						.trim()
						.toString();
				} catch (err) {
					return null;
				}
			})();
			import("./join/join-workspace-page");
			return html`
				<bs-join-workspace-page accessCode="${accessCode}" name="${ifDefined(name)}"></bs-join-workspace-page>
			`;
		}

		// If the route is legal we show the legal page
		if (pathname.startsWith(ROUTES.legal)) {
			import("./landing/legal-page");
			return html` <bs-legal-page></bs-legal-page> `;
		}

		if (hasSession) {
			// Check if we should show the landing page
			if (
				[ROUTES.affiliate, ROUTES.blog(), ROUTES.pricing, ROUTES.sitemap, ROUTES.toolbox()].findIndex((path) =>
					pathname.startsWith(path)
				) >= 0
			) {
				import("./landing/landing-page");
				return html` <bs-landing-page></bs-landing-page>`;
			}

			// If we have a session always send the user to the session page
			import("./session/session-page");
			return html` <bs-session-page></bs-session-page> `;
		}

		// Default route is the landing page
		import("./landing/landing-page");
		return html` <bs-landing-page></bs-landing-page>`;
	}
}
