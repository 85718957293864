import { pickRandomItem } from "shared";
import { getInitials } from "./util";
import { UNNAMED_USER_NAME } from "../config";
import { get, translate } from "lit-translate";

/**
 * Returns the best name.
 * @param name
 * @param maxLength
 * @param initialsLength
 * @param defaultName
 */
export function getBestName(
	name: string | undefined,
	{ initialsLength, maxLength, defaultName }: { maxLength?: number; initialsLength?: number; defaultName?: string } = {}
): string {
	name = name?.trim() || defaultName || UNNAMED_USER_NAME;

	// Check if we can return the name.
	if (maxLength == null || name.length < maxLength) {
		return name;
	}

	// Check if we can return the first name.
	const nameLessThanMaxLength = name.split(" ").find((part) => part.length < maxLength);
	if (nameLessThanMaxLength != null) {
		return nameLessThanMaxLength;
	}

	// Return initials as a last resort.
	return getInitials(name, initialsLength);
}

/**
 * Returns a user greeting based on the current time of day.
 * @param name
 */
export function getFriendlyUserGreeting(name?: string) {
	const currentTime = new Date().getHours();
	name = getBestName(name, { maxLength: 20 });
	const includeName = name.length && name !== UNNAMED_USER_NAME;

	if (currentTime >= 5 && currentTime < 12) {
		return includeName ? translate(`Good morning, {{name}}`, { name }) : translate("Good morning");
	} else if (currentTime >= 12 && currentTime < 18) {
		return includeName ? translate(`Good afternoon, {{name}}`, { name }) : translate("Good afternoon");
	} else {
		return includeName ? translate(`Good evening, {{name}}`, { name }) : translate("Good evening");
	}
}

/**
 * Returns a friendly user message based on the current time of day.
 */
export function getFriendlyUserMessage() {
	const currentTime = new Date().getHours();
	const messages = (() => {
		if (currentTime >= 5 && currentTime < 12) {
			return [
				get("Have a productive day!"),
				get("Make today count!"),
				get("Stay focused and positive!"),
				get("You got this!"),
				get("The world is your oyster"),
				get("Set your mind to something new"),
				get("Let's start the day right!"),
				get("Make the most of your time"),
				get("Seize the day!"),
				get("Be your best self today"),
				get("Keep up the good work!")
			];
		} else if (currentTime >= 12 && currentTime < 18) {
			return [
				get("How about a quick stretch break?"),
				get("Stay hydrated and healthy!"),
				get("Take a breather and relax!"),
				get("You're doing great!"),
				get("Keep pushing forward!"),
				get("Enjoy the journey!"),
				get("The future is yours"),
				get("Stay positive and motivated!"),
				get("You're making progress!"),
				get("Small steps lead to big wins!")
			];
		} else {
			return [
				get("Relax and unwind a little."),
				get("Take some time for yourself!"),
				get("Be kind to yourself!"),
				get("You're capable of great things!"),
				get("Stay positive and hopeful!"),
				get("Tomorrow's a new day!"),
				get("Keep moving forward!"),
				get("Take it one step at a time!")
			];
		}
	})();

	return pickRandomItem(messages);
}

/**
 * Returns a friendly user greeting emoji based on the current time of day.
 */
export function getFriendlyUserGreetingEmoji() {
	const currentTime = new Date().getHours();
	const messages = (() => {
		if (currentTime >= 5 && currentTime < 12) {
			return ["☀️", "☕️", "🌤️"];
		} else if (currentTime >= 12 && currentTime < 18) {
			return ["☀️", "☕️", "🌤️"];
		} else {
			return ["🌆", "🌙", "🍷"];
		}
	})();

	return pickRandomItem(messages);
}
