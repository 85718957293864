import { openDialog, WebDialog } from "web-dialog";
import { html, render, unsafeCSS } from "lit";
import { stopEvent } from "./util";
import { ifDefined } from "lit/directives/if-defined.js";
import { prepareDialogForWrapper } from "../atoms/dialog-wrapper";
import "../atoms/dialog-wrapper";
import { getVersionTag, IDEA_WIDGET_IDS } from "../config";
import { ColorMode, hasValue, takeId } from "shared";
import { getBestName } from "./user-greeting";
import { sessionStore } from "../state/session/session-store";
import { workspaceStore } from "../state/workspace/workspace-store";
import { getColorModeFromStorage } from "./color-mode-util";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { WebDialogWithClose } from "../atoms/web-dialog-with-close";
import { get, translate } from "lit-translate";

const IDEA_WIDGET_URL = "https://idea-widget.ideanote.io";

/**
 *  Opens a dialog with the given text.
 * @param text
 */
export function openAlert(text: string) {
	return openDialog({
		initialize: () => {
			const $d = new WebDialog();
			$d.style.setProperty("--dialog-max-width", "300px");
			prepareDialogForWrapper($d);
			return $d;
		},
		center: true,
		$content: ($dialog) =>
			render(
				html`
					<bs-dialog-wrapper>
						<article>
							<bs-text>${text}</bs-text>
						</article>
						<footer>
							<bs-form-item>
								<button slot="input" type="submit" @click="${() => $dialog.close()}">${translate(`Okay`)}</button>
							</bs-form-item>
						</footer>
					</bs-dialog-wrapper>
				`,
				$dialog
			)
	});
}

/**
 * Opens a dialog with the given text and a red confirm button.
 * @param text
 * @param confirmText
 * @param cancelText
 */
export function openConfirmDelete({
	text,
	confirmText,
	cancelText
}: {
	text: string;
	confirmText?: string;
	cancelText?: string;
}) {
	return openConfirm({
		text,
		confirmText: confirmText || get("Yes, delete"),
		cancelText: cancelText || get("Cancel"),
		isDestructive: true
	});
}

/**
 * Opens a dialog with the given text and a confirm button.
 * @param text
 * @param destructive
 * @param confirmText
 * @param cancelText
 * @param isHtml
 * @param maxWidth
 */
export function openConfirm({
	text,
	isDestructive,
	confirmText,
	cancelText,
	isHtml,
	maxWidth
}: {
	text: string;
	isDestructive?: boolean;
	confirmText?: string;
	cancelText?: string;
	isHtml?: boolean;
	maxWidth?: number;
}): Promise<boolean | undefined> {
	return openDialog({
		initialize: () => {
			const $d = new WebDialog();
			$d.style.setProperty("--dialog-max-width", `${maxWidth || 300}px`);
			return $d;
		},
		center: true,
		$content: ($dialog) =>
			render(
				html`
					<style>
						${isDestructive
							? unsafeCSS(`
						#cta {
							--form-item-bg: var(--error-normal);
							--form-item-color: var(--error-contrast-normal);
						}
						`)
							: ""}
					</style>
					<article>
						<bs-text>${isHtml ? unsafeHTML(text) : text}</bs-text>
					</article>
					<footer style="display: flex; margin-top: var(--space-l); justify-content: flex-end">
						<bs-form-item invert flat>
							<button slot="input" type="submit" @click="${() => $dialog.close(false)}">
								${cancelText || get("No")}
							</button>
						</bs-form-item>
						<bs-form-item autofocus id="cta">
							<button slot="input" @click="${() => $dialog.close(true)}">${confirmText || get("Yes")}</button>
						</bs-form-item>
					</footer>
				`,
				$dialog
			)
	}).resolver;
}

/**
 * Opens a dialog that makes the user feel awfully good.
 * @param title
 * @param text
 * @param icon
 * @param img
 * @param color
 * @param buttonText
 */
export function openAwesomeDialog({
	title,
	text,
	icon = "🥳",
	img,
	color = "#ffb200",
	buttonText
}: {
	title?: string;
	text: string;
	icon?: string;
	img?: string;
	color?: string;
	buttonText?: string;
}) {
	title = title ?? get("Congratulations!");
	buttonText = buttonText ?? get("Okay");

	import("../atoms/awesome-card");
	return openDialog({
		initialize: () => {
			const $d = new WebDialogWithClose<boolean>();
			$d.style.setProperty("--dialog-max-width", "500px");
			$d.style.setProperty("--dialog-padding", "0");
			return $d;
		},
		center: true,
		$content: ($dialog) =>
			render(
				html`
					<bs-awesome-card
						icon="${ifDefined(icon)}"
						headline="${ifDefined(title)}"
						text="${ifDefined(text)}"
						button-text="${ifDefined(buttonText)}"
						img="${ifDefined(img)}"
						color="${ifDefined(color)}"
						@done="${() => $dialog.close(true)}"
					></bs-awesome-card>
				`,
				$dialog
			)
	});
}

/**
 * Opens a dialog where the user can enter a prompt.
 * @param text
 * @param placeholder
 * @param defaultValue
 * @param required
 */
export function openPrompt({
	text,
	placeholder,
	defaultValue,
	required
}: {
	text: string;
	placeholder?: string;
	defaultValue?: string;
	required?: boolean;
}): Promise<string | undefined> {
	return openDialog({
		initialize: () => {
			const $d = new WebDialog();
			$d.style.setProperty("--dialog-max-width", "500px");
			return $d;
		},
		center: true,
		$content: ($dialog) =>
			render(
				html`
					<form
						@submit="${(e: Event) => {
							stopEvent(e);
							const text = (new FormData(e.target as HTMLFormElement).get("text") as string).trim();
							$dialog.close(text);
						}}"
					>
						<bs-text margin>${text}</bs-text>
						<bs-form-item invert fill>
							<input
								slot="input"
								type="text"
								value="${ifDefined(defaultValue)}"
								placeholder="${ifDefined(placeholder)}"
								name="text"
								autocomplete="off"
								?required="${ifDefined(required)}"
							/>
						</bs-form-item>
						<button type="submit" style="visibility: hidden; position: absolute;"></button>
					</form>
					<footer style="display: flex; margin-top: var(--space-l); justify-content: flex-end">
						<bs-form-item invert flat>
							<button slot="input" type="submit" @click="${() => $dialog.close()}">${translate("Cancel")}</button>
						</bs-form-item>
						<bs-form-item>
							<button
								slot="input"
								type="submit"
								@click="${() => {
									const $submit = $dialog.querySelector("button[type=submit]") as HTMLButtonElement;
									$submit.click();
								}}"
							>
								${translate("Done")}
							</button>
						</bs-form-item>
					</footer>
				`,
				$dialog
			)
	}).resolver;
}

export function openReportFeedback({ text, debug }: { text?: string; debug?: string } = {}) {
	const workspace = workspaceStore.currentWorkspace;
	const user = sessionStore.sessionUser;

	return openIdeanoteWidget({
		id: IDEA_WIDGET_IDS.feedback,
		title: get("Feedback"),
		text: get("Report an issue or suggest a feature to Ideamap"),
		// Idea template form field ID on https://ideamap.ideanote.io/settings/idea-templates
		defaultValues: Object.fromEntries(
			[
				["a1104f08-cd7b-4bd3-9ed8-e6540afa38ec", text],
				["444bad8c-ac09-4ee6-86ff-14f1cfab215a", workspace?.name],
				["5dee5612-5c60-43b7-959e-e986a789243a", takeId(workspace)],
				["0817f76d-3304-47c8-9eb2-5f037331ec3a", getBestName(user?.name)],
				["07dba0ac-3bb6-43eb-ab08-fac89df4bf26", takeId(user)],
				["ce842858-0184-4903-9dd3-c03de04e5807", user?.email],
				["6f73d8e8-ce9a-483c-9475-5f184160122b", debug],
				["036c59f9-9326-4717-b4a4-435b0543970a", getVersionTag()]
			].filter(([id, value]) => hasValue(value))
		) as Record<string, string>
	});
}

export async function openSurveyAlert() {
	if (
		await openAwesomeDialog({
			title: get("Do you have time for a quick survey?"),
			text: get("We would like to learn how to improve. Please take a moment to tell us about your experience."),
			buttonText: get("Start survey"),
			icon: "🔬",
			color: "#d1d3d9"
		}).resolver
	) {
		openSurvey();
	}
}

export function openSurvey() {
	const workspace = workspaceStore.currentWorkspace;
	const user = sessionStore.sessionUser;
	return openIdeanoteWidget({
		id: IDEA_WIDGET_IDS.survey,
		title: get("Ideamap survey"),
		text: get("Thank you for taking the time to help us improve Ideamap!"),
		maxWidth: 800,
		// Idea template form field ID on https://ideamap.ideanote.io/settings/idea-templates
		defaultValues: Object.fromEntries(
			[
				["ef145e65-2cb6-4f93-ad9d-43b5fc69f455", workspace?.name],
				["335cc849-7204-4507-babd-48fa87c51097", takeId(workspace)],
				["f604576c-19ee-4399-8420-a145b306442a", getBestName(user?.name)],
				["72aeffe4-9785-4372-867f-16d6a8a4aae6", takeId(user)],
				["aed94b7a-0bcd-49af-bdb8-74b7d56db0c4", getVersionTag()]
			].filter(([id, value]) => hasValue(value))
		) as Record<string, string>
	});
}

export function openIdeanoteWidget({
	id,
	defaultValues,
	title,
	text,
	maxWidth,
	colorMode = getColorModeFromStorage()
}: {
	id: string;
	defaultValues?: Record<string, string | number>;
	title: string;
	text: string;
	maxWidth?: number;
	colorMode?: ColorMode | "auto";
}) {
	const params = new URLSearchParams({
		config: id,
		"theme.colorMode": colorMode
	});

	if (defaultValues != null) {
		params.set("preferences.defaultValues", JSON.stringify(defaultValues));
	}

	const src = `${IDEA_WIDGET_URL}?${params.toString()}`;

	return openDialog({
		initialize: () => {
			const $d = new WebDialogWithClose();
			$d.style.setProperty("--dialog-max-width", `${maxWidth || 600}px`);
			prepareDialogForWrapper($d);
			return $d;
		},
		center: true,
		$content: ($dialog) =>
			render(
				html`
					<bs-dialog-wrapper>
						<header>
							<bs-title>${title}</bs-title>
							<bs-text>${text}</bs-text>
						</header>
						<article>
							<bs-card style="overflow: hidden;">
								<iframe
									allowfullscreen
									name="idea-widget-frame"
									title="Idea Collection Widget"
									style="border: none;width: 100%;height: 450px;border-radius: 0"
									frameborder="0"
									src="${src}"
								></iframe>
							</bs-card>
						</article>
					</bs-dialog-wrapper>
				`,
				$dialog
			)
	});
}
