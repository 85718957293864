export function emojiToUnicode(input: string): string {
	return emojiToUnicodeRaw(input)
		.split(" ")
		.map((val) => parseInt(val, 10).toString(16).padStart(4, "0"))
		.filter((val) => val !== "fe0f") // remove variation selector-16
		.join(" ");
}

function emojiToUnicodeRaw(input: string): string {
	if (input.length === 1) {
		return input.charCodeAt(0).toString();
	} else if (input.length > 1) {
		const pairs: number[] = [];
		for (let i = 0; i < input.length; i++) {
			if (
				// high surrogate
				input.charCodeAt(i) >= 0xd800 &&
				input.charCodeAt(i) <= 0xdbff
			) {
				if (input.charCodeAt(i + 1) >= 0xdc00 && input.charCodeAt(i + 1) <= 0xdfff) {
					// low surrogate
					pairs.push((input.charCodeAt(i) - 0xd800) * 0x400 + (input.charCodeAt(i + 1) - 0xdc00) + 0x10000);
				}
			} else if (input.charCodeAt(i) < 0xd800 || input.charCodeAt(i) > 0xdfff) {
				// modifiers and joiners
				pairs.push(input.charCodeAt(i));
			}
		}
		return pairs.join(" ");
	}

	return "";
}
