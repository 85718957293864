import { GlobalStore } from "../store";
import { roomAction } from "./room-action";
import { model$, modelList$ } from "../model/model-list";
import {
	cmpIds,
	DEFAULT_MINDMAP_LAYOUT_KIND,
	DEFAULT_MINDMAP_PALETTE_KIND,
	Idea,
	Ref,
	Room,
	RoomTimer,
	takeId,
	User
} from "shared";
import { loading$, success$ } from "../action";
import { activity$, activityCreate$, activityRemove$ } from "../activity/activity-action";
import { combineLatest, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs";
import { sessionStore } from "../session/session-store";
import { modelStore } from "../model/model-store";
import { themeStore } from "../theme/theme-store";
import { createMindmapTheme } from "../../molecules/mindmap/mindmap-theme";
import { ideaAction } from "../idea/idea-action";

export class RoomStore extends GlobalStore {
	private currentRoom$ = model$([roomAction.setCurrentRoom]).pipe(shareReplay(1));

	private currentRoomId$ = model$([roomAction.setCurrentRoom]).pipe(
		distinctUntilChanged((a, b) => cmpIds(a, b)),
		map((room) => (room == null ? null : room.id))
	);

	model$ = {
		// TEMPORARY; REMOVE WHEN YOU SEE IT
		activeGame: success$(roomAction.setActiveGame),
		currentRoomLoading: loading$(roomAction.setCurrentRoom),
		currentRoom: this.currentRoom$,
		currentRoomId: this.currentRoomId$,

		theme: combineLatest([this.currentRoom$, themeStore.model$.colorMode]).pipe(
			map(([room, colorMode]) =>
				createMindmapTheme({
					paletteKind: room?.data?.theme?.palette ?? DEFAULT_MINDMAP_PALETTE_KIND,
					layoutKind: room?.data?.theme?.layout ?? DEFAULT_MINDMAP_LAYOUT_KIND,
					colorMode
				})
			),
			shareReplay(1)
		),

		ideas: modelList$<Idea>(
			[
				[ideaAction.list, "next"],
				[ideaAction.remove, "remove"],
				[ideaAction.bulkRemove, "remove"],
				[ideaAction.create, "prepend"],
				[success$(ideaAction.createIdeaWithParent, this.currentRoomId$).pipe(map(({ idea }) => idea!)), "prepend"],
				[activityCreate$("Idea"), "prepend"],
				[activityRemove$("Idea"), "remove"],
				[activity$("idea.create", { map: (activity) => activity.idea, meta: this.currentRoomId$ }), "prepend"],
				[activity$("idea.bulk.remove", { map: (activity) => activity.ideaIds, meta: this.currentRoomId$ }), "remove"],
				[ideaAction.clear, "clear"]
			],
			this.currentRoomId$
		),

		// TODO: Move to "RoomStore" and rename this store to "CurrentRoomStore"
		roomsLoading: loading$(roomAction.list),
		rooms: modelList$<Room>([
			[roomAction.list, "next"],
			[roomAction.create, "prepend"],
			[roomAction.remove, "remove"],
			[roomAction.joinRoom, "prepend"],
			[roomAction.leaveRoom, "remove"],
			[roomAction.clear, "clear"],
			[
				sessionStore.model$.sessionUser.pipe(
					switchMap((sessionUser) =>
						activity$("user.removedFromRoom", {
							filter: (activity) => cmpIds(activity.value.userId, sessionUser),
							map: (activity) => activity.roomId
						})
					)
				),
				"remove"
			],
			[activityCreate$("Room"), "prepend"],
			[activityRemove$("Room"), "remove"]
			// [activity$("removeRoom", (activity) => activity.roomId), "remove"]
		])
	};

	private _currentRoom: Room | null = null;
	get currentRoom() {
		return this._currentRoom;
	}

	protected subscriptions() {
		return [
			this.currentRoom$.subscribe((room) => {
				this._currentRoom = room;
			})
		];
	}

	async addActiveUserToRoom(room: Ref<Room>, user: Ref<User>) {
		let newActiveUsers: Ref<User>[] = [];
		await modelStore.mutate(room, (room) => {
			newActiveUsers = [...new Set<Ref<User>>([takeId(user), ...(room.activeUsers?.map((u) => takeId(u)) || [])])];

			return {
				...room,
				activeUsers: newActiveUsers
			};
		});

		return newActiveUsers;
	}

	async removeActiveUserFromRoom(room: Ref<Room>, user: Ref<User>) {
		let newActiveUsers: Ref<User>[] = [];
		await modelStore.mutate(room, (room) => {
			newActiveUsers = (room.activeUsers || []).filter((u) => !cmpIds(u, user));

			return {
				...room,
				activeUsers: newActiveUsers
			};
		});

		return newActiveUsers;
	}

	setRoomTimer(room: Ref<Room>, timer: RoomTimer | null) {
		return modelStore.mutate(room, (room) => ({
			...room,
			timer: timer || undefined
		}));
	}
}

export const roomStore = new RoomStore();
