import { ActionSubject } from "../action";
import { Ref, Subscription, Workspace } from "shared";

export const workspaceAction = {
	setCurrentWorkspace: new ActionSubject<Ref<Workspace> | null>(),
	get: new ActionSubject<Workspace>(),
	list: new ActionSubject<Workspace[]>(),
	create: new ActionSubject<Workspace>(),
	update: new ActionSubject<Workspace>(),
	remove: new ActionSubject<Ref<Workspace>>(),
	join: new ActionSubject<Ref<Workspace>>(),
	setCurrentWorkspaceOrNavigate: new ActionSubject<Ref<Workspace> | null>(),
	subscription: new ActionSubject<Subscription | undefined>()
};
