// Language Tags (BCP 47) (https://www.techonthenet.com/js/language_tags.php)
// https://simplelocalize.io/data/locales/
export const LANGUAGE_CODES = ["en-US", "pt-BR", "de-DE", "da-DK", "fr-FR"] as const;
// export const LANGUAGE_CODES = ["en-US", "da-DK"] as const;

export type LanguageCode = (typeof LANGUAGE_CODES)[number];

export function getLanguageEmoji(language: LanguageCode) {
	switch (language) {
		case "en-US":
			return "🇺🇸";
		case "da-DK":
			return "🇩🇰";
		case "pt-BR":
			return "🇧🇷";
		case "de-DE":
			return "🇩🇪";
		case "fr-FR":
			return "🇫🇷";
	}
}
