import { QUERY_PARAMS, ROUTE_CHANGED_EVENT, UTM_MEDIUM_PWA } from "../config";
import { appStore } from "../state/app/app-store";

/**
 * Extracts the ID from the /:id route
 * @param path
 */
export function getJoinRoomAccessCode(path: string): string | undefined {
	return new RegExp(`^\/room\/([0-9a-z]+)$`, "i").exec(path)?.[1];
}

export function setHash(id: string, { silent }: { silent?: boolean } = {}) {
	if (silent) {
		pushState(`${location.pathname}#${id}`, { silent: true });
	} else {
		location.hash = id;
	}
}

/**
 * Either pushed or replaces the current state.
 * @param path
 * @param replace
 * @param silent
 */
export function pushState(path: string, { replace, silent }: { replace?: boolean; silent?: boolean } = {}) {
	// console.log("pushState", path, { replace, silent });
	// console.trace();
	const prevHref = location.href;
	if (replace) {
		history.replaceState(null, "", path);
	} else {
		history.pushState(null, "", path);
	}

	if (!silent && location.href !== prevHref) {
		dispatchRouteChangedEvent();
	}
}

/**
 * Dispatches a route changed event.
 */
export function dispatchRouteChangedEvent() {
	window.dispatchEvent(new CustomEvent(ROUTE_CHANGED_EVENT));
}

/**
 * Listens for route changed events.
 * @param cb
 * @param options
 */
export function addRouteChangedListener(cb: () => void, options?: EventListenerOptions) {
	window.addEventListener(ROUTE_CHANGED_EVENT, cb, options);
}

/**
 * Listens for hash changed events.
 * @param cb
 * @param options
 */
export function addHashChangedListener(cb: () => void, options?: EventListenerOptions) {
	window.addEventListener("hashchange", cb, options);
}

/**
 * Removes route changed event listener.
 * @param cb
 * @param options
 */
export function removeRouteChangedListener(cb: () => void, options?: EventListenerOptions) {
	window.removeEventListener(ROUTE_CHANGED_EVENT, cb, options);
}

/**
 * Gets the query param from the URL.
 * @param name
 */
export function getQueryParam(name: string): string | null {
	return new URLSearchParams(window.location.search).get(name);
}

/**
 * Reloads the app, preserving the correct path and query params.
 * @param path
 */
export function reloadApp(path?: string) {
	const queryParams = new URLSearchParams(window.location.search);
	if (appStore.isSandbox) queryParams.set(QUERY_PARAMS.embed, "");
	if (appStore.isPwa) queryParams.set(QUERY_PARAMS.utmMedium, UTM_MEDIUM_PWA);
	location.href = `${path ?? location.pathname}${queryParams.size > 0 ? `?${queryParams.toString()}` : ""}`;
}
