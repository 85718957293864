import { cmpIds, getSessionUserIdCookieName, Id, User } from "shared";
import { ENVIRONMENT, STORAGE_KEYS } from "../config";
import { hydrateGQLResult } from "../api/gql/gql-util";
import { getItem } from "./local-storage";

export function getSessionUserIdCookieValue(): Id<User> | undefined {
	return readCookie<Id<User>>(getSessionUserIdCookieName(ENVIRONMENT));
}

export function readCookie<T extends string>(name: string): T | undefined {
	const signedCookie = document.cookie
		.split(";")
		.find((c) => c.trim().startsWith(name + "="))
		?.split("=")[1];

	if (signedCookie == null) {
		return undefined;
	}

	// Example: s:018c2051-4c24-e9ff-5ba2-2c4dda516562.+z78GqwbU3J1AJkb3PiwFBQajnuRnAE9wpA6tEhBsOA
	// Don't verify signature (this is done server-side)
	const [value] = decodeURIComponent(signedCookie).split(".");

	// Remove the "s:" prefix
	return value.substring(2) as T;
}

export function getInitialSessionState() {
	let initialSessionUserId = getSessionUserIdCookieValue() ?? null;
	let initialSessionUser = hydrateGQLResult(getItem(STORAGE_KEYS.sessionUser)) as User | null;

	// These lines are not backwards compatible with the old way of storing session user in localstorage
	// if (
	// 	initialSessionUserId == null ||
	// 	(initialSessionUser != null && !cmpIds(initialSessionUserId, initialSessionUser))
	// ) {
	// 	initialSessionUser = null;
	// }

	// These lines are backwards compatible with the old way of storing session user in localstorage
	if (initialSessionUserId != null && initialSessionUser != null && !cmpIds(initialSessionUserId, initialSessionUser)) {
		// If both session user id and session user are set, but they don't match, we will clear the session user
		initialSessionUser = null;
	} else if (initialSessionUserId == null && initialSessionUser != null) {
		// If session user id is not set, but session user is set, we will set the session user id to the session user id
		// This is to make sure that the session user id is always set for backwards compatibility
		initialSessionUserId = initialSessionUser.id;
	}

	return { userId: initialSessionUserId, user: initialSessionUser };
}
