import { GlobalStore } from "../store";
import { getRandomMindmapPaletteColorIndex, Idea, makeId, ModelData, Ref, Room, takeId } from "shared";
import { modelStore } from "../model/model-store";
import { sessionStore } from "../session/session-store";

export class IdeaStore extends GlobalStore {
	model$ = {};

	private localIdeaTimestampSet = new Set<Date>();

	protected subscriptions() {
		return [];
	}

	async addReactionToIdea(idea: Ref<Idea>, reaction: string, count?: number) {
		await modelStore.mutate(idea, (idea) => ({
			...idea,
			reactions: {
				...idea.reactions,
				[reaction]: (idea.reactions?.[reaction] ?? 0) + (count ?? 1)
			}
		}));
	}

	hasIdeaBeenSynced(ideaId: Ref<Idea>): boolean {
		const idea = modelStore.getInstant<Idea>(ideaId);
		if (idea == null) return true;

		// The idea is that this value has been changed if the idea has been synced with server
		return !this.localIdeaTimestampSet.has(idea.createdAt);
	}

	async waitForIdeaSynced(ideaId: Ref<Idea>, timeout = 3000): Promise<void> {
		const testIntervalMs = 20;

		return new Promise((resolve, reject) => {
			const startTime = Date.now();

			const interval = setInterval(() => {
				if (this.hasIdeaBeenSynced(ideaId)) {
					clearInterval(interval);
					resolve();
				} else if (Date.now() - startTime > timeout) {
					clearInterval(interval);
					resolve();
				}
			}, testIntervalMs);
		});
	}

	createLocalIdea(room: Ref<Room>, data: ModelData<Idea>): Idea {
		const localIdeaTimestamp = new Date();

		this.localIdeaTimestampSet.add(localIdeaTimestamp);

		const localIdea = {
			...data,
			__entity: "Idea",
			id: makeId<Idea>(),
			createdBy: sessionStore.sessionUser?.id,
			room: room,
			createdAt: localIdeaTimestamp,
			updatedAt: localIdeaTimestamp,
			direction: data.direction ?? "right",
			color: data.color ?? (data.parent != null ? undefined : getRandomMindmapPaletteColorIndex().toString()),
			order: data.order || 0,
			parent: takeId(data.parent) ?? undefined,
			media: data?.media?.map((media) => takeId(media)) ?? [],
			meta: undefined,
			isLocked: false,
			friendlyId: Math.floor(Math.random() * 100000) + 10000
		} as Idea;

		modelStore.absorb(localIdea);

		return localIdea;
	}
}

export const ideaStore = new IdeaStore();
