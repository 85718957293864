// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt: BeforeInstallPromptEvent | undefined = undefined;

/**
 * Shows the install prompt if possible.
 */
export async function showInstallPrompt() {
	if (deferredPrompt == null) return;
	deferredPrompt.prompt();
	deferredPrompt = undefined;
}

/**
 * Returns true if the deferredPrompt is not null.
 */
export function canShowInstallPrompt() {
	return deferredPrompt != null;
}

/**
 * Listens for the capture install prompt event.
 */
export function captureInstallPromptEvent() {
	window.addEventListener("beforeinstallprompt", (e: Event) => {
		e.preventDefault();
		deferredPrompt = e as BeforeInstallPromptEvent;
	});
}
