import { ActionSubject, ActionSubjectCached } from "../action";
import { BoardCursorPosition, Id, Idea, Ref, Room, RoomTimer, User } from "shared";
import { GameKind } from "../../molecules/game/game-helper";

export const roomAction = {
	setCurrentRoom: new ActionSubject<Room | null>(),
	setCurrentRoomToFirstInList: new ActionSubject<Room | null>(),
	joinRoom: new ActionSubject<Room>(),
	removeUserFromRoom: new ActionSubject<Ref<User>, Ref<Room>>(),
	list: new ActionSubject<Room[]>(),
	create: new ActionSubject<Room>(),
	clear: new ActionSubject<undefined>(),
	update: new ActionSubject<Room>(),
	remove: new ActionSubject<Ref<Room>>(),
	leaveRoom: new ActionSubject<Ref<Room>>(),
	get: new ActionSubject<Room>(),
	updateTimer: new ActionSubject<RoomTimer | null, Ref<Room>>(),
	updateUserCursor: new ActionSubject<BoardCursorPosition | null, Ref<Room>>(),
	followUserId: new ActionSubject<Id<User> | null, Ref<Room>>(),
	forceFollow: new ActionSubject<Ref<Room>>(),

	// TEMPORARY; REMOVE WHEN YOU SEE IT
	setActiveGame: new ActionSubjectCached<GameKind | undefined>(),

	brainstorm: new ActionSubject<Idea[], Ref<Room>>(),
	brainstormGrow: new ActionSubject<Idea[], Ref<Room>>(),
	brainstormSimple: new ActionSubject<void>()
};
