import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";

@customElement("bs-progress-spinner")
class BsProgressSpinner extends LitElement {
	static get styles() {
		return [
			sharedStyles,
			css`
				:host {
					display: flex;
					justify-content: center;
					align-items: center;
					color: currentColor;
				}

				#spinner {
					width: var(--progress-spinner-size, 1.5em);
					height: var(--progress-spinner-size, 1.5em);
					animation: spin 800ms infinite steps(8);

					transform-origin: center center;
					position: relative;
					display: block;
					overflow: hidden;
					contain: strict;
				}

				@keyframes spin {
					from {
						transform: rotate(0turn);
					}
					to {
						transform: rotate(1turn);
					}
				}
			`
		];
	}

	render() {
		return html`
			<svg id="spinner" width="100%" height="100%" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
				<g fill-rule="nonzero" fill="currentColor">
					<rect width="6" height="20" rx="3" opacity=".3" transform="translate(27)" />
					<rect width="6" height="20" rx="3" opacity=".5" transform="rotate(45 16.5 62.592)" />
					<rect width="6" height="20" rx="3" opacity=".7" transform="rotate(90 16.5 43.5)" />
					<rect width="6" height="20" rx="3" opacity=".9" transform="rotate(135 16.5 35.592)" />
					<rect width="6" height="20" rx="3" opacity=".1" transform="rotate(180 16.5 30)" />
					<rect width="6" height="20" rx="3" opacity=".1" transform="rotate(-135 16.5 24.408)" />
					<rect width="6" height="20" rx="3" opacity=".1" transform="rotate(-90 16.5 16.5)" />
					<rect width="6" height="20" rx="3" opacity=".1" transform="rotate(-45 16.5 -2.592)" />
				</g>
			</svg>
		`;
	}
}
