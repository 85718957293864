import { GlobalStore } from "../store";
import { themeAction } from "./theme-action";
import { success$ } from "../action";

export class ThemeStore extends GlobalStore {
	model$ = {
		colorMode: success$(themeAction.setColorMode)
	};
}

export const themeStore = new ThemeStore();
