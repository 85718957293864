import { Link, Room, toBinaryStr } from "shared";
import { QUERY_PARAMS, ROUTES } from "../config";

export function getJoinWorkspaceUrl(link: Link, name?: string) {
	return `${window.location.origin}${ROUTES.joinWorkspace(link.code)}${
		name != null ? `/${encodeURIComponent(btoa(toBinaryStr(name)))}` : ""
	}`;
}

export function getJoinRoomUrl(link: Link) {
	return `${window.location.origin}${ROUTES.joinRoom(link.code)}`;
}

export function getRoomUrl(room: Room) {
	return `${window.location.origin}${ROUTES.room(room.id)}`;
}

export function getRoomEmbedCode(link: Link) {
	const src = `${getJoinRoomUrl(link)}?${QUERY_PARAMS.embed}&${QUERY_PARAMS.continue}`;
	return `<iframe src="${src}"  height="500px" style="width: 100%;"  frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true"></iframe>`;
}

/**
 * Text is an email if follows the for .+@.+
 * @param text
 */
export function isTextEmail(text: string) {
	return /.+@.+/.test(text);
}
