import { combineLatest, filter, map, merge, Observable, ReplaySubject, Subject, switchMap } from "rxjs";
import { cmpIds, Model } from "shared";

export type ActionKind = "success" | "start" | "error";

export interface ActionBase<TMeta = unknown> {
	kind: ActionKind;
	meta?: TMeta;
}

export interface SuccessAction<TValue, TMeta = unknown> extends ActionBase<TMeta> {
	kind: "success";
	value: TValue;
}

export interface StartAction<TMeta = unknown> extends ActionBase<TMeta> {
	kind: "start";
}

export interface ErrorAction<TMeta = unknown> extends ActionBase<TMeta> {
	kind: "error";
	error: Error;
}

export type Action<TValue, TMeta = unknown> = SuccessAction<TValue, TMeta> | StartAction<TMeta> | ErrorAction<TMeta>;

export class ActionSubject<TValue, TMeta = unknown> extends Subject<Action<TValue, TMeta>> {}

export class ActionSubjectCached<TValue, TMeta = unknown> extends ReplaySubject<Action<TValue, TMeta>> {
	constructor() {
		super(1);
	}
}

export type ActionObservable<TValue, TMeta = unknown> = Observable<Action<TValue, TMeta>>;

export function isAction<T>(action: unknown): action is Action<T> {
	return (
		action != null &&
		typeof action === "object" &&
		"kind" in action &&
		typeof action.kind === "string" &&
		["success", "start", "error"].includes(action.kind)
	);
}

export async function runAction<TValue>(
	action: ActionSubject<TValue>,
	cb: () => Promise<TValue> | TValue
): Promise<TValue>;
export async function runAction<TValue, TMeta>(
	action: ActionSubject<TValue, TMeta>,
	cb: () => Promise<TValue> | TValue,
	meta: TMeta
): Promise<TValue>;
export async function runAction<TValue, TMeta = unknown>(
	action: ActionSubject<TValue, TMeta>,
	cb: () => Promise<TValue> | TValue,
	meta?: TMeta
) {
	try {
		action.next({ kind: "start", meta });
		const value = await cb();
		action.next({ kind: "success", value, meta });
		return value;
	} catch (error) {
		action.next({ kind: "error", error, meta });
		throw error;
	}
}

export interface Loading {
	loading: boolean;
	error?: Error;
	start?: boolean;
	success?: boolean;
}

export function loading$(...action: ActionObservable<unknown>[]): Observable<Loading> {
	return merge(...action).pipe(
		map((action) => {
			switch (action.kind) {
				case "start":
					return { loading: true, start: true };
				case "success":
					return { loading: false, success: true };
				case "error":
					return { loading: false, error: action.error };
			}
		})
	);
}

export function combinedLoading$(...action: ActionObservable<unknown>[]): Observable<Loading> {
	return combineLatest(action).pipe(
		map(([...loading]) => {
			if (loading.some((action) => action.kind === "start")) {
				return { loading: true, start: true };
			}

			const errorAction = loading.find((action) => action.kind === "error") as ErrorAction | undefined;
			if (errorAction) {
				return { loading: false, error: errorAction.error };
			}

			return {
				loading: false,
				success: true
			};
		})
	);
}

export function meta$<TValue, TMeta>(
	action: ActionObservable<TValue>,
	meta?: TMeta | Observable<TMeta>
): ActionObservable<TValue> {
	if (meta === undefined) {
		return action;
	}

	if (meta != null && meta instanceof Observable) {
		return meta.pipe(switchMap((meta) => meta$(action, meta)));
	} else {
	}

	return action.pipe(
		filter((action) => {
			return action.meta === meta || cmpIds(action.meta as Model, meta as Model);
		})
	) as ActionObservable<TValue>;
}

export function success$<TValue, TMeta>(
	action: ActionObservable<TValue>,
	meta?: TMeta | Observable<TMeta>
): Observable<TValue> {
	return meta$(action, meta).pipe(
		filter((action) => action.kind === "success"),
		map((action) => {
			if (action.kind !== "success") throw new Error("Kind is not 'success'!");
			return action.value;
		})
	) as Observable<TValue>;
}

export function start$<T>(action: ActionObservable<T>): Observable<StartAction<T>> {
	return action.pipe(filter((action) => action.kind === "start")) as Observable<StartAction<T>>;
}
