import { GlobalStore, subSuccessActionValue } from "../store";
import { appAction } from "./app-action";
import { success$ } from "../action";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { teamsStore } from "../teams/teams-store";
import { sessionStore } from "../session/session-store";

export type AppContext = "teams" | "pwa" | "webapp" | "landing";

export class AppStore extends GlobalStore {
	private _isMobile = false;
	get isMobile() {
		return this._isMobile;
	}

	private _isPwa = false;
	get isPwa() {
		return this._isPwa;
	}

	private _isSandbox = false;
	get isSandbox() {
		return this._isSandbox;
	}

	private isMobile$ = new BehaviorSubject<boolean>(false);
	private isPwa$ = new BehaviorSubject<boolean>(false);

	model$ = {
		isMobile: this.isMobile$,
		isPwa: this.isPwa$,
		isSandbox: new BehaviorSubject<boolean>(false),

		isMenuOpen: success$(appAction.setIsMenuOpen),

		/**
		 * Upselling is not allowed on mobile on Microsoft Teams.
		 * https://learn.microsoft.com/en-us/microsoftteams/platform/concepts/deploy-and-publish/appsource/prepare/teams-store-validation-guidelines?referrer=developerportal#security
		 */
		isUpsellingAllowed: combineLatest([teamsStore.model$.initialized, this.isMobile$]).pipe(
			map(([initialized, isMobile]) => !(initialized && isMobile))
		),

		/**
		 * Combine is teams and pwa to get app context
		 */
		appContext: combineLatest([teamsStore.model$.initialized, this.isPwa$, sessionStore.model$.hasSession]).pipe(
			map(
				([isTeamsInitialized, isPwa, hasSession]) =>
					`${isTeamsInitialized ? "teams" : hasSession ? "webapp" : isPwa ? "pwa" : "landing"}` as AppContext
			)
		) as Observable<AppContext>
	};

	protected subscriptions() {
		return [
			subSuccessActionValue(appAction.setIsMobile, (val) => {
				this._isMobile = val;
				this.isMobile$.next(val);
			}),
			subSuccessActionValue(appAction.setIsPwa, (val) => {
				this._isPwa = val;
				this.isPwa$.next(val);
			}),
			subSuccessActionValue(appAction.setIsSandbox, (val) => {
				this._isSandbox = val;
				this.model$.isSandbox.next(val);
			})
		];
	}
}

// TODO: Global state doesn't work properly right now
export const appStore = new AppStore();
