import { Media, MediaTransformationKind } from "../model/media";
import { emojiToUnicode } from "./emoji-to-unicode";
import { HexColor } from "../types/color-model";
import { Ref } from "../model/model";
import { Nullable } from "../types/type-util";
import { takeId } from "./model-util";

// Remember to also update imgproxy "maxSrcResolution"! (https://github.com/imgproxy/imgproxy/issues/96)
const MAX_FILE_SIZE_MB = 20;

export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export function emojiMediaUrl(baseUrl: string, emoji: string, kind: MediaTransformationKind) {
	const unicode = emoji.toLowerCase().startsWith("u") ? emoji : "u" + emojiToUnicode(emoji).replaceAll(" ", "_");
	return `${baseUrl}/icons/${unicode.toLowerCase()}/${kind}`;
}

export function defaultUserAvatarUrl(
	baseUrl: string,
	{
		id,
		color,
		kind
	}: {
		id: string;
		color?: HexColor;
		kind: MediaTransformationKind;
	}
) {
	return `${baseUrl}/avatar/${id}/${encodeURIComponent(color ?? "black")}/${kind}`;
}

export function mediaUrl(baseUrl: string, media: Ref<Media>, kind: MediaTransformationKind): string;
export function mediaUrl(
	baseUrl: string,
	media: Nullable<Ref<Media>>,
	kind: MediaTransformationKind
): string | undefined;
export function mediaUrl(
	baseUrl: string,
	media: Nullable<Ref<Media>>,
	kind: MediaTransformationKind
): string | undefined {
	if (media == null) return undefined;
	return `${baseUrl}/media/${takeId(media)}/${kind}`;
}
