import { Action, ActionObservable, isAction } from "../action";
import { cmpIds, Id, Model, Ref, takeId } from "shared";
import {
	BehaviorSubject,
	distinctUntilChanged,
	filter,
	map,
	merge,
	Observable,
	scan,
	shareReplay,
	startWith,
	tap
} from "rxjs";
import { modelStore } from "./model-store";

export type ModelListInputNextKind = "next" | "prepend" | "append" | "remove" | "clear";

export type ModelListInputAction<TModel extends Model | undefined | null, TMeta> =
	| ActionObservable<TModel[], TMeta>
	| ActionObservable<TModel, TMeta>
	| ActionObservable<Id<NonNullable<TModel>>[], TMeta>
	| ActionObservable<Id<NonNullable<TModel>>, TMeta>
	| ActionObservable<Ref<NonNullable<TModel>>[], TMeta>
	| ActionObservable<Ref<NonNullable<TModel>>, TMeta>
	| Observable<TModel[]>
	| Observable<TModel>
	| Observable<Id<NonNullable<TModel>>[]>
	| Observable<Id<NonNullable<TModel>> | null>
	| Observable<Ref<NonNullable<TModel>>[]>
	| Observable<Ref<NonNullable<TModel>>>;

export type ModelListInput<TModel extends Model, TMeta> =
	| [ModelListInputAction<TModel, TMeta>, ModelListInputNextKind]
	| [ModelListInputAction<undefined, TMeta>, "clear"];

export interface SubscriberOptions<TMeta> {
	meta?: Observable<TMeta> | TMeta;
}

// export function model$<TModel extends Model, TMeta = unknown>(
// 	inputs: ModelListInputAction<TModel, TMeta>[],
// 	meta?: Observable<TMeta>
// ): Observable<TModel>;
// export function model$<TModel extends Model, TMeta = unknown>(
// 	inputs: ModelListInputAction<TModel | null, TMeta>[],
// 	meta?: Observable<TMeta>
// ): Observable<Nullable<TModel>>;
// export function model$<TModel extends Model, TMeta = unknown>(
// 	inputs: ModelListInputAction<TModel, TMeta>[],
// 	meta?: Observable<TMeta>
// ): Observable<TModel>;
// export function model$<TModel extends Model | undefined | null, TMeta = unknown>(
// 	inputs: ModelListInputAction<TModel, TMeta>[],
// 	meta?: Observable<TMeta>
// ): Observable<TModel | undefined | null>;
export function model$<TModel extends Model | undefined | null, TMeta = unknown>(
	inputs: ModelListInputAction<TModel, TMeta>[],
	meta?: Observable<TMeta>
): Observable<TModel> {
	const id$ = merge(...inputs).pipe(
		filter((action) => (isAction(action) ? action.kind === "success" : true)),
		map((action) => {
			if (isAction(action)) {
				if (action.kind !== "success") throw new Error("Kind is not 'success'!");
				if (action.value == null) return action.value;
				return action.value instanceof Array ? action.value[0] : action.value;
			} else {
				return action;
			}
		}),
		shareReplay(1)
	) as Observable<Ref<NonNullable<TModel>> | null | undefined>;

	return modelStore.get$(id$ as Observable<Ref<NonNullable<TModel>>>);
}

export function modelList$<TModel extends Model, TMeta = unknown>(
	inputs: ModelListInput<TModel, TMeta>[],
	// options?: SubscriberOptions<TMeta>
	meta?: Observable<TMeta> | TMeta
): Observable<TModel[]> {
	let currentMeta: TMeta | undefined = undefined;
	const ids$: Observable<Ref<TModel>[]> = merge(
		...(meta instanceof Observable
			? [
					meta.pipe(
						distinctUntilChanged(),
						tap((meta: TMeta) => {
							currentMeta = meta;
						}),
						filter(() => false)
					) as Observable<{ action: Action<Ref<TModel>>; next: ModelListInputNextKind }>
				]
			: []),
		...inputs.map((input) =>
			(input[0] as unknown as Observable<TModel | Action<TModel>>).pipe(
				map((action: TModel | Action<TModel>) => {
					const next = input[1];

					if (isAction<Ref<TModel>>(action)) {
						return { action, next };
					} else {
						return {
							action: {
								kind: "success",
								value: action
							} as Action<Ref<TModel>>,
							next
						};
					}
				})
			)
		)
	).pipe(
		filter(({ action, next }) => {
			return isAction(action)
				? action.kind === "success" &&
						(meta == null ||
							action.meta === undefined ||
							action.meta === currentMeta ||
							cmpIds(action.meta as any, currentMeta as any))
				: true;
		}),
		scan((acc, { action, next }) => {
			if (isAction(action) && action.kind !== "success") throw new Error("Action kind is not 'success'!");
			const models = action.value instanceof Array ? action.value : [action.value];

			switch (next) {
				case "clear":
					return [];
				case "next":
					return uniqueIdList(models);
				case "prepend":
					return uniqueIdList([...models, ...acc]);
				case "append":
					return uniqueIdList([...acc, ...models]);
				case "remove":
					return acc.filter((model) => !models.some((m) => cmpIds(model, m)));
				default:
					return uniqueIdList(acc);
			}
		}, [] as Ref<TModel>[]),
		shareReplay(1)
	);

	return modelStore.list$(ids$);
}

export function latest$<T>(obs: Observable<T> | BehaviorSubject<T>, dfault: T): Observable<T>;
export function latest$<T>(obs: Observable<T> | BehaviorSubject<T>, dfault?: T): Observable<T>;
export function latest$<T>(obs: Observable<T> | BehaviorSubject<T>, dfault?: T): Observable<T | undefined> {
	if (obs instanceof BehaviorSubject) return obs.asObservable();

	return obs.pipe(startWith(dfault ?? undefined), shareReplay(1));
}

export function uniqueIdList<TModel extends Model>(list$: Ref<TModel>[]): Id<TModel>[] {
	return [...new Set(list$.map((model) => takeId(model)))];
}

export function modelUniqueById$<TModel extends Model>(model$: Observable<TModel>): Observable<TModel> {
	return model$.pipe(distinctUntilChanged((a, b) => cmpIds(a, b)));
}

// export function modelListEntry<TModel extends Model>(
// 	action: ActionObservable<Ref<TModel>, Ref<TModel> | unknown>,
// 	next: ModelListInputNextKind
// ): ModelListInput<TModel>;
// export function modelListEntry<TModel extends Model>(
// 	action: ActionObservable<Ref<TModel>[], Ref<TModel> | unknown>,
// 	next: ModelListInputNextKind
// ): ModelListInput<TModel>;
// export function modelListEntry<TModel extends Model>(
// 	action: ModelListInput<TModel>["action"],
// 	next: ModelListInputNextKind
// ): ModelListInput<TModel> {
// 	return { action, next };
// }
