const MINDMAP_PALETTE_KINDS = [
	"energy",
	"rust",
	"candy",
	"pastel",
	"mono",
	"bright",
	"forest",
	"waves",
	"nature",
	"nostalgia",
	"glow",
	"murky",
	"fire",
	"vacation"
] as const;
export type MindmapPaletteKind = (typeof MINDMAP_PALETTE_KINDS)[number];

export const DEFAULT_MINDMAP_PALETTE_KIND: MindmapPaletteKind = "energy";
