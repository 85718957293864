import { WebDialog } from "web-dialog";
import { customElement } from "lit/decorators/custom-element.js";
import { BsDialogCloseButton } from "./dialog-close-button";

const $template = document.createElement("template");
$template.innerHTML = `
  <bs-dialog-close-button id="close-button"></bs-dialog-close-button>
`;

@customElement("web-dialog-with-close")
export class WebDialogWithClose<R = any> extends WebDialog<R> {
	constructor() {
		super();
		this.$dialog.appendChild($template.content.cloneNode(true));
	}

	get $closeButton() {
		return this.$dialog.querySelector("#close-button") as BsDialogCloseButton;
	}

	get fill() {
		return this.$closeButton.fill;
	}

	set fill(value: boolean) {
		this.$closeButton.fill = value;
	}
}
