import { ActionSubjectCached, runAction } from "../action";

export const appAction = {
	setIsSandbox: new ActionSubjectCached<boolean>(),
	setIsMobile: new ActionSubjectCached<boolean>(),
	setIsMenuOpen: new ActionSubjectCached<boolean>(),
	setIsPwa: new ActionSubjectCached<boolean>()
};

export const setIsSandbox = (val: boolean) => runAction(appAction.setIsSandbox, () => val);
export const setIsMobile = (val: boolean) => runAction(appAction.setIsMobile, () => val);
export const setIsMenuOpen = (val: boolean) => runAction(appAction.setIsMenuOpen, () => val);
export const setIsPwa = (val: boolean) => runAction(appAction.setIsPwa, () => val);
