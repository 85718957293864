import { HexColor } from "./mindmap-theme";

export const MINDMAP_SPACING_TINY = 8;
export const MINDMAP_SPACING_SMALL = 10;
export const MINDMAP_SPACING_MEDIUM = 14;
export const MINDMAP_SPACING_LARGE = 18;

// Colors
export const MINDMAP_COLOR_WHITE = "#FFFFFF";
export const MINDMAP_COLOR_BLACK = "#000000";
export const MINDMAP_COLOR_WHITE_DIRTY = "#eeeeee";
export const MINDMAP_COLOR_BLACK_DIRTY = "#0B0B19";

export const MINDMAP_COLOR_SHADOW = "rgba(0,0,0,0.45)";

export interface MindmapColor {
	light: HexColor;
	dark: HexColor;
	normal: HexColor;
	lighter: HexColor;
	darker: HexColor;
	contrast: {
		light: HexColor;
		dark: HexColor;
		normal: HexColor;
		lighter: HexColor;
		darker: HexColor;
	};
}

export const MINDMAP_COLOR_TURQUOISE: MindmapColor = {
	darker: "#00687C",
	dark: "#0096A9",
	normal: "#4DD0E1",
	light: "#10E0F5",
	lighter: "#96F7FF",
	contrast: {
		darker: MINDMAP_COLOR_WHITE,
		dark: MINDMAP_COLOR_WHITE,
		normal: MINDMAP_COLOR_BLACK,
		light: MINDMAP_COLOR_BLACK,
		lighter: MINDMAP_COLOR_BLACK
	}
};

export const COLOR_GREY: MindmapColor = {
	darker: "#3b3c3f",
	dark: "#82898c",
	normal: "#D1D5DB",
	light: "#d2d3d7",
	lighter: "#e4e4ec",
	contrast: {
		darker: MINDMAP_COLOR_WHITE,
		dark: MINDMAP_COLOR_WHITE,
		normal: MINDMAP_COLOR_BLACK,
		light: MINDMAP_COLOR_BLACK,
		lighter: MINDMAP_COLOR_BLACK
	}
};
