import { ColorModeColors, Palette, pascalToKebabCase, ThemeColor } from "shared";

/**
 * Sets up a palette of colors as CSS properties.
 * @param palette
 */
export function setPaletteCSSProperties(palette: Palette) {
	for (const [name, colors] of Object.entries(palette)) {
		setThemeColorCSSProperties(pascalToKebabCase(name), colors);
	}
}

/**
 * Sets up theme color CSS properties.
 * @param name
 * @param colors
 */
export function setThemeColorCSSProperties(name: string, colors: ThemeColor) {
	for (const [variant, color] of Object.entries(colors)) {
		if (variant === "contrast") {
			setThemeColorCSSProperties(`${name}-contrast`, color);
			continue;
		}

		setThemeColorCSSProperty(`${name}-${variant}`, color);
	}
}

/**
 * Sets up a theme color CSS property.
 * @param cssVarName
 * @param color
 */
export function setThemeColorCSSProperty(cssVarName: string, color: string) {
	document.documentElement.style.setProperty(`--${cssVarName}`, color);
}

/**
 * Sets up color mode colors as CSS properties.
 * @param colors
 */
export function setColorModeColors(colors: ColorModeColors) {
	document.documentElement.classList.toggle("dark", colors.isDark ?? false);
	for (const [name, color] of Object.entries(colors)) {
		if (name === "isDark") continue;
		if (name === "shade") {
			setThemeColorCSSProperties("shade", color);
			continue;
		}

		setThemeColorCSSProperty(pascalToKebabCase(name), color);
	}
}
