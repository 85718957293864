import {
	AdaptiveCardDialogInfo,
	app,
	authentication,
	calendar,
	dialog,
	pages,
	people,
	profile,
	ShareDeepLinkParameters,
	sharing
} from "@microsoft/teams-js";
import { ColorMode, Link, Room } from "shared";
import { MS_TEAMS_ENTITY_IDS, TEAMS_APP_ID } from "../config";
import { getJoinRoomUrl } from "../util/share-util";

export class TeamsService {
	/**
	 * Returns true if the app is initialized.
	 */
	isInitialized() {
		return app.isInitialized();
	}

	/**
	 * Returns auth token.
	 */
	async getAuthToken(options?: authentication.AuthTokenRequestParameters): Promise<string | undefined> {
		return await authentication.getAuthToken(options);
	}

	/**
	 * Initializes the library.
	 */
	async initialize(validMessageOrigins?: string[]): Promise<boolean> {
		try {
			await app.initialize(validMessageOrigins);
			return true;
		} catch (err) {
			return false;
		}
	}

	getContext() {
		return app.getContext();
	}

	/**
	 * Notifies the frame that app has loaded and to hide the loading indicator if one is shown.
	 * If error is provided, it notifies the frame that app initialization has failed and to show an error page in its place.
	 */
	notifyAppLoaded(success: boolean) {
		app.notifyAppLoaded();

		if (success) {
			app.notifySuccess();
		} else {
			app.notifyFailure({
				reason: app.FailedReason.Other
			});
		}
	}

	/**
	 * Registers a handler for theme changes.
	 * @param cb: The handler to invoke when the user changes their theme.
	 */
	registerOnThemeChangeHandler(cb: (colorMode: ColorMode) => void) {
		app.registerOnThemeChangeHandler((theme) => cb(this.msThemeToColorMode(theme)));
	}

	/**
	 * Converts the MS theme to a color mode.
	 * @param theme
	 */
	msThemeToColorMode(theme: string): ColorMode {
		return theme == "dark" ? "dark" : theme === "contrast" ? "contrast" : "light";
	}

	/**
	 * Opens the people selector.
	 */
	openSelectPeople(data: people.PeoplePickerInputs) {
		return people.selectPeople(data);
	}

	/**
	 * Shares some web content.
	 * @param content
	 */
	shareWebContent(content: sharing.IShareRequest<sharing.IShareRequestContentType>) {
		return sharing.shareWebContent(content);
	}

	/**
	 * Generates a link to a room.
	 * @param room
	 * @param link
	 */
	generateRoomDeeplink(room: Room, link: Link) {
		return teamsService.generateDeepLink(getJoinRoomUrl(link), {
			entityLabel: room.challenge?.question ?? "Brainstorm",
			context: {
				subEntityId: link.code
			}
		});
	}

	/**
	 * Generates a deeplink link
	 * https://learn.microsoft.com/en-us/microsoftteams/platform/concepts/build-and-test/deep-link-application?tabs=teamsjs-v2#generate-a-deep-link-to-your-tab
	 * @example https://teams.microsoft.com/l/entity/fe4a8eba-2a31-4737-8e33-e5fae6fee194/tasklist123?webUrl=https://tasklist.example.com/123/456&label=Task 456&context={"subEntityId": "task456"}
	 * @param url
	 * @param options
	 */
	generateDeepLink(
		url: string,
		{
			entityId,
			entityLabel,
			appId,
			context
		}: {
			appId?: string;
			entityLabel?: string;
			context?: { subEntityId?: string; channelId?: string; chatId?: string; contextType?: string };
			entityId?: string;
		} = {}
	) {
		appId = appId ?? TEAMS_APP_ID;
		entityId = encodeURIComponent(entityId ?? MS_TEAMS_ENTITY_IDS.home);
		entityLabel = encodeURIComponent(entityLabel ?? "Ideamap");
		url = encodeURIComponent(url);
		return `https://teams.microsoft.com/l/entity/${appId}/${entityId}?webUrl=${url}&label=${entityLabel}${
			Object.entries(context ?? {}).length > 0 ? `&context=${encodeURIComponent(JSON.stringify(context))}` : ""
		}`;
	}

	registerTabConfigSaveHandler(handler: (e: pages.config.SaveEvent) => void) {
		pages.config.registerOnSaveHandler(handler);
	}

	setTabConfig(config: pages.InstanceConfig) {
		return pages.config.setConfig(config);
	}

	setTabConfigValidity(validity: boolean) {
		pages.config.setValidityState(validity);
	}

	async authenticate({ url, width = 600, height = 500 }: { url: string; width?: number; height?: number }) {
		// https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad?tabs=teamsjs-v2
		return authentication.authenticate({ url, width, height });
	}

	registerBackButtonHandler(cb: () => boolean) {
		pages.backStack.registerBackButtonHandler(cb);
	}

	openAdaptiveCard(options: AdaptiveCardDialogInfo, submitHandler?: dialog.DialogSubmitHandler) {
		dialog.adaptiveCard.open(options, submitHandler);
	}

	/**
	 * Opens a link.
	 * @param deeplink
	 */
	openLink(deeplink: string) {
		app.openLink(deeplink);
	}

	/**
	 * Shows the profile of a user.
	 * @param data
	 */
	showProfile(data: profile.ShowProfileRequest) {
		return profile.showProfile(data);
	}

	/**
	 * Opens the calendar composer.
	 * @param options
	 */
	openCalendar(options: calendar.ComposeMeetingParams) {
		return calendar.composeMeeting(options);
	}

	/**
	 * Shares a deeplink.
	 * https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/pages?view=msteams-client-js-latest#@microsoft-teams-js-pages-sharedeeplink
	 */
	shareDeepLink(options: ShareDeepLinkParameters) {
		pages.shareDeepLink(options);
	}

	isSelectPeopleSupported() {
		return people.isSupported();
	}

	isCalendarSupported() {
		return calendar.isSupported();
	}

	isSharingSupported() {
		return sharing.isSupported();
	}

	isDialogSupported() {
		return dialog.isSupported();
	}

	isAdaptiveCardSupported() {
		return dialog.adaptiveCard.isSupported();
	}

	/*testOpenTask() {
		const taskInfo = {
			title: "Task Module Demo",
			height: "medium",
			width: "medium",
			card: {
				type: "AdaptiveCard",
				$schema: "http://adaptivecards.io/schemas/adaptive-card.json",
				version: "1.4",
				body: [
					{
						type: "TextBlock",
						text: "This is sample adaptive card.",
						wrap: true
					},
					{
						type: "TextBlock",
						text: "Here is a ninja cat"
					},
					{
						type: "Image",
						url: "http://adaptivecards.io/content/cats/1.png"
					}
				]
			},
			fallbackUrl: null,
			completionBotId: null
		};

		tasks.startTask(taskInfo, (err, result) => {
			alert(JSON.stringify(result));
		});
	}*/
}

export const teamsService = new TeamsService();
