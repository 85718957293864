import { Socket } from "./socket";
import { API_BASE_WS_URL } from "../config";
import { BoardCursorPosition, Id, Room, WebSocketMessage } from "shared";
import { Subject } from "rxjs";

export interface RoomSocketEventsMap {
	message: (message: WebSocketMessage) => void;
}

export class RoomSocket extends Socket<RoomSocketEventsMap, RoomSocketEventsMap> {
	private currentRoomId: Id<Room> | null | undefined = null;
	private receiveMessage$ = new Subject<WebSocketMessage>();

	get message$() {
		return this.receiveMessage$.asObservable();
	}

	constructor(url: string) {
		super(url);

		this.connection.on("message", (msg: any) => {
			this.didReceiveMessage(msg);
		});
	}

	protected didConnect() {
		super.didConnect();

		// Join room on reconnect
		setTimeout(() => {
			if (this.currentRoomId != null) {
				this.joinRoom(this.currentRoomId!);
			}
		}, 1000);
	}

	async joinRoom(roomId: Id<Room>) {
		this.currentRoomId = roomId;
		await this.sendMessage({ kind: "room.join", roomId });
	}

	async leaveRoom() {
		this.currentRoomId = null;

		await this.sendMessage({ kind: "room.leave", roomId: this.currentRoomId! });
	}

	async updateRoomCursor(roomId: Id<Room>, cursor: BoardCursorPosition | null) {
		await this.sendMessage({ kind: "user.cursor", roomId, cursor });
	}

	async sendMessage(message: WebSocketMessage) {
		this.connection.send(message);
	}

	protected async didReceiveMessage(message: WebSocketMessage) {
		this.receiveMessage$.next(message);
	}
}

export const roomSocket = new RoomSocket(API_BASE_WS_URL);
