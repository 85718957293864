import { BehaviorSubject } from "rxjs";
import { GlobalStore, subSuccessActionValue } from "../store";
import { teamsAction } from "./teams-action";
import { loading$, success$ } from "../action";

export class TeamsStore extends GlobalStore {
	// Cache the initialized value to easily check if the app is running in Teams
	private _initialized = false;
	get initialized() {
		return this._initialized;
	}

	model$ = {
		initialized: new BehaviorSubject<boolean>(false),
		context: success$(teamsAction.setContext),
		authToken: new BehaviorSubject<string | undefined>(undefined),
		authTokenLoading: loading$(teamsAction.getAuthToken)
	};

	protected subscriptions() {
		return [
			subSuccessActionValue(teamsAction.initialize, (success) => {
				this._initialized = success;
				this.model$.initialized.next(success);
			}),
			subSuccessActionValue(teamsAction.getAuthToken, (token) => {
				this.model$.authToken.next(token);
			})
		];
	}
}

export const teamsStore = new TeamsStore();
