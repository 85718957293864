import { MindmapPaletteKind } from "shared/src/types/mindmap-palette-kind";
import { MindmapLayoutKind } from "shared/src/types/mindmap-layout-kind";
import { MINDMAP_PALETTES } from "./mindmap-palettes";
import { MINDMAP_LAYOUTS } from "./mindmap-layouts";
import { DEFAULT_MINDMAP_THEME_DARK, DEFAULT_MINDMAP_THEME_LIGHT, MindmapTheme } from "mindmap";
import { ColorMode } from "shared";
import { isColorModeDark } from "../../util/color-mode-util";

export function createMindmapTheme({
	paletteKind,
	layoutKind,
	colorMode
}: {
	paletteKind: MindmapPaletteKind;
	layoutKind: MindmapLayoutKind;
	colorMode?: ColorMode;
}): MindmapTheme {
	const isDark = isColorModeDark(colorMode);
	const palette = MINDMAP_PALETTES[paletteKind]?.(isDark) ?? {};
	const layout = MINDMAP_LAYOUTS[layoutKind];

	return {
		...(isDark ? DEFAULT_MINDMAP_THEME_DARK : DEFAULT_MINDMAP_THEME_LIGHT),
		palette: {
			...(isDark ? DEFAULT_MINDMAP_THEME_DARK.palette : DEFAULT_MINDMAP_THEME_LIGHT.palette),
			...palette
		},
		layout
	};
}
