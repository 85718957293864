import { LANGUAGE_CODES, LanguageCode } from "../types/language";
import { PlanCurrencyKind } from "../types/plan";
import { DEFAULT_CURRENCY } from "../constants/constants";

export function languageCodeToISO(languageCode: LanguageCode) {
	return languageCode.split("-")[0].toLowerCase();
}

/**
 * Finds the best match for a language code (eg. da-DK, da and da-dk)
 * @param locale
 */
export function findBestLanguageCode(locale: string): LanguageCode | undefined {
	for (const code of LANGUAGE_CODES) {
		if (locale.toLowerCase() === code.toLowerCase()) {
			return code;
		}
	}

	// If we cant find a match with the exact language code, try to just match the language without the region
	const [language] = locale.split("-") as [string, string | undefined];
	return LANGUAGE_CODES.find((code) => code.startsWith(language.toLowerCase()));
}

/*
EUR
DKK
BRL

PLN
TRY
NOK
SEK

AUD
CAD
GBP
JPY

 */
export function languageCodeToCurrency(languageCode: LanguageCode): PlanCurrencyKind {
	switch (languageCodeToISO(languageCode)) {
		case "da":
			return "dkk";
		case "pt":
			return "brl";
		case "pl":
			return "pln";
		case "tr":
			return "try";
		case "no":
			return "nok";
		case "sv":
			return "sek";
		case "au":
			return "aud";
		case "ca":
			return "cad";
		case "gb":
			return "gbp";
		case "ja":
			return "jpy";
		case "de":
		case "es":
		case "fr":
		case "it":
		case "nl":
			return "eur";
		default:
			return DEFAULT_CURRENCY;
	}
}
