export const API_ERROR_KINDS = [
	"notFound",
	"unauthorized",
	"forbidden",
	"internalServerError",
	"badRequest",
	"invitationConsumed",
	"unknown",
	"contentFilterTriggered",
	"timeout",
	"rateLimitExceeded",
	"noAccountFound",
	"notEnoughContent",
	"aiActionError"
] as const;

export type APIErrorKind = (typeof API_ERROR_KINDS)[number];

export const API_ERROR_STATUS_CODES: Partial<Record<APIErrorKind, number>> = {
	// General
	badRequest: 400,
	unauthorized: 401,
	forbidden: 403,
	notFound: 404,
	timeout: 408,
	rateLimitExceeded: 429,
	internalServerError: 500,
	unknown: 500,
	// Specific
	notEnoughContent: 400,
	contentFilterTriggered: 400,
	aiActionError: 400,
	invitationConsumed: 404,
	noAccountFound: 404
};

export class APIError extends Error {
	constructor(public kind: APIErrorKind, message?: string) {
		super(message);
		this.name = `APIError:${kind}`;
	}

	toObject(includeStack: boolean = false) {
		return {
			name: this.name,
			kind: this.kind,
			message: this.message,
			stack: includeStack ? this.stack : undefined
		};
	}

	static fromObject(obj: any) {
		if (!(obj instanceof Object)) return undefined;

		const { kind, message, stack, name } = obj as any;
		if (kind == null) return undefined;

		const apiError = new APIError(kind, message);
		if (stack != null) apiError.stack = stack;
		if (name != null) apiError.name = name;
		return apiError;
	}

	static fromStatusCode(statusCode: number, message?: string) {
		const kind = Object.entries(API_ERROR_STATUS_CODES).find(([_, code]) => code === statusCode)?.[0] as
			| APIErrorKind
			| undefined;

		if (kind == null) return new APIError("unknown", message);

		return new APIError(kind, message);
	}
}

export function isAPIError(error: any): error is APIError {
	return error instanceof APIError || (error.kind != null && API_ERROR_KINDS.includes(error.kind));
}
