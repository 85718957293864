import { AIActionOutputKind } from "../types/ai-action-recipe";
import { hasValue, parseSectionsInText, removeEndPunctuation, unmarkdownify, unquotify } from "./text-util";

export type AIActionParsedOutputText = string;
export type AIActionParsedOutputList = string[];
export type AIActionParsedOutputSections = Record<string, string[]>;
export interface AIActionParsedOutputMindmapNode {
	text: string;
	children: AIActionParsedOutputMindmapNode[];
}
export interface AIActionParsedOutputMindmap {
	nodes: AIActionParsedOutputMindmapNode[];
}

export type AIActionParsedOutput =
	| AIActionParsedOutputText
	| AIActionParsedOutputList
	| AIActionParsedOutputSections
	| AIActionParsedOutputMindmap;

export function parseAIActionOutput(rawOutput: string, outputKind: "text"): AIActionParsedOutputText;
export function parseAIActionOutput(rawOutput: string, outputKind: "list"): AIActionParsedOutputList;
export function parseAIActionOutput(rawOutput: string, outputKind: "sections"): AIActionParsedOutputSections;
export function parseAIActionOutput(rawOutput: string, outputKind: "mindmap"): AIActionParsedOutputMindmap;
export function parseAIActionOutput(rawOutput: string, outputKind: AIActionOutputKind): AIActionParsedOutput;
export function parseAIActionOutput(rawOutput: string, outputKind: AIActionOutputKind): AIActionParsedOutput {
	switch (outputKind) {
		case "text":
			return parseAIActionResultAsText(rawOutput);
		case "list":
			return parseAIActionResultAsList(rawOutput);
		case "sections":
			return parseAIActionResultsAsSections(rawOutput);
		case "mindmap":
			return parseAIActionResultsAsMindmap(rawOutput);
	}
}

function parseAIActionResultAsText(text: string): AIActionParsedOutputText {
	return unquotify(text.trim());
}

const LIST_ITEM_PREFIX_REGEX = /^([\d\s-]+\.|-(?=[A-Z])|[-*]\s+|[>\s]+)/i;

/**
 * Parses list items in text.
 * If the lines is a list item, parse them and clean them. Otherwise just return the lines.
 * @param text
 */
function parseAIActionResultAsList(text: string): AIActionParsedOutputList {
	// Split the text into lines and clean the lines
	const lines = unmarkdownify(text.trim())
		.split(/\n/)
		.map((line) => line.trim());

	const hasListItemPrefix = lines.some((line) => LIST_ITEM_PREFIX_REGEX.test(line));

	return lines
		.map((line) => {
			if (hasListItemPrefix && !LIST_ITEM_PREFIX_REGEX.test(line)) return null;
			return parseAIActionResultListItem(line);
		})
		.filter(hasValue);
}

function parseAIActionResultListItem(text: string): string {
	text = text.replace(LIST_ITEM_PREFIX_REGEX, "");
	return removeEndPunctuation(unquotify(text.trim()));
}

function parseAIActionResultsAsSections(text: string): AIActionParsedOutputSections {
	const sections = parseSectionsInText(text);

	if (sections == null) return {};

	const sectionsWithListItems: Record<string, string[]> = {};
	for (const [title, content] of Object.entries(sections)) {
		sectionsWithListItems[title] = parseAIActionResultAsList(content);
	}

	return sectionsWithListItems;
}

/**
 * Parse the AI action results as a mindmap.
 * Example input:
 * >Level 1
 * >>Level 2
 * >>>Level 3
 * >>Level 2
 * @param text
 */
function parseAIActionResultsAsMindmap(text: string): AIActionParsedOutputMindmap {
	const lines = unmarkdownify(text.trim())
		.split(/\n/)
		.map((line) => line.trim());

	const rootNodes: AIActionParsedOutputMindmapNode[] = [];
	let nodeStack: AIActionParsedOutputMindmapNode[] = [];

	let prevWasEmptyLevel = false;
	for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		const isLastLine = i === lines.length - 1;

		if (line.length === 0) continue;

		let level = line.match(/^[>#]+/)?.[0]?.length;
		if (level == null) {
			if (nodeStack.length > 0 && !isLastLine) {
				// If the line is not a mindmap node, but there are nodes in the stack, add the line as a child to the last node
				level = nodeStack.length + (prevWasEmptyLevel ? 0 : 1);
			} else {
				continue;
			}

			prevWasEmptyLevel = true;
		} else {
			prevWasEmptyLevel = false;
		}

		const text = parseAIActionResultListItem(line.replace(/^[>#-]+\s*/, "").trim());

		const newNode: AIActionParsedOutputMindmapNode = { text, children: [] };

		if (level <= nodeStack.length) {
			// Make sure that the node stack is on correct level
			while (nodeStack.length > level - 1) {
				nodeStack.pop();
			}
		}

		if (level <= 1 || nodeStack.length === 0) {
			// Add a new root node
			rootNodes.push(newNode);
			// Reset node stack
			nodeStack = [newNode];
		} else {
			// Add child to parent (current node in top of the node stack)
			const parent = nodeStack[nodeStack.length - 1];
			parent.children.push(newNode);
			nodeStack.push(newNode);
		}
	}

	return {
		nodes: rootNodes
	};
}
