import { Id, Model, Ref } from "../model/model";
import { uuidv4 } from "./hash-util";

export type PartialModel<TModel extends Model> = { id: Id<TModel> } & Partial<TModel>;

export function makeId<T extends Model = any>(seed?: number) {
	return uuidv4(seed) as Id<T>;
}

export function isId<TModel extends Model = any>(id: any): id is Id<TModel> {
	return typeof id === "string" && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(id);
}

export function takeId<M extends Model>(ref: Ref<M>): Id<M>;
export function takeId<M extends Model>(ref: Ref<M> | undefined): Id<M> | undefined;
export function takeId<M extends Model>(ref: Ref<M> | null): Id<M> | null;
export function takeId<M extends Model>(ref: Ref<M> | null | undefined): Id<M> | null | undefined;
export function takeId<M extends Model>(ref: Ref<M> | null | undefined): Id<M> | null | undefined {
	if (ref == null) {
		return ref;
	}

	return typeof ref !== "object" ? ref : "id" in ref && typeof ref.id === "string" ? ref.id : undefined;
}

export function takeModel<M extends Model>(ref: Ref<M> | undefined): M | undefined {
	if (ref == null || typeof ref !== "object") {
		return undefined;
	}

	return ref;
}

export function takeModels<M extends Model>(refs: Ref<M>[]): M[] {
	return refs.map((ref) => takeModel(ref)).filter((item): item is NonNullable<typeof item> => item != null);
}

export function cmpIds(refA: Ref<Model> | undefined | null, refB: Ref<Model> | undefined | null) {
	if (refA == null || refB == null) return refA === refB;

	return takeId(refA) === takeId(refB);
}

export function isModel(ref: any): ref is Model {
	return ref != null && typeof ref === "object" && "id" in ref && "createdAt" in ref && "updatedAt" in ref;
}

export function convertRefsToIds<T>(model: any): T {
	function inner(m: any): any {
		if (model == null) {
			return model;
		} else if (Array.isArray(model)) {
			return model.map(inner);
		} else if (model instanceof Date) {
			return model;
		} else if (typeof model === "object") {
			return Object.entries(model).reduce((acc: any, [key, value]) => {
				acc[key] = isModel(value) ? model.id : convertRefsToIds(value);
				return acc;
			}, {} as any);
		}
	}

	return inner(model) as T;
}
