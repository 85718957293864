import { app } from "@microsoft/teams-js";
import { openConfirm } from "./dialog-helper";
import { teamsStore } from "../state/teams/teams-store";

/**
 * A hacky way of determining if the app is running in Teams.
 * The reason we need this is because the Teams SDK is async.
 * If location.search includes "locale=en-us&theme=default", we assume the app is running in Teams.
 */
export function guessIsTeamsContext() {
	return location.search.includes("locale");
}

export function getTeamsOrganizationName(context?: app.Context): string | undefined {
	return context?.sharePointSite?.mySiteDomain?.replace("-my.sharepoint.com", "") ?? "Microsoft Teams";
}

/**
 * In teams, external links need to give the user a confirmation dialog before opening the link.
 */
export function ensureExternalLinkDialog() {
	window.addEventListener("click", async (e: MouseEvent) => {
		// Find the target by using the composed path to get the element through the shadow boundaries.
		const $anchor = (("composedPath" in e) as any)
			? e.composedPath().find(($elem) => $elem instanceof HTMLAnchorElement)
			: e.target;

		// Abort if the event is not about the anchor tag
		if ($anchor == null || !($anchor instanceof HTMLAnchorElement)) {
			return;
		}

		// Make sure the anchor is an external link
		// Make sure the link start with "http", we dont want to stop "blob:" links
		const href = $anchor.href;
		if (href.length > 0 && href.startsWith("http") && !href.startsWith(location.origin)) {
			e.preventDefault();
			await openExternalLinkWithConfirm(href);
		}
	});
}

/**
 * Opens an external link with confirm if necessary.
 * @param href
 */
export async function openExternalLink(href: string) {
	if (teamsStore.initialized) {
		return openExternalLinkWithConfirm(href);
	} else {
		window.open(href, "_blank");
	}
}

/**
 * Shows a confirmation dialog before opening an external link.
 * @param href
 */
export async function openExternalLinkWithConfirm(href: string) {
	if (
		await openConfirm({
			text: `Do you want to open the external website "${href}" in another tab?`
		})
	) {
		window.open(href, "_blank");
	}
}
