import { AIActionRecipe } from "../types/ai-action-recipe";
import { Id, Model, Ref } from "./model";
import { User, UserBase, UserDAO } from "./user";
import { Workspace, WorkspaceBase, WorkspaceDAO } from "./workspace";
import { cmpIds, makeId } from "../util/model-util";
import { Media, MediaBase, MediaDAO } from "./media";
import { EntityKind } from "./entity-kind";

export interface AIActionMetadata {
	name?: string;
	description?: string;
	entityKind?: EntityKind;
	state?: {
		start?: string;
		active?: string;
		success?: string;
		error?: string;
	};
	technique?: {
		description: string;
		name?: string;
		toolboxId?: string;
		sticker?: string;
	};
}

export interface AIActionBase extends Model {
	__entity?: "AIAction";

	icon?: Ref<MediaBase>;
	metadata?: AIActionMetadata;

	recipe: AIActionRecipe;

	createdBy?: Ref<UserBase>;
	workspace?: Ref<WorkspaceBase>;
}

export interface AIAction extends AIActionBase {
	icon?: Ref<Media>;
	createdBy?: Ref<User>;
	workspace?: Ref<Workspace>;
}

export interface AIActionDAO extends AIActionBase {
	icon?: Ref<MediaDAO>;
	createdBy?: Id<UserDAO>;
	workspace?: Id<WorkspaceDAO>;
}

export type BuiltInAIActionKind =
	| "scamper"
	| "swot"
	| "valuePropositionCanvas"
	| "sixThinkingHats"
	| "disneyMethod"
	| "grow"
	| "customGrow"
	| "wordAssociation"
	| "mindsetShifting"
	| "roleStorming"
	| "elevatorPitch"
	| "feedback"
	| "fiveWhy"
	| "businessModelCanvas"
	| "extractCompanyGoals"
	| "extractMindmap"
	| "ideas"
	| "finetune"
	| "inspiration"
	| "icebreakers"
	| "topics"
	| "challenges"
	| "chat"
	| "challengeBrief"
	| "imagePrompt"
	| "expand"
	| "shorten"
	| "customModifyIdea"
	| "conclusion"
	| "customIdeas"
	| "suggestPersona"
	| "selectIdeas"
	| "combineIdeas"
	| "synthesizeIdeas"
	| "topicForIdeas"
	| "oppositeIdeas"
	| "prosAndCons"
	| "ideaTitle"
	| "sixThinkingHatsQuestions"
	| "scamperQuestions"
	| "reverseThinkingQuestions"
	| "whyQuestion"
	| "rapidIdeationInspiration"
	| "crazyEightsQuestions"
	| "wordPairAssociations"
	| "brainstorm"
	| "brainstormGrow";

const BUILT_IN_AI_ACTION_KIND_TO_ID_MAP: Record<BuiltInAIActionKind, Id<AIActionBase>> = {
	scamper: makeId<AIActionBase>(1),
	swot: makeId<AIActionBase>(2),
	valuePropositionCanvas: makeId<AIActionBase>(3),
	sixThinkingHats: makeId<AIActionBase>(4),
	disneyMethod: makeId<AIActionBase>(5),
	grow: makeId<AIActionBase>(6),
	wordAssociation: makeId<AIActionBase>(7),
	mindsetShifting: makeId<AIActionBase>(8),
	roleStorming: makeId<AIActionBase>(9),
	elevatorPitch: makeId<AIActionBase>(10),
	feedback: makeId<AIActionBase>(11),
	fiveWhy: makeId<AIActionBase>(13),
	businessModelCanvas: makeId<AIActionBase>(14),
	extractCompanyGoals: makeId<AIActionBase>(15),
	extractMindmap: makeId<AIActionBase>(16),
	ideas: makeId<AIActionBase>(17),
	finetune: makeId<AIActionBase>(18),
	inspiration: makeId<AIActionBase>(19),
	icebreakers: makeId<AIActionBase>(20),
	topics: makeId<AIActionBase>(21),
	challenges: makeId<AIActionBase>(22),
	chat: makeId<AIActionBase>(24),
	challengeBrief: makeId<AIActionBase>(28),
	imagePrompt: makeId<AIActionBase>(29),
	expand: makeId<AIActionBase>(30),
	conclusion: makeId<AIActionBase>(31),
	customIdeas: makeId<AIActionBase>(32),
	shorten: makeId<AIActionBase>(33),
	suggestPersona: makeId<AIActionBase>(34),
	selectIdeas: makeId<AIActionBase>(35),
	combineIdeas: makeId<AIActionBase>(36),
	ideaTitle: makeId<AIActionBase>(37),
	wordPairAssociations: makeId<AIActionBase>(38),
	topicForIdeas: makeId<AIActionBase>(39),
	sixThinkingHatsQuestions: makeId<AIActionBase>(40),
	scamperQuestions: makeId<AIActionBase>(41),
	whyQuestion: makeId<AIActionBase>(42),
	reverseThinkingQuestions: makeId<AIActionBase>(43),
	rapidIdeationInspiration: makeId<AIActionBase>(44),
	crazyEightsQuestions: makeId<AIActionBase>(45),
	synthesizeIdeas: makeId<AIActionBase>(46),
	oppositeIdeas: makeId<AIActionBase>(47),
	brainstorm: makeId<AIActionBase>(48),
	customGrow: makeId<AIActionBase>(49),
	customModifyIdea: makeId<AIActionBase>(50),
	brainstormGrow: makeId<AIActionBase>(51),
	prosAndCons: makeId<AIActionBase>(52)
};

export function builtInAIActionKindToId(kind: BuiltInAIActionKind): Id<AIActionBase> {
	return BUILT_IN_AI_ACTION_KIND_TO_ID_MAP[kind];
}

export function builtInActionIdToKind(id: Id<AIActionBase>): BuiltInAIActionKind | undefined {
	return Object.entries(BUILT_IN_AI_ACTION_KIND_TO_ID_MAP).find(([, actionId]) => cmpIds(actionId, id))?.[0] as
		| BuiltInAIActionKind
		| undefined;
}
