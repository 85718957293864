import { MindmapPaletteColors, MindmapPaletteThemeColor } from "./mindmap-theme";
import { MINDMAP_COLOR_BLACK_DIRTY, MINDMAP_COLOR_WHITE } from "./theme-constants";

export const DEFAULT_PALETTE_COLOR: MindmapPaletteThemeColor = {
	color: MINDMAP_COLOR_BLACK_DIRTY,
	contrast: MINDMAP_COLOR_WHITE
};

export const MINDMAP_THEME_PALETTE_COLORS_ENERGY: MindmapPaletteColors = [
	{ color: "#E57373", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#BA68C8", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#9575CD", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#818CF8", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#60A5FA", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#4DD0E1", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#2bc28c", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#059669", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#AED581", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#E2E05D", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#FCD34D", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#FFA500", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#A52A2A", contrast: MINDMAP_COLOR_WHITE }
];

/*export const MINDMAP_THEME_PALETTE_COLORS_GOOGLE: MindmapPaletteColors = [
	{ color: "#EF5350", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#EC407A", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#AB47BC", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#7E57C2", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#5C6BC0", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#42A5F5", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#26C6DA", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#26A69A", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#66BB6A", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#9CCC65", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#D4E157", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#FFEE58", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#FFCA28", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#FFA726", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#FF7043", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#8D6E63", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#BDBDBD", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#78909C", contrast: MINDMAP_COLOR_WHITE }
];*/

export const MINDMAP_THEME_PALETTE_COLORS_PASTEL: MindmapPaletteColors = [
	{ color: "#ffadad", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ffd6a5", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#fdffb6", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#caffbf", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#9bf6ff", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#a0c4ff", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#bdb2ff", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ffc6ff", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const THEME_PALETTE_COLORS_CANDY: MindmapPaletteColors = [
	{ color: "#ba88ff", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ff78c7", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#fee440", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#00bbf9", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#00f5d4", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const MINDMAP_THEME_PALETTE_COLORS_RUST: MindmapPaletteColors = [
	{ color: "#053047", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#005f73", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#0a9396", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#94d2bd", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#e9d8a6", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ee9b00", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ca6702", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#bb3e03", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#ae2012", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#9b2226", contrast: MINDMAP_COLOR_WHITE }
];

export const MINDMAP_THEME_PALETTE_COLORS_BRIGHT: MindmapPaletteColors = [
	{ color: "#5aa9e6", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#7fc8f8", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ffe45e", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ff6392", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const MINDMAP_THEME_PALETTE_COLORS_FOREST: MindmapPaletteColors = [
	{ color: "#304f65", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#fe7f2d", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#fcca46", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#a1c181", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#619b8a", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const MINDMAP_THEME_PALETTE_COLORS_WAVES: MindmapPaletteColors = [
	{ color: "#023e8a", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#0077b6", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#0096c7", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#00b4d8", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#48cae4", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#90e0ef", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const MINDMAP_THEME_PALETTE_COLORS_NATURE: MindmapPaletteColors = [
	{ color: "#ef476f", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ffd166", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#06d6a0", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#118ab2", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#073b4c", contrast: MINDMAP_COLOR_WHITE }
];

export const MINDMAP_THEME_PALETTE_COLORS_NOSTALGIA: MindmapPaletteColors = [
	{ color: "#264653", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#2a9d8f", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#e9c46a", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#f4a261", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#e76f51", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const MINDMAP_THEME_PALETTE_COLORS_GLOW: MindmapPaletteColors = [
	{ color: "#355070", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#6d597a", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#b56576", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#e56b6f", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#eaac8b", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const MINDMAP_THEME_PALETTE_COLORS_MURKY: MindmapPaletteColors = [
	{ color: "#99d98c", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#76c893", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#52b69a", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#34a0a4", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#168aad", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#1a759f", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#1e6091", contrast: MINDMAP_COLOR_WHITE }
];

export const MINDMAP_THEME_PALETTE_COLORS_FIRE: MindmapPaletteColors = [
	{ color: "#f7b267", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#f79d65", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#f4845f", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#f27059", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#f25c54", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];

export const MINDMAP_THEME_PALETTE_COLORS_VACATION: MindmapPaletteColors = [
	{ color: "#2364aa", contrast: MINDMAP_COLOR_WHITE },
	{ color: "#3da5d9", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#73bfb8", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#fec601", contrast: MINDMAP_COLOR_BLACK_DIRTY },
	{ color: "#ea7317", contrast: MINDMAP_COLOR_BLACK_DIRTY }
];
