import { MindmapTheme } from "./mindmap-theme";
import { DEFAULT_MINDMAP_PALETTE_DARK, DEFAULT_MINDMAP_PALETTE_LIGHT } from "./palette";
import { DEFAULT_MINDMAP_LAYOUT } from "./layout";

export const DEFAULT_MINDMAP_THEME_LIGHT: MindmapTheme = {
	palette: DEFAULT_MINDMAP_PALETTE_LIGHT,
	layout: DEFAULT_MINDMAP_LAYOUT
};

export const DEFAULT_MINDMAP_THEME_DARK: MindmapTheme = {
	...DEFAULT_MINDMAP_THEME_LIGHT,
	palette: DEFAULT_MINDMAP_PALETTE_DARK
};
