import { PropertyValues, ReactiveController, ReactiveControllerHost } from "lit";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { Store, StoreModel } from "./store";
import { debounce, RemoveUndefined } from "shared";

export interface ControllerHost<T extends Store> extends ReactiveControllerHost {
	controllerUpdated?: (props: PropertyValues<StoreModel<T>>) => void;
}

// Key to (value: Value) => void
export type UpdateMap<T extends Store, U = StoreModel<T>> = {
	[K in keyof U]?: (value: RemoveUndefined<U[K]>, previousValue?: U[K]) => void;
};

export class Controller<TStore extends Store> implements ReactiveController {
	protected subs: Subscription[] = [];
	protected host: ControllerHost<TStore>;

	// A map for caching changes so we can update the controller with all the latest changes at once instead of one at a time
	protected changes = new Map<string, unknown>();

	constructor(host: ControllerHost<TStore>, public store: TStore, protected updateMap?: UpdateMap<TStore>) {
		(this.host = host).addController(this);
	}

	readonly model: StoreModel<TStore> = {} as any;

	protected subscribe(): void {
		this.store.addSubscription();

		this.subs = [];

		// console.log(`${this.host.constructor.name}: sub`);

		for (const [key, obs] of Object.entries(this.store.model$)) {
			if (!(obs instanceof Observable)) {
				continue;
			}

			const previousValues = new Map<string, unknown>();

			if (obs instanceof ReplaySubject) {
				(this.model as any)[key] = obs;
				previousValues.set(key, obs);
			}

			let isSettingUp = true;

			this.subs.push(
				obs.subscribe((value) => {
					(this.model as any)[key] = value;

					if (previousValues.has(key) && previousValues.get(key) === value) {
						return;
					}

					if (this.updateMap != null && !isSettingUp) {
						const update = this.updateMap[key as keyof UpdateMap<TStore>];

						if (update != null) {
							update(value, previousValues.get(key) as any);
						}
					}

					this.changes.set(key, value);
					previousValues.set(key, value);

					debounce(
						() => {
							this.host.requestUpdate();
							this.host.controllerUpdated?.(this.changes as any);
							this.changes = new Map();
						},
						this,
						20,
						{ forceMs: 20 }
					);
				})
			);

			isSettingUp = false;
		}
	}

	protected unsubscribe() {
		this.store.removeSubscription();

		// console.log(`${this.host.constructor.name}: unsub`);

		for (const sub of this.subs) {
			sub.unsubscribe();
		}

		this.subs = [];
	}

	hostConnected() {
		this.subscribe();
	}

	hostDisconnected() {
		this.unsubscribe();
	}
}
