import { MindmapMediaPreviewUrls, MindmapPalette } from "./mindmap-theme";
import {
	COLOR_GREY,
	MINDMAP_COLOR_BLACK_DIRTY,
	MINDMAP_COLOR_SHADOW,
	MINDMAP_COLOR_TURQUOISE,
	MINDMAP_COLOR_WHITE,
	MINDMAP_COLOR_WHITE_DIRTY
} from "./theme-constants";
import { MINDMAP_THEME_PALETTE_COLORS_ENERGY } from "./palette-colors";

export const MINDMAP_PREVIEW_MEDIA_URLS_LIGHT: MindmapMediaPreviewUrls = {
	imagePreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/image.webp",
	videoPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/video.webp",
	audioPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/audio.webp",
	documentPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/document.webp",
	otherPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/other.webp"
};

export const MINDMAP_PREVIEW_MEDIA_URLS_DARK: MindmapMediaPreviewUrls = {
	imagePreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/image-dark.webp",
	videoPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/video-dark.webp",
	audioPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/audio-dark.webp",
	documentPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/document-dark.webp",
	otherPreviewUrl: "https://storage.googleapis.com/ideamap/static/preview-media/other-dark.webp"
};

export const DEFAULT_MINDMAP_PALETTE_LIGHT: MindmapPalette = {
	previewUrls: MINDMAP_PREVIEW_MEDIA_URLS_LIGHT,
	colors: MINDMAP_THEME_PALETTE_COLORS_ENERGY,
	unfoldBackgroundColorHover: COLOR_GREY.lighter,
	unfoldTextColorHover: COLOR_GREY.contrast.lighter,
	backgroundColor: MINDMAP_COLOR_WHITE,
	foregroundColor: MINDMAP_COLOR_BLACK_DIRTY,
	backgroundDotsColor: COLOR_GREY.lighter,
	selectColor: MINDMAP_COLOR_TURQUOISE.light,
	borderShadowColorDragging: MINDMAP_COLOR_SHADOW,
	nodeMediaBackgroundColor: MINDMAP_COLOR_WHITE_DIRTY,
	nodeMediaMoreTextColor: MINDMAP_COLOR_BLACK_DIRTY,
	nodeMoreTextColor: COLOR_GREY.dark,
	nodeMoreTextBackgroundColor: MINDMAP_COLOR_WHITE_DIRTY,
	defaultNodeBackgroundColor: MINDMAP_COLOR_WHITE,
	defaultNodeTextColor: MINDMAP_COLOR_BLACK_DIRTY
};

export const DEFAULT_MINDMAP_PALETTE_DARK: MindmapPalette = {
	previewUrls: MINDMAP_PREVIEW_MEDIA_URLS_DARK,
	colors: MINDMAP_THEME_PALETTE_COLORS_ENERGY,
	backgroundColor: "#19191c", //MINDMAP_COLOR_BLACK_DIRTY,
	foregroundColor: MINDMAP_COLOR_WHITE_DIRTY,
	backgroundDotsColor: COLOR_GREY.darker,
	nodeMoreTextBackgroundColor: COLOR_GREY.dark,
	nodeMoreTextColor: COLOR_GREY.light,
	nodeMediaBackgroundColor: COLOR_GREY.dark,
	nodeMediaMoreTextColor: COLOR_GREY.light,
	unfoldBackgroundColorHover: COLOR_GREY.darker,
	unfoldTextColorHover: COLOR_GREY.contrast.darker,
	borderShadowColorDragging: MINDMAP_COLOR_SHADOW,
	selectColor: MINDMAP_COLOR_TURQUOISE.dark,
	defaultNodeBackgroundColor: MINDMAP_COLOR_BLACK_DIRTY,
	defaultNodeTextColor: MINDMAP_COLOR_WHITE_DIRTY
};
